/**
 *                        .::::.
 *                      .::::::::.
 *                     :::::::::::
 *                  ..:::::::::::'
 *               '::::::::::::'
 *                 .::::::::::
 *            '::::::::::::::..
 *                 ..::::::::::::.
 *               ``::::::::::::::::
 *                ::::``:::::::::'        .:::.
 *               ::::'   ':::::'       .::::::::.
 *             .::::'      ::::     .:::::::'::::.
 *            .:::'       :::::  .:::::::::' ':::::.
 *           .::'        :::::.:::::::::'      ':::::.
 *          .::'         ::::::::::::::'         ``::::.
 *      ...:::           ::::::::::::'              ``::.
 *     ````':.          ':::::::::'                  ::::..
 *                        '.:::::'                    ':'````..
 * 
 * ----------------------
 * |
 * |  加载集成插件和依赖
 * |
 * ----------------------
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from '@/plugins/api'

import Print from 'vue-print-nb'
// 全局引用
Vue.use(Print);


Vue.prototype.$api = api;

/**
 * ----------------------
 * |
 * |  加载 / 基础样式文件
 * |
 * ----------------------
 */
import '@/assets/css/baseStyle.css'

/**
 * ----------------------
 * |
 * |  加载less
 * |
 * ----------------------
 */
import less from 'less'
Vue.use(less)


/**
 * ----------------------
 * |
 * |  加载UI框架 vant(移动)  element(PC)
 * |
 * ----------------------
 */
import '@/plugins/vant.js'
import '@/plugins/element.js'

/**
 * ----------------------
 * |
 * |  加载vue-quill-editor.js 富文本框
 * |
 * ----------------------
 */
import '@/plugins/vue-quill-editor'

/**
 * ----------------------
 * |
 * |  加载vuescroll.js 滚动条
 * |
 * ----------------------
 */
import '@/plugins/vuescroll'

// moment.js
import moment from 'moment'; //导入模块
moment.locale('zh-cn');
Vue.prototype.$moment = moment;//赋值使用


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
