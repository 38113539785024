<template>
  <!-- 交易统计 -->
  <div>
    <!-- 筛选区域 -->
    <div class="wScreen-area">
      <!-- 时间选择 -->
      <div class="wScreenBox">
        <!-- 下拉选择 -->
        <div class="wScreen-select">
          <el-select
            v-model="wScreen.value"
            placeholder="请选择"
            @change="wDoChangeSelect_time"
          >
            <el-option
              v-for="(item, index) in wScreen.options"
              :key="index"
              :label="item.label"
              :value="index"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 时间选择 -->
        <div class="wScreen-datePicker" v-if="wScreen.options.length > 0">
          <el-date-picker
            v-model="wScreen.options[wScreen.value].value"
            :disabled="!(wScreen.value == wScreen.options.length - 1)"
            type="monthrange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="wDoChangeDatePicker_time"
          >
          </el-date-picker>
        </div>
      </div>
    </div>
    <!-- 统计信息 -->
    <div class="wST-statistics">
      <div class="wSTS-item">
        <div class="wSTS-iconBox">
          <img
            class="wSTS-icon"
            src="@/assets/image/ldhg_money001.png"
            alt="图标"
          />
        </div>
        <div class="wSTS-info">
          <div class="wSTSI-label">收入(元)</div>
          <div class="wSTSI-value">{{ wStatistics.transaction.income }}</div>
        </div>
      </div>
      <div class="wSTS-itemLine"></div>
      <div class="wSTS-item">
        <div class="wSTS-iconBox">
          <img
            class="wSTS-icon"
            src="@/assets/image/ldhg_money002.png"
            alt="图标"
          />
        </div>
        <div class="wSTS-info">
          <div class="wSTSI-label">支出金额(元)</div>
          <div class="wSTSI-value">{{ wStatistics.transaction.expend }}</div>
        </div>
      </div>
      <div class="wSTS-itemLine"></div>
      <div class="wSTS-item">
        <div class="wSTS-iconBox">
          <img
            class="wSTS-icon"
            src="@/assets/image/ldhg_money003.png"
            alt="图标"
          />
        </div>
        <div class="wSTS-info">
          <div class="wSTSI-label">总佣金</div>
          <div class="wSTSI-value">
            {{ wStatistics.transaction.commission }}
          </div>
        </div>
      </div>
      <div class="wSTS-itemLine"></div>
      <div class="wSTS-item">
        <div class="wSTS-iconBox">
          <img
            class="wSTS-icon"
            src="@/assets/image/ldhg_money003.png"
            alt="图标"
          />
        </div>
        <div class="wSTS-info">
          <div class="wSTSI-label">双品牌收入</div>
          <div class="wSTSI-value">
            0.00
            <!-- {{ wStatistics.transaction.commission }} -->
          </div>
        </div>
      </div>
    </div>
    <!-- 统计区域 -->
    <div class="wST-Echarts">
      <div class="wSTE-item">
        <div class="wSTE-head">
          <div class="wSTEH-spot"></div>
          <div class="wSTEH-text">收入概况</div>
        </div>
        <div class="wSTE-body">
          <div class="wSTEB-column">
            <template v-if="false">
              <div class="wSTEBC-item">
                <div class="wSTEBC-label">订单收入</div>
                <div class="wSTEBC-value" @click="wToIncome('202')">
                  {{ wStatistics.income.order }}
                </div>
              </div>
              <div class="wSTEBC-item">
                <div class="wSTEBC-label">充值收入</div>
                <div class="wSTEBC-value" @click="wToIncome('102')">
                  {{ wStatistics.income.recharge }}
                </div>
              </div>
              <div class="wSTEBC-item">
                <div class="wSTEBC-label">门店收入</div>
                <div class="wSTEBC-value" @click="wToIncome('103')">
                  {{ wStatistics.income.shop }}
                </div>
              </div>
            </template>
            <template v-for="(wItem, wIndex) in wStatistics.income">
              <div class="wSTEBC-item" :key="wIndex">
                <div class="wSTEBC-label">
                  {{ wItem.incomeExpenditureTypeName }}
                </div>
                <div
                  class="wSTEBC-value"
                  @click="wToIncome(wItem.incomeExpenditureType)"
                >
                  {{ wItem.sumMoney || "0.00" }}
                </div>
              </div>
            </template>
          </div>
          <div class="wSTEB-EchartsBox" id="myChart_income"></div>
        </div>
      </div>
      <div class="wSTE-item">
        <div class="wSTE-head">
          <div class="wSTEH-spot"></div>
          <div class="wSTEH-text">支出概况</div>
        </div>
        <div class="wSTE-body">
          <div class="wSTEB-column">
            <template v-if="false">
              <div class="wSTEBC-item">
                <div class="wSTEBC-label">退款</div>
                <div class="wSTEBC-value" @click="wToExpend('201')">
                  {{ wStatistics.expend.refund }}
                </div>
              </div>
              <div class="wSTEBC-item">
                <div class="wSTEBC-label">提现</div>
                <div class="wSTEBC-value" @click="wToExpend('202')">
                  {{ wStatistics.expend.withdrawal }}
                </div>
              </div>
            </template>
            <template v-for="(wItem, wIndex) in wStatistics.expend">
              <div class="wSTEBC-item" :key="wIndex">
                <div class="wSTEBC-label">
                  {{ wItem.incomeExpenditureTypeName }}
                </div>
                <div
                  class="wSTEBC-value"
                  @click="wToExpend(wItem.incomeExpenditureType)"
                >
                  {{ wItem.sumMoney || "0.00" }}
                </div>
              </div>
            </template>
          </div>
          <div class="wSTEB-EchartsBox" id="myChart_expend"></div>
        </div>
      </div>
      <div class="wSTE-item">
        <div class="wSTE-head">
          <div class="wSTEH-spot"></div>
          <div class="wSTEH-text">佣金概况</div>
        </div>
        <div class="wSTE-body">
          <div class="wSTEB-column">
            <template v-for="(wItem, wIndex) in wStatistics.commission">
              <div class="wSTEBC-item" :key="wIndex">
                <div class="wSTEBC-label">
                  {{ wItem.incomeTypeName }}
                </div>
                <div
                  class="wSTEBC-value"
                  @click="wToCommission(wItem.incomeType)"
                >
                  {{ wItem.commissionMoney || "0.00" }}
                </div>
              </div>
            </template>
          </div>
          <div class="wSTEB-EchartsBox" id="myChart_commission"></div>
        </div>
      </div>
      <div class="wSTE-item">
        <div class="wSTE-head">
          <div class="wSTEH-spot"></div>
          <div class="wSTEH-text">未发放佣金概况</div>
        </div>
        <div class="wSTE-body">
          <div class="wSTEB-column">
            <template v-for="(wItem, wIndex) in wStatistics.unissued">
              <div class="wSTEBC-item" :key="wIndex">
                <div class="wSTEBC-label">
                  {{ wItem.incomeTypeName }}
                </div>
                <div
                  class="wSTEBC-value"
                  @click="wToUnissued(wItem.incomeType)"
                >
                  {{ wItem.commissionMoney || "0.00" }}
                </div>
              </div>
            </template>
          </div>
          <div class="wSTEB-EchartsBox" id="myChart_unissued"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import { statistics_financeMain } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 时间筛选条件
      wScreen: {
        timeArr: [0, 3, 6, 9], // 时间差，0-本月，3-最近3月，6-最近6月，9-最近9月
        value: 0,
        options: [],
      },

      // 统计信息
      wStatistics: {
        // 实际收入(元)、支出金额(元)、总佣金
        transaction: {
          income: "0.00", // 收入
          expend: "0.00", // 支出
          commission: "0.00", // 总佣金
          doubleBrandIncome: "0.00", // 双品牌收入
        },
        // 收入概况
        income: [],
        // 支出概况
        expend: [],
        // 佣金概况
        commission: [],
        // 未发放佣金概况
        unissued: [],
      },
    };
  },
  mounted() {
    // Promise.all()方法用于将多个 Promise 实例，包装成一个新的 Promise 实例。
    // Promise.all([this.wDic_screen()])
    //   .then((posts) => {
    //     // 查询财务统计主数据
    //     this.wGetFinanceMainData();
    //   })
    //   .catch((reason) => {
    //     console.log(reason);
    //   });
  },
  methods: {
    /**
     *获取几个月前的输入日期
     *{param:DateTime} date 输入日期(YYYY-MM-DD)
     *{param:number } monthNum 月数
     */
    getPreMonthDay(date, monthNum) {
      var dateArr = date.split("-");
      var year = dateArr[0]; //获取当前日期的年份
      var month = dateArr[1]; //获取当前日期的月份
      var day = dateArr[2]; //获取当前日期的日
      var days = new Date(year, month, 0);
      days = days.getDate(); //获取当前日期中月的天数
      var year2 = year;
      var month2 = parseInt(month) - monthNum;
      if (month2 <= 0) {
        year2 =
          parseInt(year2) -
          parseInt(Math.abs(month2) / 12 + 1);
        month2 = 12 - (Math.abs(month2) % 12);
      }
      var day2 = day;
      var days2 = new Date(year2, month2, 0);
      days2 = days2.getDate();
      if (day2 > days2) {
        day2 = days2;
      }
      if (month2 < 10) {
        month2 = "0" + month2;
      }
      var t2 = year2 + "-" + month2 + "-" + day2;
      return t2;
    },
    /**
     * 获取某年月的天数
     * @param year 年
     * @param month 月（0-11）
     * @returns {number} 天数
     */
    getDaysOfMonth(year, month) {
      month = month + 1;
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return 31;
        case 4:
        case 6:
        case 9:
        case 11:
          return 30;
        case 2:
          return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
            ? 29
            : 28;
        default:
          return 0;
      }
    },
    /**
     * wDic_screen
     * 获取筛选的时间
     */
    wDic_screen() {
      return new Promise((resolve, reject) => {
        let { timeArr } = this.wScreen;
        // 数据处理
        let wSOptions = [];
        timeArr.forEach((item) => {
          let wObj = {};
          const end = new Date();
          const start = new Date();
          if (item == 0) {
            wObj.label = "本月";
          } else {
            wObj.label = `最近${item}月`;
          }
          let wTime_start =
            start.getFullYear() +
            "-" +
            (parseInt(start.getMonth() + 1) < 10
              ? "0" + parseInt(start.getMonth() + 1)
              : parseInt(start.getMonth() + 1)) +
            "-01";
          let wStartTime = this.getPreMonthDay(wTime_start, (item <= 0 ? 0 : (item - 1)));
          wObj.value = [new Date(wStartTime), end];
          wSOptions.push(wObj);
        });

        // 添加自定义选择时间
        wSOptions.push({
          label: "自定义",
          value: "",
        });

        console.log(wSOptions);

        // 更新数据
        this.wScreen.options = wSOptions;
        resolve("success");
        // 错误的情况下才执行
        true || reject("false");
      });
    },

    /**
     * wGetFinanceMainData
     * 查询财务统计主数据
     */
    wGetFinanceMainData() {
      // 请求参数
      let wData = {};

      // 获取值
      let { value, options } = this.wScreen;

      let timeArr = options[value].value;
      console.log(timeArr);
      let wTime_start =
        timeArr[0].getFullYear() +
        "-" +
        (parseInt(timeArr[0].getMonth() + 1) < 10
          ? "0" + parseInt(timeArr[0].getMonth() + 1)
          : parseInt(timeArr[0].getMonth() + 1)) +
        "-01";
      let wGetDate = this.getDaysOfMonth(
        timeArr[1].getFullYear(),
        timeArr[1].getMonth()
      );
      let wTime_end =
        timeArr[1].getFullYear() +
        "-" +
        (parseInt(timeArr[1].getMonth() + 1) < 10
          ? "0" + parseInt(timeArr[1].getMonth() + 1)
          : parseInt(timeArr[1].getMonth() + 1)) +
        "-" +
        wGetDate;
      console.log(wTime_start);
      console.log(wTime_end);
      // 添加参数
      wData.startDate = wTime_start;
      wData.endDate = wTime_end;
      // 请求函数
      statistics_financeMain(wData).then((res) => {
        // 返回值
        let resData = res;

        // 更新数据
        // 统计信息
        this.wStatistics = {
          // 实际收入(元)、支出金额(元)、总佣金
          transaction: {
            income: (
              Math.floor(parseFloat(resData.realIncome || 0) * 100) / 100
            ).toFixed(2), // 收入
            expend: (
              Math.floor(parseFloat(resData.expend || 0) * 100) / 100
            ).toFixed(2), // 支出
            commission: (
              Math.floor(parseFloat(resData.allCommission || 0) * 100) / 100
            ).toFixed(2), // 总佣金
            doubleBrandIncome: (
              Math.floor(parseFloat(resData.doubleBrandIncome || 0) * 100) / 100
            ).toFixed(2), // 双品牌
          },
          // 收入概况
          income: resData.sumIncomeVOS,
          // 支出概况
          expend: resData.sumExpenditureVOS,
          // 佣金概况
          commission: resData.commissionArrivalSumVOList,
          // 未发放佣金概况
          unissued: resData.commissionNonArrivalSumVOList,
        };
        this.$nextTick(() => {
          // 绘制饼状图
          this.wDoDrawerPieChart();
        });
      });
    },

    /**
     * wDoDrawerPieChart
     * 绘制饼状图
     */
    wDoDrawerPieChart() {
      // 收入概况
      let { income: wS_income } = this.wStatistics;
      // 数据处理
      let wIncome_titleText = "";
      let wIncome_seriesData = [];
      wS_income.forEach((wItem, wIndex) => {
        if (wIndex == 0) {
          wIncome_titleText += wItem.incomeTypeName;
        } else {
          wIncome_titleText += "、" + wItem.incomeTypeName;
        }
        wIncome_seriesData.push({
          value: parseFloat(wItem.sumMoney),
          name: wItem.incomeExpenditureTypeName,
        });
      });
      // 基于准备好的dom，初始化echarts实例
      // 基于准备好的dom，初始化echarts实例
      let myChart_income = echarts.init(
        document.getElementById("myChart_income")
      );
      // 绘制图表
      myChart_income.setOption({
        title: {
          show: false,
          text: "收入概况",
          subtext: `${wExpendOption_titleText}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "收入概况",
            type: "pie",
            radius: "50%",
            data: wIncome_seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });

      // 支出概况
      let { expend: wS_expend } = this.wStatistics;
      // 数据处理
      let wExpendOption_titleText = "";
      let wExpendOption_seriesData = [];
      wS_expend.forEach((wItem, wIndex) => {
        if (wIndex == 0) {
          wExpendOption_titleText += wItem.incomeTypeName;
        } else {
          wExpendOption_titleText += "、" + wItem.incomeTypeName;
        }
        wExpendOption_seriesData.push({
          value: parseFloat(wItem.sumMoney),
          name: wItem.incomeExpenditureTypeName,
        });
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart_expend = echarts.init(
        document.getElementById("myChart_expend")
      );
      // 绘制图表
      myChart_expend.setOption({
        title: {
          show: false,
          text: "支出概况",
          subtext: `${wExpendOption_titleText}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "支出概况",
            type: "pie",
            radius: "50%",
            data: wExpendOption_seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });

      // 佣金概况
      let { commission: wS_commission } = this.wStatistics;
      // 数据处理
      let wCommissionOption_titleText = "";
      let wCommissionOption_seriesData = [];
      wS_commission.forEach((wItem, wIndex) => {
        if (wIndex == 0) {
          wCommissionOption_titleText += wItem.incomeTypeName;
        } else {
          wCommissionOption_titleText += "、" + wItem.incomeTypeName;
        }
        wCommissionOption_seriesData.push({
          value: parseFloat(wItem.commissionMoney),
          name: wItem.incomeTypeName,
        });
      });
      // 基于准备好的dom，初始化echarts实例
      let myChart_commission = echarts.init(
        document.getElementById("myChart_commission")
      );
      // 绘制图表
      myChart_commission.setOption({
        title: {
          show: false,
          text: "佣金概况",
          subtext: `${wCommissionOption_titleText}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "佣金概况",
            type: "pie",
            radius: "50%",
            data: wCommissionOption_seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });

      // 未发放佣金概况
      let { unissued: wS_unissued } = this.wStatistics;
      // 数据处理
      let wUnissuedOption_titleText = "";
      let wUnissuedOption_seriesData = [];
      wS_unissued.forEach((wItem, wIndex) => {
        if (wIndex == 0) {
          wUnissuedOption_titleText += wItem.incomeTypeName;
        } else {
          wUnissuedOption_titleText += "、" + wItem.incomeTypeName;
        }
        wUnissuedOption_seriesData.push({
          value: parseFloat(wItem.commissionMoney),
          name: wItem.incomeTypeName,
        });
      });

      // 基于准备好的dom，初始化echarts实例
      let myChart_unissued = echarts.init(
        document.getElementById("myChart_unissued")
      );
      // 绘制图表
      myChart_unissued.setOption({
        title: {
          show: false,
          text: "未发放佣金概况",
          subtext: `${wUnissuedOption_titleText}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "未发放佣金概况",
            type: "pie",
            radius: "50%",
            data: wUnissuedOption_seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },

    /**
     * wDoChangeSelect_time
     * 时间下拉选择
     */
    wDoChangeSelect_time(wP_value) {
      console.log(wP_value);
      let { options } = this.wScreen;
      if (wP_value == options.length - 1) {
        this.wScreen.options[wP_value].value = [];
      } else {
        this.$nextTick(() => {
          // 查询财务统计主数据
          this.wGetFinanceMainData();
        });
      }
    },

    /**
     * wDoChangeDatePicker_time
     * 时间下拉选择
     */
    wDoChangeDatePicker_time(wP_value) {
      console.log(wP_value);
      this.$nextTick(() => {
        // 查询财务统计主数据
        this.wGetFinanceMainData();
      });
    },

    /**
     * wToIncome
     * 跳转至收入概括
     * wP_type
     */
    wToIncome(wP_type) {
      // 传递参数
      let wData = {};
      wP_type && (wData.type = wP_type);
      let wTime = this.wGetSelectedTimeString(); // 挑选出来的时间串
      wTime && (wData.time = wTime);
      // 页面跳转
      this.$router.push({
        name: "statisticsIncome",
        query: wData,
      });
    },

    /**
     * wToExpend
     * 跳转至支出概括
     * wP_type
     */
    wToExpend(wP_type) {
      // 传递参数
      let wData = {};
      wP_type && (wData.type = wP_type);
      let wTime = this.wGetSelectedTimeString(); // 挑选出来的时间串
      wTime && (wData.time = wTime);
      // 页面跳转
      this.$router.push({
        name: "statisticsExpend",
        query: wData,
      });
    },

    /**
     * wToCommission
     * 跳转至佣金概括
     * wP_type
     */
    wToCommission(wP_type) {
      // 传递参数
      let wData = {};
      wP_type && (wData.type = wP_type);
      let wTime = this.wGetSelectedTimeString(); // 挑选出来的时间串
      wTime && (wData.time = wTime);
      // 页面跳转
      this.$router.push({
        name: "statisticsCommission",
        query: wData,
      });
    },

    /**
     * wToUnissued
     * 跳转至未发放佣金概括
     * wP_type
     */
    wToUnissued(wP_type) {
      // 传递参数
      let wData = {};
      wP_type && (wData.type = wP_type);
      let wTime = this.wGetSelectedTimeString(); // 挑选出来的时间串
      wTime && (wData.time = wTime);
      // 页面跳转
      this.$router.push({
        name: "statisticsUnissued",
        query: wData,
      });
    },

    /**
     * wGetSelectedTimeString
     * 挑选出来的时间串
     */
    wGetSelectedTimeString() {
      // 获取查询时间
      let [
        {
          wScreen: { value: wValue, options: wOptions },
        },
      ] = [this];
      let wTime = "";
      if (wOptions[wValue].value.length == 2) {
        let wTime_begin = new Date(wOptions[wValue].value[0]);
        let wTime_end = new Date(wOptions[wValue].value[1]);
        wTime =
          wTime_begin.getFullYear().toString() +
          (wTime_begin.getMonth() + 1 < 10
            ? ("0" + (wTime_begin.getMonth() + 1)).toString()
            : (wTime_begin.getMonth() + 1).toString()) +
          wTime_end.getFullYear() +
          (wTime_end.getMonth() + 1 < 10
            ? ("0" + (wTime_end.getMonth() + 1)).toString()
            : (wTime_end.getMonth() + 1).toString());
      }
      return wTime;
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 筛选区域
.wScreen-area {
  display: block;
  width: 100%;

  .wScreenBox {
    display: flex;
    width: 100%;
    align-items: center;

    .wScreen-select {
      flex: none;
      width: 120px;
    }
  }
}
// 统计信息
.wST-statistics {
  display: flex;
  width: 100%;
  height: 130px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;

  .wSTS-item {
    display: flex;
    width: 25%;
    flex: none;
    justify-content: center;
    align-items: center;

    .wSTS-iconBox {
      flex: none;
      width: 50px;
      margin-right: 20px;

      .wSTS-icon {
        display: block;
        width: 100%;
      }
    }

    .wSTS-info {
      flex: none;

      .wSTSI-label {
        display: block;
        height: 16px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
      }

      .wSTSI-value {
        display: block;
        height: 32px;
        font-size: 32px;
        font-weight: 600;
        color: #ff6321;
        line-height: 32px;
        letter-spacing: 1px;
        margin-top: 10px;
      }
    }
  }

  .wSTS-itemLine {
    flex: none;
    width: 1px;
    height: 30px;
    background: #eeeeee;
  }
}

// 统计区域
.wST-Echarts {
  display: flex;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  justify-content: space-between;
  // align-items: flex-start;
  flex-wrap: wrap;

  .wSTE-item {
    flex: none;
    width: 49%;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: hidden;
    margin-bottom: 20px;
    padding-top: 60px;

    .wSTE-head {
      position: absolute;
      display: flex;
      width: 100%;
      height: 60px;
      top: 0;
      left: 0;
      padding: 0 30px;
      box-sizing: border-box;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      user-select: none;
      z-index: 10;

      .wSTEH-spot {
        flex: none;
        width: 10px;
        height: 10px;
        background-color: @wColor_theme;
        border-radius: 50%;
        margin-right: 10px;
      }

      .wSTEH-text {
        flex: none;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }
    }

    .wSTE-body {
      display: flex;
      width: 100%;
      // height: 460px;
      padding: 30px 36px;
      box-sizing: border-box;
      align-items: center;
      user-select: none;

      .wSTEB-column {
        flex: auto;

        .wSTEBC-item {
          display: block;
          user-select: none;

          .wSTEBC-label {
            display: block;
            font-size: 14px;
            color: #999;
            margin-bottom: 10px;
          }

          .wSTEBC-value {
            display: inline-block;
            font-size: 22px;
            color: #333;
            font-weight: 700;
            margin-bottom: 20px;
            cursor: pointer;

            &:hover {
              color: @wColor_theme;
            }
          }
        }
      }

      .wSTEB-EchartsBox {
        flex: none;
        width: 500px;
        height: 500px;
      }
    }
  }
}
</style>