<template>
    <div class="wTableArea">
        <div class="add-territory">
            <!-- 添加会员按钮 1、先选用户id 2、代理类型 3、代理城市 -->
            <el-button class="wML-addBtn" size="small" type="primary" @click="addTerritory">
                添加代理
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table border :data="table">

            <el-table-column prop="userId" label="用户ID" align="center">
            </el-table-column>

            <el-table-column prop="type" label="代理类型" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.type == 1">区域代理</div>
                    <div v-if="scope.row.type == 2">城市代理</div>
                </template>
            </el-table-column>

            <el-table-column prop="territory" label="代理区域" align="center">
            </el-table-column>

            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="removeTerritory(scope, 'delete')"><span>删除</span>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="wPagination">
            <el-pagination 
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.current"
                :page-sizes="pages.sizes"
                :page-size="pages.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.total">
            </el-pagination>
        </div>
        <el-dialog title="添加代理" :visible.sync="dialogVisible" width="800px" append-to-body :close-on-click-modal="false">
            <el-form :rules="rules" ref="form" :model="form" label-width="80px">
                <el-form-item label="代理用户" prop="userId">
                    <!-- <el-input v-model="form.userId"></el-input> -->
                    <el-button @click="selectUser">{{ form.userId ? '已选择 ' + userName : '选择' }}</el-button>
                </el-form-item>
                <el-form-item label="代理类型" prop="type">
                    <!-- <el-input v-model="form.type"></el-input> -->
                    <el-select v-model="form.type" placeholder="请选择活动区域" @change="changeSelectType">
                        <el-option label="区域代理" value="1"></el-option>
                        <el-option label="城市代理" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="代理区域" prop="territory">
                    <!-- <el-input v-model="form.territory"></el-input> -->
                    <el-cascader expand-trigger="hover" :options="options" @change="changeTerritory"></el-cascader>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="clickConfirm('form')">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="代理用户" :visible.sync="beneficiary.isShow" append-to-body :close-on-click-modal="false">
            <!-- 搜索区 -->
            <div class="wETJRFSerach-area">
                <el-input style="width: 100%; max-width: 400px" type="text" v-model="beneficiary.keyword" maxlength="11"
                    placeholder="请输入手机号"></el-input>
                <el-button type="primary" @click="wETJRFSerachBtn">搜索</el-button>
            </div>
            <!-- 表格数据区 -->
            <div class="wETJRFTable-area">
                <el-table :data="beneficiary.list" style="width: 100%">
                    <el-table-column label="会员">
                        <template slot-scope="scope">
                            <div class="wETJRFTable-user">
                                <el-avatar :size="30" :src="scope.row.avatar"></el-avatar>
                                <div class="wETJRFTable-userName">{{ scope.row.nickName }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="phone" label="手机号"> </el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" @click="wETJRFSerachChoice(scope.row)">
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <div class="wPagination">
                    <el-pagination background layout="total, prev, pager, next, jumper" :current-page="beneficiary.page"
                        :page-size="beneficiary.limit" :total="beneficiary.total" :hide-on-single-page="false"
                        @current-change="wELPageCurrentChange_edit" @size-change="wELPageSizeChange_edit">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        
    </div>
</template>
  
<script>
// 引入文件
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";
import { userTerritoryListApi, userTerritoryAddApi, removeUserTerritoryApi, } from "@/network/api.js";
export default {
    components: {
        wPageCrumbs
    },
    name: "OrderList",
    data() {
        return {
            arrayData:[],
            pages:{
                current: 1,
                sizes: [10,20,50,100],
                size: 10,
                total: 0,
            },
            // 表格数据
            table: [],
            userName: '',
            form: {
                userId: "",
                type: "",
                territory: "",
            },
            rules: {
                userId: [
                    { required: true, message: '请选择用户', trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择代理类型', trigger: 'blur' }
                ],
                territory: [
                    { required: true, message: '请选择地区', trigger: 'blur' }
                ],
            },
            dialogVisible: false,
            dialogVisibles: false,
            options: [],
            city: [
                {
                    value: '重庆市',
                    label: '重庆市',
                    disabled: true,
                    children: [
                        {
                            value: '渝北区',
                            label: '渝北区',
                        }, {
                            value: '江北区',
                            label: '江北区',
                        }, {
                            value: '南岸区',
                            label: '南岸区',
                        },
                    ]
                }, {
                    value: '辽宁省',
                    label: '辽宁省',
                    disabled: true,
                    children: [
                        {
                            value: '大连市',
                            label: '大连市',
                        }, {
                            value: '沈阳市',
                            label: '沈阳市',
                        }, {
                            value: '鞍山市',
                            label: '鞍山市',
                        },
                    ]
                }
            ],
            beneficiary: {
                isShow: false,
                keyword: "",
                page: 1, // 分页
                limit: 6, // 条数
                total: 0, // 总数
                list: [] // 数据
            }
        };
    },
    mounted() {
        this.getUserTerritoryList()
    },
    computed: {

    },
    methods: {
        getUserTerritoryList() {
            // this.table = []
            userTerritoryListApi().then(res => {
                // console.log(res)
                this.arrayData = res
                // 显示共计多少页
                this.pages.total = res.length
                this.processingPaging()
            })
        },

        changeTerritory(e) {
            this.form.territory = e.join("")
        },
        // 点击确定事件
        clickConfirm(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    console.log('submit!!');
                    userTerritoryAddApi(this.form).then(res => {
                        this.dialogVisible = false
                        this.getUserTerritoryList()
                        this.$message({
                            type: "success",
                            message: "新增成功!"
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },
        addTerritory() {
            this.dialogVisible = true
        },
        removeTerritory(e) {
            console.log(e.row.id)
            const id = e.row.id
            removeUserTerritoryApi({ id: id }).then(res => {
                console.log(res)
                this.getUserTerritoryList()
                this.$message({
                    type: "success",
                    message: "删除成功!"
                });
            })

        },
        selectUser() {
            // this.dialogVisibles = true
            this.wGetMemberBySearch()
        },
        // 选择类型事件
        changeSelectType(e) {
            const array = this.city
            this.options = []
            if (e == 2) {
                for (let i = 0; i < array.length; i++) {
                    array[i].disabled = false
                    const obj = {
                        value: array[i].value,
                        label: array[i].label,
                        disabled: array[i].disabled,
                    }
                    this.options.push(obj)
                }
            }
            if (e == 1) {
                for (let i = 0; i < array.length; i++) {
                    array[i].disabled = false
                    const obj = {
                        value: array[i].value,
                        label: array[i].label,
                        disabled: array[i].disabled,
                        children: array[i].children,
                    }
                    this.options.push(obj)
                }
            }
        },
        // 搜索用户
        wETJRFSerachBtn() {
            // 更改当前页码数据
            this.beneficiary.page = 1;
            this.$nextTick(() => {
                // 获取搜索列表数据
                this.wGetMemberBySearch();
            });
        },
        wGetMemberBySearch() {
            // 请求参数
            let wData = {};
            // 查询条件
            let {
                keyword, // 搜索关键字
                page, // 分页
                limit // 条数
            } = this.beneficiary;
            // 判断条件
            if (keyword) {
                wData.phone = keyword;
            }
            wData["pageModel.pageNo"] = page;
            wData["pageModel.pageSize"] = limit;

            // 请求函数
            this.$api.member_list({
                data: wData,
                success: res => {
                    // 更新数据
                    this.beneficiary.total = res.total;
                    this.beneficiary.list = res.records;
                    // 打开弹框
                    this.$nextTick(() => {
                        this.beneficiary.isShow = true;
                    });
                }
            });
        },
        wETJRFSerachChoice(data) {
            this.form.userId = data.id;
            this.userName = data.nickName;
            this.beneficiary.isShow = false;
        },
        wELPageSizeChange_edit(wP_cPageSize) {
            // 更改当前页码数据
            this.beneficiary.page = 1;
            this.beneficiary.limit = wP_cPageSize;
            this.$nextTick(() => {
                // 获取列表数据
                this.wGetMemberBySearch();
            });
        },
        wELPageCurrentChange_edit(wP_cPage) {
            // 更改当前页码数据
            this.beneficiary.page = wP_cPage;
            this.$nextTick(() => {
                // 获取列表数据
                this.wGetMemberBySearch();
            });
        },
        // 处理分页数据
        processingPaging(){
            this.table = []
            const ary =  this.arrayData
            const num = (this.pages.current*this.pages.size)-(this.pages.size)
            let rem = Math.floor(ary.length/this.pages.size)
            const mod = ary.length%this.pages.size
            if (mod != 0) { rem ++ }
            if (this.pages.current == 1) {
                if (ary.length < this.pages.size) {
                    for (let i = 0; i < ary.length; i++) {
                        this.table.push(ary[i])
                    }
                } else {
                    for (let i = 0; i < this.pages.size; i++) {
                        this.table.push(ary[i])
                    }
                }
                return
            }
            if (this.pages.current == rem) {
                for (let i = num; i < ary.length; i++) {
                    this.table.push(ary[i])
                }
                return
            }
            if (this.pages.current != 1 && this.pages.current != rem) {
                for (let i = num; i < this.pages.size+num; i++) {
                    this.table.push(ary[i])
                }
                return
            }
        },
        // 当前页面显示多少条数据
        handleSizeChange(e){
            this.pages.size = e
            this.pages.current = 1
            this.processingPaging()
        },
        // 跳转第n页
        handleCurrentChange(e){
            this.pages.current = e
            this.processingPaging()
        }
    },
    beforeDestroy() {

    }
};
</script>
  
<style lang='less' scoped>
@import "@/assets/css/publicStyle.less";

.add-territory {
    padding: 10px 0;
    margin-bottom: 10px;
}

.wScreenCardHeader-right {
    display: flex;
    align-items: center;

    .wSCHR-title {
        flex: none;
        padding-right: 20px;
        box-sizing: border-box;
        color: #333333;
    }
}

// 订单详情
.wOrderDetailsArea {
    position: fixed;
    top: 50px;
    left: @wWidth_sidebar_o;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    .wOrderDetails {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        background-color: #ffffff;

        .wOD-head {
            position: absolute;
            height: 70px;
            top: 0;
            left: 40px;
            right: 40px;
            padding-top: 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #dddddd;

            .wODH-label {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
            }

            .wODH-btn {
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 18px;
                color: #333333;
                cursor: pointer;
                border-radius: 4px;
                overflow: hidden;

                &:hover {
                    color: #000000;
                    background-color: #f6f8fb;
                }
            }
        }

        .wOD-body {
            position: absolute;
            top: 70px;
            left: 40px;
            right: 40px;
            bottom: 20px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;

            // 详情
            .wODB-main {
                display: block;
                width: calc(100% - 10px);
            }
        }
    }
}

// 表格板块
.wTable-block {
    display: block;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    .wTableB-head {
        margin-bottom: 10px;

        .wTableBH-label {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }

        .wTableBH-orderState {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
    }

    .wTableB-body {
        display: block;
        width: 100%;

        // 表格数据
        .wTableBB-table {
            display: block;
            width: 100%;
            border: 1px solid #dddddd;
            box-sizing: border-box;

            .wTableBBT-tr {
                width: 100%;
                background-color: #ffffff;
                border-top: 1px solid #dddddd;

                &.wTableBBT-tHead {
                    min-height: 45px;
                    padding: 10px 0;
                    box-sizing: border-box;
                    background-color: #eeeeee;
                    border-top: 0;

                    .wTableBBT-td {
                        color: #aaaaaa;
                    }
                }

                &.wTableBBT-tBody {
                    min-height: 45px;
                    padding: 10px 0;
                    box-sizing: border-box;
                }

                .wTableBBT-td {
                    padding: 0 5px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>