<template>
  <!-- 交易统计 -->
  <div>
    <!-- 筛选区域 -->
    <div class="wScreen-area">
      <!-- 时间选择 -->
      <div class="wScreenBox">
        <!-- 下拉选择 -->
        <div>
          <el-select
            v-model="query.storeId"
            placeholder="请选择门店"
            @change="changeStore"
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 统计区域 -->
    <div class="wST-Echarts">
      <div class="wSTE-item">
        <div class="wSTE-head">
          <div class="wSTEH-spot"></div>
          <div class="wSTEH-text">销量排行</div>
        </div>
        <div class="wSTE-body">
          <div class="wSTEB-column">
            <template v-for="(wItem, wIndex) in wStatistics.unissued">
              <div class="wSTEBC-item" :key="wIndex">
                <div class="wSTEBC-label">
                  {{ wItem.incomeTypeName }}
                </div>
                <div class="wSTEBC-value">
                  {{ wItem.commissionMoney || "0.00" }}
                </div>
              </div>
            </template>
          </div>
          <div class="wSTEB-EchartsBox" id="myChart_unissued"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

import { statistics_salesApi } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 时间筛选条件
      wScreen: {
        timeArr: [0, 3, 6, 9], // 时间差，0-本月，3-最近3月，6-最近6月，9-最近9月
        value: 0,
        options: [],
      },

      // 统计信息
      wStatistics: {
        // 实际收入(元)、支出金额(元)、总佣金
        transaction: {
          income: "0.00", // 收入
          expend: "0.00", // 支出
          commission: "0.00", // 总佣金
          doubleBrandIncome: "0.00", // 双品牌收入
        },
        // 收入概况
        income: [],
        // 支出概况
        expend: [],
        // 佣金概况
        commission: [],
        // 未发放佣金概况
        unissued: [],
      },

      storeList: [], // 门店
      query: {
        storeId: undefined,
      },
    };
  },
  mounted() {
    // Promise.all()方法用于将多个 Promise 实例，包装成一个新的 Promise 实例。
    // Promise.all([this.wDic_screen()])
    //   .then((posts) => {
    //     // 查询财务统计主数据
    //     this.wGetFinanceMainData();
    //   })
    //   .catch((reason) => {
    //     console.log(reason);
    //   });

    this.wGetStoreList();
  },
  methods: {
    changeStore(e) {
      console.log(e, this.query);
      this.getData();
    },
    wGetStoreList() {
      let wData = {};
      wData["pageModel.pageNo"] = 1;
      wData["pageModel.pageSize"] = 99999;

      this.$api.store_list({
        data: wData,
        success: (res) => {
          this.storeList = res.records;
          if (res.records.length >= 1) {
            this.query.storeId = res.records[0].id;
            this.getData();
          }
        },
      });
    },
    getData() {
      statistics_salesApi(this.query).then((res) => {
        console.log(res);
      });
    },

    /**
     * wDoDrawerPieChart
     * 绘制饼状图
     */
    wDoDrawerPieChart() {
      // 未发放佣金概况
      let { unissued: wS_unissued } = this.wStatistics;
      // 数据处理
      let wUnissuedOption_titleText = "";
      let wUnissuedOption_seriesData = [];
      wS_unissued.forEach((wItem, wIndex) => {
        if (wIndex == 0) {
          wUnissuedOption_titleText += wItem.incomeTypeName;
        } else {
          wUnissuedOption_titleText += "、" + wItem.incomeTypeName;
        }
        wUnissuedOption_seriesData.push({
          value: parseFloat(wItem.commissionMoney),
          name: wItem.incomeTypeName,
        });
      });

      // 基于准备好的dom，初始化echarts实例
      let myChart_unissued = echarts.init(
        document.getElementById("myChart_unissued")
      );
      // 绘制图表
      myChart_unissued.setOption({
        title: {
          show: false,
          text: "未发放佣金概况",
          subtext: `${wUnissuedOption_titleText}`,
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "未发放佣金概况",
            type: "pie",
            radius: "50%",
            data: wUnissuedOption_seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 筛选区域
.wScreen-area {
  display: block;
  width: 100%;

  .wScreenBox {
    display: flex;
    width: 100%;
    align-items: center;

    .wScreen-select {
      flex: none;
      width: 120px;
    }
  }
}

// 统计区域
.wST-Echarts {
  display: flex;
  width: 100%;
  border-radius: 10px;
  margin-top: 20px;
  justify-content: space-between;
  // align-items: flex-start;
  flex-wrap: wrap;

  .wSTE-item {
    flex: none;
    // width: 49%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: hidden;
    margin-bottom: 20px;
    padding-top: 60px;

    .wSTE-head {
      position: absolute;
      display: flex;
      width: 100%;
      height: 60px;
      top: 0;
      left: 0;
      padding: 0 30px;
      box-sizing: border-box;
      align-items: center;
      border-bottom: 1px solid #eeeeee;
      user-select: none;
      z-index: 10;

      .wSTEH-spot {
        flex: none;
        width: 10px;
        height: 10px;
        background-color: @wColor_theme;
        border-radius: 50%;
        margin-right: 10px;
      }

      .wSTEH-text {
        flex: none;
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }
    }

    .wSTE-body {
      display: flex;
      width: 100%;
      // height: 460px;
      padding: 30px 36px;
      box-sizing: border-box;
      align-items: center;
      user-select: none;

      .wSTEB-column {
        flex: auto;

        .wSTEBC-item {
          display: block;
          user-select: none;

          .wSTEBC-label {
            display: block;
            font-size: 14px;
            color: #999;
            margin-bottom: 10px;
          }

          .wSTEBC-value {
            display: inline-block;
            font-size: 22px;
            color: #333;
            font-weight: 700;
            margin-bottom: 20px;
            cursor: pointer;

            &:hover {
              color: @wColor_theme;
            }
          }
        }
      }

      .wSTEB-EchartsBox {
        flex: none;
        width: 500px;
        height: 500px;
      }
    }
  }
}
</style>
