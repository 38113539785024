<template>
  <!-- 收入概况 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div slot="header" class="wScreenCardHeader">
          <span>筛选查询</span>
          <el-button type="primary" size="mini" @click="wDoExportData">
            导出数据
          </el-button>
        </div>
        <!-- 筛选列表 -->
        <el-form
          ref="wScreenForm"
          label-position="left"
          label-width="90px"
          :model="wScreen"
          @submit.native.prevent
        >
          <el-row :gutter="20">
            <!-- 收入分类 -->
            <el-col :span="6">
              <el-form-item label="收入分类" prop="wCType">
                <el-select
                  v-model="wScreen.wCType"
                  placeholder="全部分类"
                  @change="wchange_wCType"
                >
                  <el-option
                    v-for="item in wC_wCType"
                    :key="item.wValue"
                    :label="item.wLabel"
                    :value="item.wValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 二级分类 -->
            <el-col :span="6" :hidden="wScreen.wCType != 101">
              <el-form-item label="二级分类" prop="wCType02">
                <el-select
                  v-model="wScreen.wCType02"
                  placeholder="全部分类"
                  @change="wchange_wCType02"
                >
                  <el-option
                    v-for="item in wC_wCType02"
                    :key="item.wValue"
                    :label="item.wLabel"
                    :value="item.wValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 查询时间 -->
            <el-col :span="6">
              <el-form-item label="查询时间" prop="wTimeSlot">
                <el-date-picker
                  style="width: 100%"
                  v-model="wScreen.wTimeSlot"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="wchange_wTimeSlot"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <!-- 操作按钮 -->
            <el-col :span="24" v-if="false">
              <el-button size="medium" @click="wFormScreenReset">
                重置
              </el-button>
              <el-button size="medium" @click="wFormScreenSubmit">
                查询
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>

    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 订单收入 -->
      <template v-if="wScreen.wCType == '101'">
        <!-- 表格 -->
        <el-table border :data="wTable.wList">
          <el-table-column label="用户" align="center" width="250">
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <el-avatar
                  :src="scope.row.avatar"
                  style="margin-right: 10px"
                ></el-avatar>
                <div>{{ scope.row.nickName }}</div>
              </div>
              <div>手机号:{{ scope.row.phone }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="orderName" label="内容" align="center">
          </el-table-column>
          <el-table-column prop="orderTypeName" label="订单分类" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="时间" align="center">
          </el-table-column>
          <el-table-column
            prop="orderRealMoney"
            label="金额(元)"
            align="center"
          >
          </el-table-column>
        </el-table>
      </template>
      <!-- 用户充值收入 -->
      <template v-if="wScreen.wCType == '102'">
        <!-- 表格 -->
        <el-table border :data="wTable.wList">
          <el-table-column label="用户" align="center" width="250">
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <el-avatar
                  :src="scope.row.avatar"
                  style="margin-right: 10px"
                ></el-avatar>
                <div>{{ scope.row.nickName }}</div>
              </div>
              <div>手机号:{{ scope.row.phone }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="内容" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="时间" align="center">
          </el-table-column>
          <el-table-column prop="money" label="金额(元)" align="center">
          </el-table-column>
        </el-table>
      </template>
      <!-- 门店收入 -->
      <template v-if="wScreen.wCType == '103'">
        <!-- 表格 -->
        <el-table border :data="wTable.wList">
          <el-table-column label="用户" align="center" width="250">
            <template slot-scope="scope">
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                v-if="scope.row.nickName"
              >
                <el-avatar
                  :src="scope.row.avatar"
                  style="margin-right: 10px"
                ></el-avatar>
                <div>{{ scope.row.nickName }}</div>
              </div>
              <div
                v-if="scope.row.phone">手机号:{{ scope.row.phone }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="dishTotalAmountYuan"
            label="订单总金额(元)"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="id" label="订单号" align="center">
          </el-table-column>
          <el-table-column prop="orderTypeName" label="订单类型" align="center">
          </el-table-column>
          <el-table-column prop="shopName" label="门店名称" align="center">
          </el-table-column>
          <el-table-column prop="paymentTime" label="支付时间" align="center">
          </el-table-column>
        </el-table>
      </template>
      <!-- 暂无数据 -->
      <template v-if="false">
        <!-- 表格 -->
        <el-table border> </el-table>
      </template>
      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination
          background
          :layout="wC_layout"
          :current-page="wTable.wPage"
          :page-size="wTable.wLimit"
          :total="wTable.wTotal"
          :page-sizes="wC_pageSizes"
          :hide-on-single-page="false"
          @current-change="wELPageCurrentChange"
          @size-change="wELPageSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  statistics_queryExpendType,
  statistics_queryIncomByOrder,
  statistics_queryIncomByRecharge,
} from "@/network/wApi.js";
import { wApi_statistics_queryIncomeListByStore } from "@/plugins/wApi.js";

export default {
  data() {
    return {
      // 字典数据-收入分类
      wDic_CType: [],
      // 筛选查询
      wScreen: {
        wCType: null, // 收入分类, 默认0-全部分类
        wCType02: "0", // 收入分类, 默认0-全部分类
        wTimeSlot: null, // 查询时间段
      },
      // 表格数据
      wTable: {
        wPage: 1,
        wLimit: 10,
        wTotal: 0,
        wList: [],
      },
    };
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout;
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes;
    },
    // 获取筛选条件的收入分类数据
    wC_wCType() {
      let wCTypeData = [];
      this.wDic_CType.forEach((item, index) => {
        wCTypeData.push({
          wValue: item.id.toString(),
          wLabel: item.incomeExpenditureName,
        });
      });
      return wCTypeData;
    },
    // 获取筛选条件的二级分类数据
    wC_wCType02() {
      // 获取一级选择
      let [{ wCType }] = [this.wScreen];
      let wCTypeData = [
        {
          wValue: "0",
          wLabel: "全部分类",
        },
      ];
      this.wDic_CType.forEach((item, index) => {
        if (item.id.toString() == wCType) {
          let wSub = item.orderTypeVOList || [];
          wSub.forEach((element) => {
            wCTypeData.push({
              wValue: element.id.toString(),
              wLabel: element.name,
            });
          });
        }
      });
      return wCTypeData;
    },
  },
  mounted() {
    // 获取参数
    if (this.$route.query.type) {
      this.wScreen.wCType = this.$route.query.type.toString();
    }
    if (this.$route.query.time && this.$route.query.time.length == 12) {
      let wTime_begin = new Date(
        this.$route.query.time.substring(0, 4) +
          "-" +
          this.$route.query.time.substring(4, 6) +
          "-" +
          "01 00:00:00"
      );
      let wTime_end_date = new Date(
        this.$route.query.time.substring(6, 10),
        this.$route.query.time.substring(10, 12),
        "00"
      ).getDate();
      let wTime_end = new Date(
        this.$route.query.time.substring(6, 10) +
          "-" +
          this.$route.query.time.substring(10, 12) +
          "-" +
          wTime_end_date +
          " 23:59:59"
      );
      // 更新数据
      this.wScreen.wTimeSlot = [wTime_begin, wTime_end];
    }
    this.$nextTick(() => {
      // Promise.allSettled()方法，用来确定一组异步操作是否都结束了（不管成功或失败）
      Promise.allSettled([this.wQueryExpendTypeData()]).then((results) => {
        console.log(results);
        this.wTable.wPage = 1;
        this.$nextTick(() => {
          // 查询表格数据
          this.wQueryTableData();
        });
      });
    });
  },
  methods: {
    /**
     * wFormScreenReset
     * 重置表单数据
     */
    wFormScreenReset() {
      this.$refs["wScreenForm"].resetFields();
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wFormScreenSubmit
     * 提交表单数据
     */
    wFormScreenSubmit() {
      // 查询表格数据
      this.wQueryTableData();
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTable.wPage = wP_cPage;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wELPageSizeChange
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wTable.wPage = 1;
      this.wTable.wLimit = wP_cPageSize;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wDoExportData
     * 导出数据
     */
    wDoExportData() {
      this.$message("功能待开发");
    },
    /**
     * wchange_wCType
     * 收入分类切换选择
     * @param {String} wP_value 目前的选中值
     */
    wchange_wCType(wP_value) {
      // 更新数据
      this.wScreen.wCType02 = "0";

      this.wTable.wPage = 1;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wchange_wCType02
     * 收入二级分类切换选择
     * @param {String} wP_value 目前的选中值
     */
    wchange_wCType02(wP_value) {
      this.wTable.wPage = 1;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wchange_wTimeSlot
     * 收入时间筛选切换选择
     * @param {Array} wP_value 组件绑定值。格式与绑定值一致，可受 value-format 控制
     */
    wchange_wTimeSlot(wP_value) {
      this.wTable.wPage = 1;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wQueryExpendTypeData
     * 查询收入分类数据
     */
    wQueryExpendTypeData() {
      return new Promise((resolve, reject) => {
        // 请求参数
        let wData = {};
        // 添加参数 1-收入 0-支出
        wData.exchangeType = 1;
        // 请求函数
        statistics_queryExpendType(wData)
          .then((res) => {
            // 更新数据
            res.forEach((element) => {
              this.wDic_CType.push(element);
            });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wQueryTableData
     * 查询表格数据
     */
    wQueryTableData() {
      let [
        wData,
        {
          wScreen: { wCType, wCType02, wTimeSlot },
          wTable: { wPage, wLimit },
        },
      ] = [{}, this];
      // 查询时间
      if (wTimeSlot && wTimeSlot.length == 2) {
        wData.startDate = `${wTimeSlot[0].getFullYear()}-${
          wTimeSlot[0].getMonth() + 1 < 10
            ? "0" + (wTimeSlot[0].getMonth() + 1)
            : wTimeSlot[0].getMonth() + 1
        }-${
          wTimeSlot[0].getDate() < 10
            ? "0" + wTimeSlot[0].getDate()
            : wTimeSlot[0].getDate()
        }`;
        wData.endDate = `${wTimeSlot[1].getFullYear()}-${
          wTimeSlot[1].getMonth() + 1 < 10
            ? "0" + (wTimeSlot[1].getMonth() + 1)
            : wTimeSlot[1].getMonth() + 1
        }-${
          wTimeSlot[1].getDate() < 10
            ? "0" + wTimeSlot[1].getDate()
            : wTimeSlot[1].getDate()
        }`;
      }
      // 分页参数
      wData.pageModel = {};
      wData.pageModel.pageNo = wPage;
      wData.pageModel.pageSize = wLimit;
      // 添加分类,0-支出，1-收入
      wData.exchangeType = 1;
      // 更加wCType-支出类型做不同的操作
      switch (wCType && wCType.toString()) {
        case "101":
          // 订单收入
          {
            // 订单收入-二级分类
            if (wCType02 != "0") {
              wData.orderType = wCType02;
            }
            // 请求参数
            statistics_queryIncomByOrder(JSON.stringify(wData))
              .then((res) => {
                let [{ total: wTotal, records: wList }] = [res];

                // 更新数据
                this.wTable.wTotal = wTotal;
                this.wTable.wList = wList;
              })
              .catch((err) => {
                // 更新数据
                this.wTable.wTotal = 0;
                this.wTable.wList = [];
              });
          }
          break;
        case "102":
          // 用户充值收入
          {
            // 请求参数
            statistics_queryIncomByRecharge(JSON.stringify(wData))
              .then((res) => {
                let [{ total: wTotal, records: wList }] = [res];

                // 更新数据
                this.wTable.wTotal = wTotal;
                this.wTable.wList = wList;
              })
              .catch((err) => {
                // 更新数据
                this.wTable.wTotal = 0;
                this.wTable.wList = [];
              });
          }
          break;
        case "103":
          // 门店收入
          {
            // 请求参数
            wApi_statistics_queryIncomeListByStore({
              data: JSON.stringify(wData),
            })
              .then((res) => {
                let [{ total: wTotal, records: wList }] = [res.data];

                // 更新数据
                this.wTable.wTotal = wTotal;
                this.wTable.wList = wList;
              })
              .catch((err) => {
                // 更新数据
                this.wTable.wTotal = 0;
                this.wTable.wList = [];
              });
          }
          break;

        default:
          {
            this.$message.warning("功能待开发！");
            // 更新数据
            this.wTable.wTotal = 0;
            this.wTable.wList = [];
          }
          break;
      }
    },

    // 获取数据类型对应的名称
    wGetTableType(wP_type) {
      let wP_typeName = "";
      for (let i = 0; i < this.wDic_CType.length; i++) {
        let item = this.wDic_CType[i];
        if (item.id == wP_type) {
          wP_typeName = item.name;
          break;
        }
      }
      return wP_typeName;
    },
  },
};
</script>

<style lang="less" scoped>
// 收入概况
.wStatistics-income {
  display: block;
  width: 100%;
  padding: 30px 60px;
  box-sizing: border-box;
  background-color: #ffffff;

  .wSI-head {
    display: flex;
    padding: 30px 0 0;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #f3f3f3;

    .wSIH-screen {
      flex: auto;
    }

    .wSIH-export {
      flex: none;
      padding-left: 20px;
      box-sizing: border-box;
    }
  }

  .wSI-body {
    display: block;
    width: 100%;

    .wSIB-head {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      align-items: center;
      user-select: none;

      .wSIBH-list {
        flex: none;
        display: flex;
        align-items: center;

        .wSIBH-item {
          flex: none;
          text-align: center;
          width: 120px;
          line-height: 40px;
          background-color: #eeeeee;
          border: 1px solid #dddddd;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          cursor: pointer;

          &.wActive,
          &:hover {
            color: #ffffff;
            border-color: #ff6321;
            background-color: #ff6321;
          }
        }
      }
    }

    .wSIB-table {
      display: block;
      width: 100%;
      margin-top: 30px;
    }
  }
}
</style>