import axios from 'axios'
import qs from 'qs'
import ElementUI from 'element-ui';

export function request(config) {
  return new Promise((resolve, reject) => {

    // 1.创建axios的实例
    const instance = axios.create({
      baseURL: (config.baseUrl ? config.baseUrl : process.env.VUE_APP_URE),
      method: config.method || 'POST',
      params: config.params || {},
      // method: config.method == 1 ? 'POST' : 'GET',
      data: config.data || {},
      headers: {
        'Content-Type': (config.isHeader == 1 ? 'application/json' : 'application/x-www-form-urlencoded; charset=utf-8;'),
        'token': localStorage.getItem('USER_TOKEN') || ''
      },
      timeout: 30000
    })

    // 请求拦截
    instance.interceptors.request.use(config => {
      config.data = config.isHeader ? config.data : qs.stringify(config.data)
      if (!localStorage.getItem('USER_TOKEN').length) {
        ElementUI.Message({
          message: '登录失效，请重新登录',
          type: 'error'
        });
        let myTime = setTimeout(() => {
          this.$router.push('/login')
          clearTimeout(myTime)
        }, 2000);
      }
      return config
    })
    // 2.2.响应拦截
    instance.interceptors.response.use(res => {
      // console.log(res)
      if (res.data.code == 401) {
        ElementUI.Message({
          message: '登录失效，请重新登录',
          type: 'error'
        });
        let myTime = setTimeout(() => {
          this.$router.push('/login')
          clearTimeout(myTime)
        }, 2000);
        reject(res)
      } else if (res.data.code == 200) {
        resolve(res.data.data)
      } else {
        ElementUI.Message({
          message: res.data.msg,
          type: 'error'
        });
        reject(res)
      }
    }, error => {
      if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
        ElementUI.Message({
          message: '请求超时',
          type: 'error'
        });
      }
      reject(error)
    })

    // 3.发送真正的网络请求
    return instance(config)
  })
}