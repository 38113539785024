<template>
  <!-- 广告列表 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div class="wScreenCardHeader">
          <span></span>
          <el-button type="primary" size="mini" @click="wFun_openAnnouncementFrame()">发布公告</el-button>
        </div>
      </el-card>
    </div>
    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 公告列表 -->
      <template>
        <!-- 表格 -->
        <el-table border :data="wTable.wList">
          <el-table-column prop="id" label="编号" align="center" width="100">
          </el-table-column>
          <el-table-column label="广告内容" align="center" prop="content">
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <el-button type="text" @click="wFun_openAnnouncementFrame(scope)"><span>编辑</span></el-button>
              <el-button type="text" @click="wFun_deleteAnnouncementById(scope.row.id)"><span>删除</span></el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="wTable.wPage"
          :page-size="wTable.wLimit" :total="wTable.wTotal" :page-sizes="[5, 10, 15, 20, 50]"
          :hide-on-single-page="false" @current-change="wELPageCurrentChange" @size-change="wELPageSizeChange">
        </el-pagination>
      </div>
    </div>

    <!-- 新增、修改版announcement弹框 -->
    <el-dialog :title="wAnnouncement.wForm.wId ? '修改公告' : '添加公告'" :visible.sync="wAnnouncement.wIsShow"
      :append-to-body="true" :close-on-click-modal="false">
      <!-- 表单 -->
      <el-form ref="wAnnouncementForm" :model="wAnnouncement.wForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="公告内容：" prop="wContent" required>
          <el-input v-model="wAnnouncement.wForm.wContent" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <!-- footer -->
      <div slot="footer">
        <el-button @click="wAnnouncement.wIsShow = false">取 消</el-button>
        <el-button type="primary" @click="wFun_submitAnnouncementInfo">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  inquiryNotice,
  addOperateAnnouncementApi,
  editOperateAnnouncementApi,
  deleteOperateAnnouncementApi
} from "@/network/api";

export default {
  name: "OperateAnnouncement",
  data() {
    return {
      // 筛选查询
      wScreen: {
        wLocation: null // 广告位
      },
      // 表格数据
      wTable: {
        wPage: 1,
        wLimit: 5,
        wTotal: 0,
        wList: []
      },
      // 添加/修改announcement弹框
      wAnnouncement: {
        wIsShow: false, // 是否展示
        wForm: {
          wId: null, // id 有值代表
          wContent: null, // 公告内容
          wSort: 0 // 排序
        } // 表单数据
      }
    };
  },
  mounted() {
    this.wFun_queryTableData(true);
  },
  computed: {
  },
  methods: {
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTable.wPage = wP_cPage
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wFun_queryTableData()
      })
    },

    /**
     * wELPageSizeChange
     * 修改分页条数
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wTable.wPage = 1
      this.wTable.wLimit = wP_cPageSize
      this.$nextTick(() => {
        // 获取提现审核列表数据
        this.wFun_queryTableData()
      })
    },

    /**
     * wFun_queryTableData
     * 方法-查询表格数据
     * @param wP_truthy 真值,true-初始值,false-加载更多
     */
    wFun_queryTableData(wP_truthy) {
      let wData = {}
      // 分页数据
      let { wPage, wLimit } = this.wTable
      let pageModel = {
        pageNo: wPage,
        pageSize: wLimit
      }
      wData['pageModel'] = pageModel
      // 请求函数
      inquiryNotice(wData)
        .then(res => {
          console.log('广告信息', res);
          // 更新数据
          this.wTable.wList = res.records;
          this.wTable.wTotal = res.total;
        })
        .catch(err => { });
    },

    /**
     * wFun_deleteAnnouncementById
     * 方法-根据id删除banner
     * @wP_id banner的id
     */
    wFun_deleteAnnouncementById(wP_id) {
      if (!wP_id) {
        this.$message.error("系统繁忙，请稍后再试！");
        return;
      }
      this.$confirm("此操作将永久删除该公告, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteOperateAnnouncementApi({
            id: wP_id
          })
            .then(res => {
              this.$message.success("删除成功!");
              this.wFun_queryTableData();
            })
            .catch(err => { });
        })
        .catch(() => { });
    },
    /**
     * wFun_openAnnouncementFrame
     * 方法-打开announcement弹框
     * @param wP_obj 打开announcement弹框信息
     */
    wFun_openAnnouncementFrame(wP_obj) {
      // 打开弹框
      this.wAnnouncement.wIsShow = true
      this.$nextTick(() => {
        // 表单初始化
        this.$refs["wAnnouncementForm"].resetFields();
        this.wAnnouncement.wForm.wId = null;
        if (wP_obj) {
          // 修改announcement，给表单赋值
          let [
            {
              row: {
                id: wId,
                content: wContent,
                sort: wSort // 是否有效 0-无效 1-有效
              }
            }
          ] = [wP_obj];
          // 更新数据
          this.wAnnouncement.wForm.wId = wId;
          this.wAnnouncement.wForm.wContent = wContent;
          this.wAnnouncement.wForm.wSort = wSort == 1;
        }
      });
    },

    /**
     * wchange_location
     * 选中值发生变化时触发
     */

    /**
     * wchange_jumpType
     * 选中值发生变化时触发
     */

    /**
     * wFun_submitBannerInfo
     * 方法-提交banner信息
     */
    wFun_submitAnnouncementInfo() {
      let { wId, wContent, wSort } = this.wAnnouncement.wForm
      let wData = {
        id: wId,
        content: wContent,
        sort: Number(wSort),
      }
      console.log('wData', wData);
      // 根据id是否存在判断新增或修改
      if (wId) {
        // 修改
        // announcement id
        // wData.id = wForm.wId;

        editOperateAnnouncementApi(wData)
          .then(res => {
            this.$message.success("修改成功!");
            // 更新数据
            this.wAnnouncement.wIsShow = false;
            this.wFun_queryTableData();
          })
          .catch(err => { });
      } else {
        // 新增
        addOperateAnnouncementApi(wData)
          .then(res => {
            console.log(res);
            this.$message.success("添加成功!");
            // 更新数据
            this.wAnnouncement.wIsShow = false;
            this.wFun_queryTableData();
          })
          .catch(err => { });
      }
    }

  }
};
</script>

<style lang="less">
@import "@/assets/css/publicStyle.less";
</style>