<template>
    <div class="wTableArea">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>筛选查询</span>
            </div>
            <!-- 筛选列表 -->
            <div class="wMLS-list">
                <el-form ref="" label-position="left" label-width="90px" :model="form">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="手机号码">
                                <el-input v-model="form.userPhone" type="text" placeholder="请输入创建者手机号" style="width: 100%; max-width: 200px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店标识">
                                <el-select v-model="form.identifying" placeholder="请选择门店标识">
                                    <el-option v-for="(item,index) in option"  :label="item.label" :value="item.value" :key="item.value" > </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="form.storeName" type="text" placeholder="请输入门店名称" style="width: 100%; max-width: 200px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-button size="medium" @click="clickReset "> 重置 </el-button>
                            <el-button size="medium" @click="clickSearch"> 查询 </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>

        <!-- 表格 -->
        <el-table border :data="table" v-loading="loading">
            <el-table-column prop="userName" label="用户昵称" align="center" width="180"></el-table-column>
            <el-table-column prop="userPhone" label="用户手机" align="center"  width="180"></el-table-column>
            <el-table-column label="门店标识" align="center"  width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.userIdentity == 'HN'">顾客门店</el-tag>
                    <el-tag v-if="scope.row.userIdentity == 'V1'">会员门店</el-tag>
                    <el-tag v-if="scope.row.userIdentity == 'V2'">VIP门店</el-tag>
                    <el-tag v-if="scope.row.userIdentity == 'CN'">渠道代理门店</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="storeName" label="门店名称" align="center"></el-table-column>
            <el-table-column label="营业执照" width="150" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 50px;height:50px" :src="scope.row.storeImage"
                        :preview-src-list="[scope.row.storeImage]"></el-image>
                </template>
            </el-table-column>

            <el-table-column prop="storeAddress" label="门店地址" align="center"></el-table-column>

            <!-- <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small"><span>查看</span></el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <div class="wPagination">
            <el-pagination 
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pages.current"
                :page-sizes="pages.sizes"
                :page-size="pages.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pages.total">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
// 引入文件
import { getDevStoreListApi } from "@/network/api.js";
export default {
    components: {},
    data() {
        return {
            option:[
                {
                    label:"会员门店",
                    value:"V1",
                },{
                    label:"VIP门店",
                    value:"V2",
                },{
                    label:"渠道代理门店",
                    value:"CN",
                }
            ],
            form:{
                userPhone:"",
                identifying:"",
                storeName:""
            },
            // 表格数据
            table: [],
            loading: false,
            arrayData:[],
            pages:{
                current: 1,
                sizes: [10,20,50,100],
                size: 10,
                total: 0,
            }


        };
    },
    mounted() {
        this.getDevStoreList()
    },
    computed: {

    },
    methods: {
        // 筛选重置
        clickReset (){
            this.form = {
                userPhone:"",
                identifying:"",
                storeName:""
            }
        },
        // 筛选查询
        clickSearch(){
            this.loading = true
            console.log(this.form)
            const data = {
                phone: this.form.userPhone,
                type: this.form.identifying,
                storeName: this.form.storeName
            }
            getDevStoreListApi(data).then( res => {
                this.arrayData = res.data                   // 临时存放分页数据
                this.pages.total = this.arrayData.length    // 显示共计多少页                   
                this.processingPaging()                  // 处理分页数据
            })
        },
        // 获取开发门店数据
        getDevStoreList() {
            this.loading = true
            getDevStoreListApi().then( res => {
                console.log(res)
                this.arrayData = res
                this.pages.total = this.arrayData.length
                this.processingPaging()
            })            
        },

        // 处理分页数据
        processingPaging(){
            this.loading = false,
            this.table = []
            const ary =  this.arrayData
            const num = (this.pages.current*this.pages.size)-(this.pages.size)
            let rem = Math.floor(ary.length/this.pages.size)
            const mod = ary.length%this.pages.size
            if (mod != 0) { rem ++ }
            if (this.pages.current == 1) {
                if (ary.length < this.pages.size) {
                    for (let i = 0; i < ary.length; i++) {
                        this.table.push(ary[i])
                    }
                } else {
                    for (let i = 0; i < this.pages.size; i++) {
                        this.table.push(ary[i])
                    }
                }
                return
            }
            if (this.pages.current == rem) {
                for (let i = num; i < ary.length; i++) {
                    this.table.push(ary[i])
                }
                return
            }
            if (this.pages.current != 1 && this.pages.current != rem) {
                for (let i = num; i < this.pages.size+num; i++) {
                    this.table.push(ary[i])
                }
                return
            }
        },

        // 当前页面显示多少条数据
        handleSizeChange(e){
            this.pages.size = e
            this.pages.current = 1
            this.processingPaging()
        },
        // 跳转第n页
        handleCurrentChange(e){
            this.pages.current = e
            this.processingPaging()
        }

    }
};
</script>
<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";



</style></style>