<template>
    <!-- 广告列表 -->
    <div>
        <!-- 筛选查询 -->
        <!-- <div class="wScreenArea">
        <el-card class="box-card">
          <div class="wScreenCardHeader">
            <span></span>
            <el-button
              type="primary"
              size="mini"
              @click="wFun_openBannerFrame()"
            >添加</el-button>
          </div>
        </el-card>
      </div> -->
        <!-- 表格数据 -->
        <div class="wTableArea">
            <!-- 订单列表 -->
            <template>
                <!-- 表格 -->
                <el-table border :data="list_phone">
                    <el-table-column label="客服电话" prop="phone" align="center"></el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" @click="openDialogPhone(scope.row)">
                                <span>修改</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <!-- 分页 -->
        </div>

        <!-- 新增、修改版banner弹框 -->
        <el-dialog title="修改客服电话" :visible.sync="dialogPhone" :append-to-body="true"
            :close-on-click-modal="false">
            <!-- 表单 -->
            <el-form ref="wBannerForm" :model="form" label-width="120px">
                <el-form-item label="客服电话" prop="">
                    <el-input v-model="form.phone" placeholder="请输入客服电话" :disabled="false" />
                </el-form-item>
            </el-form>
            <!-- footer -->
            <div slot="footer">
                <el-button @click="dialogPhone = false">取 消</el-button>
                <el-button type="primary" @click="updatePhone">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
import {
    getServerPhoneApi,
    gupdateServerPhoneApi
} from "@/network/api";

export default {
    name: "OperateBanner",
    data() {
        return {
            list_phone:[],
            form: {
                phone:""
            },
            dialogPhone: false,
        };
    },
    mounted() {
        this.getServerPhone()
    },
    computed: {
  
    },
    methods: {
        openDialogPhone(row){
            // console.log(row.phone)
            this.form.phone = row.phone
            this.dialogPhone = true
            
        },
        // 查询客服电话
        getServerPhone(){
            getServerPhoneApi().then(res => {
                // console.log(res)
                let ary = []
                ary.push({phone: res.value})
                this.list_phone = ary
            })
        },
        updatePhone(){
            gupdateServerPhoneApi(this.form).then(res => {
                // console.log("修改",res)
                this.dialogPhone = false
                this.getServerPhone()
            })
        }
    }
};
</script>
  
<style lang="less">
@import "@/assets/css/publicStyle.less";

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #ff6321;
}

.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>