<template>
  <!-- 用户管理 -->
  <div>
    <!-- 用户列表 -->
    <div class="wSet-user">
      <div class="wSU-head">
        <el-button
          type="primary"
          @click="wDoOpenFrame('add')"
        >新增</el-button>
      </div>
      <div class="wSU-body">
        <el-table :data="wUser.list">
          <el-table-column
            prop="account"
            label="账号"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="昵称"
          ></el-table-column>
          <el-table-column
            prop="roleName"
            label="角色"
          ></el-table-column>
          <el-table-column
            prop="storeName"
            label="所属店铺"
          ></el-table-column>
          <el-table-column
            prop="loginTime"
            label="登录时间"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <el-switch
                :value="scope.row.wState"
                :active-text="scope.row.wState ? '已启用' : '已禁用'"
                @change="wDoChangeSwitch(scope.row)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="300"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="wDoRechargePWD(scope.row.id)"
              >
                重置密码
              </el-button>
              <el-button
                type="text"
                @click="wDoOpenFrame('edit', scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                @click="wDoDelete(scope.row.id)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="wUser.page"
            :page-size="wUser.limit"
            :total="wUser.total"
            :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false"
            @current-change="wELPageCurrentChange"
            @size-change="wELPageSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新添用户弹框 -->
    <el-dialog
      :title="wFrameAdd.type == 0 ? '新增' : '编辑'"
      :visible.sync="wFrameAdd.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form
        ref="wFrameAdd"
        :model="wFrameAdd.form"
        label-width="100px"
      >
        <el-form-item
          label="用户账号:"
          prop="account"
        >
          <el-input
            v-model="wFrameAdd.form.account"
            :disabled="wFrameAdd.type == 1"
            placeholder="请输入用户账号"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="权限角色:"
          prop="role"
        >
          <el-select
            v-model="wFrameAdd.form.role"
            placeholder="请选择权限角色"
            style="width: 100%; max-width: 250px"
          >
            <el-option
              v-for="item in wRoleSelect"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属店铺:">
          <el-select
            v-model="wFrameAdd.form.storeId"
            placeholder="请选择所属店铺"
            style="width: 100%; max-width: 250px"
            clearable
          >
            <el-option
              v-for="item in storeList"
              :key="item.id"
              :label="item.storeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="wDoSaveUser()"
          >确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  set_queryRoleAll,
  set_queryUserAll,
  set_doUserAdd,
  set_doUserEdit,
  set_doUserDelete,
  set_doUserRechangePWD,
  set_doUserUpdateState
} from "@/network/wApi.js";

export default {
  data() {
    return {
      // 字典-角色列表数据
      wDic_role: [],

      // 用户列表
      wUser: {
        page: 1,
        limit: 10,
        total: 0,
        list: []
      },

      // 新增弹框
      wFrameAdd: {
        isShow: false, // 是否展示
        type: 0, // 弹框类型，默认0-新增，1-编辑修改
        form: {
          cid: 0, // 用户id
          account: "", // 用户账号
          role: "", // 权限角色id
          storeId: undefined
        } // 表单数据
      },

      storeList: []
    };
  },
  mounted() {
    Promise.allSettled([this.wDicQueryRoleArr(), this.getStoreList()]).then(
      results => {
        // 查询用户列表数据
        this.wQueryUserList();
      }
    );
  },
  computed: {
    wRoleSelect: function() {
      let wRoleSelectArr = [];
      this.wDic_role.forEach(item => {
        let obj = {};
        obj.id = item.sysRole.id;
        obj.label = item.sysRole.name;
        wRoleSelectArr.push(obj);
      });
      return wRoleSelectArr;
    }
  },
  methods: {
    getStoreList() {
      return new Promise((resolve, reject) => {
        // 请求参数
        let wData = {};
        wData["pageModel.pageNo"] = 1;
        wData["pageModel.pageSize"] = 999999;

        // 请求函数
        this.$api.store_list({
          data: wData,
          success: res => {
            this.storeList = res.records;
          }
        });

        store_storeList()
          .then(res => {
            this.wDic_role = res;
            resolve("success");
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    /**
     * wDicQueryRoleArr
     * 字典-查询角色列表数据
     */
    wDicQueryRoleArr() {
      return new Promise((resolve, reject) => {
        // 请求函数
        set_queryRoleAll()
          .then(res => {
            this.wDic_role = res;
            resolve("success");
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    /**
     * wQueryUserList
     * 查询用户列表数据
     */
    wQueryUserList() {
      // 请求参数
      let wData = {};

      // 获取值
      let { page, limit } = this.wUser;
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;

      // 请求函数
      set_queryUserAll(wData).then(res => {
        // 更新数据
        this.wUser.total = res.total;
        res.records.forEach(element => {
          element.wState = element.status == 1 ? true : false;
        });
        this.wUser.list = res.records;
      });
    },

    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      // 更改当前页码数据
      this.wUser.page = wP_cPage;
      this.$nextTick(() => {
        // 查询用户列表数据
        this.wQueryUserList();
      });
    },

    /**
     * wELPageSizeChange
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wUser.page = 1;
      this.wUser.limit = wP_cPageSize;
      this.$nextTick(() => {
        // 查询用户列表数据
        this.wQueryUserList();
      });
    },

    /**
     * wDoOpenFrame
     * 打开新增编辑菜单弹框
     * wP_type 类型,
     * wP_info 修改的信息
     */
    wDoOpenFrame(wP_type, wP_info) {
      // 打开弹框
      this.wFrameAdd.isShow = true;

      this.$nextTick(() => {
        // 对该表单项进行重置
        this.$refs["wFrameAdd"].resetFields();

        // 数据处理
        switch (wP_type) {
          case "add":
            // 新增
            {
              this.wFrameAdd.type = 0;
            }
            break;
          case "edit":
            // 修改
            {
              if (!wP_info) {
                this.wFrameAdd.isShow = false;
                this.$message.error("系统繁忙，请稍后再试!");
                return;
              }
              // 赋值
              this.wFrameAdd.type = 1;
              this.wFrameAdd.form.cid = wP_info.id;
              this.wFrameAdd.form.account = wP_info.account;
              this.wFrameAdd.form.role = wP_info.roleId;
              this.wFrameAdd.form.storeId = wP_info.storeId;
            }
            break;

          default:
            break;
        }
      });
    },

    /**
     * wDoSaveUser
     * 保存用户数据
     */
    wDoSaveUser() {
      // 请求参数
      let wData = {};

      // 获取值
      let { type: wM_type, form: wM_form } = this.wFrameAdd;

      // 数据处理
      // 用户账号
      if (!wM_form.account) {
        this.$message.error("用户账号不能为空！");
        return;
      } else {
        wData.account = wM_form.account;
      }
      // 权限角色
      if (!wM_form.role) {
        this.$message.error("权限角色不能为空！");
        return;
      } else {
        wData.roleId = wM_form.role;
      }

      wData.storeId = wM_form.storeId;

      // 弹框类型，默认0-新增，1-编辑修改
      switch (`${wM_type}`) {
        case "0":
          // 新增
          {
            // 请求函数
            set_doUserAdd(wData).then(res => {
              this.$message.success("添加成功!");
              this.wFrameAdd.isShow = false;
              // 更改当前页码数据
              this.wUser.page = 1;
              this.$nextTick(() => {
                // 查询用户列表数据
                this.wQueryUserList();
              });
            });
          }
          break;
        case "1":
          // 编辑修改
          {
            // 请求函数
            wData.id = wM_form.cid;
            set_doUserEdit(wData).then(res => {
              this.$message.success("修改成功!");
              this.wFrameAdd.isShow = false;
              this.$nextTick(() => {
                // 查询用户列表数据
                this.wQueryUserList();
              });
            });
          }
          break;

        default:
          this.$message("功能待开发!");
          break;
      }

      return;
    },

    /**
     * wDoChangeSwitch
     * 启用与静止启用接口
     */
    wDoChangeSwitch(wP_scopeRow) {
      let { id } = wP_scopeRow;
      // 请求函数
      set_doUserUpdateState({
        accountId: id
      }).then(res => {
        this.$message.success("修改成功!");
        this.$nextTick(() => {
          // 查询用户列表数据
          this.wQueryUserList();
        });
      });
    },

    /**
     * wDoRechargePWD
     * 重置密码
     */
    wDoRechargePWD(wP_id) {
      this.$confirm("此操作将重置该用户密码, 是否继续?", "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 请求函数
          set_doUserRechangePWD({
            accountId: wP_id
          }).then(res => {
            this.$message.success("重置密码成功!");
            this.$nextTick(() => {
              // 查询用户列表数据
              this.wQueryUserList();
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置密码"
          });
        });
    },

    /**
     * wDoDelete
     * 删除用户
     */
    wDoDelete(wP_id) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 请求函数
          set_doUserDelete({
            ids: [wP_id]
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!"
            });
            // 查询用户列表数据
            this.wQueryUserList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 用户管理
.wSet-user {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;

  // 头部按钮
  .wSU-head {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  // 内容区
  .wSU-body {
    display: block;
    width: 100%;
  }
}
</style>