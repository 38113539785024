import { request } from "./request.js"

// 菜品 - 分类管理
// 分类列表
export function getClassify(data) {
  return request({
    url: '/goods/category/listByStoreId',
    data
  })
}
// 分类添加
export function addClassify(data) {
  return request({
    url: '/goods/category/add',
    data: data,
    isHeader: 1
  })
}
// 分类删除
export function deleteClassify(data) {
  return request({
    url: '/goods/category/removeCategorysById',
    data
  })
}
// 分类修改
export function updateClassify(data) {
  return request({
    url: '/goods/category/update',
    data: data,
    isHeader: 1
  })
}

//供应链 - 分类管理
//分类列表
export function getSupplyClassify(data) {
  return request({
    url: '/goods/category/querySupplyList',
    data: data,
    isHeader: 1
  })
}

//添加商品分类
export function AddSupply(data) {
  return request({
    url: '/goods/category/addSupplyGoods',
    data: data,
    isHeader: 1
  })
}

//编辑商品分类信息
export function updateSupply(data) {
  return request({
    url: '/goods/category/updateSupplyGoodsById',
    data: data,
    isHeader: 1
  })
}

//删除商品分类信息
export function deleteSupply(data) {
  return request({
    url: '/goods/category/deleteSupplyGoodsById',
    data
  })
}

//赶场商品-查询商品类型下拉选项
export function getSupplyOptions(data) {
  return request({
    url: '/market/querySupplyGoodsOptions',
    data,
  })
}



// 配置 - 业绩收益分佣配置
// 列表
export function getAchievementListApi(params) {
  return request({
    url: '/noodle/commission/list',
    method: 'GET',
    params
  })
}
// 新增
export function addAchievementApi(data) {
  return request({
    url: '/noodle/commission/add',
    data,
    isHeader: 1
  })
}
// 修改
export function editAchievementApi(data) {
  return request({
    url: '/noodle/commission/update',
    data,
    isHeader: 1
  })
}
// 删除
export function deleteAchievementApi(data) {
  return request({
    url: '/noodle/commission/delete',
    data,
    isHeader: 1
  })
}


// 用户
// 用户绑定业绩收益分佣配置
export function setUserCommissionApi(data) {
  return request({
    url: '/noodle/commission/user/update',
    data,
    isHeader: 1
  })
}
// 用户绑定业绩收益分佣配置详情
export function getUserCommissionApi(params) {
  return request({
    url: '/noodle/commission/user/info',
    method: 'GET',
    params
  })
}


// 门店 
// 删除门店
export function deleteStoreApi(id) {
  return request({
    url: `/store/delete?id=${id}`,
    isHeader: 1
  })
}

// 获取开发门店
export function getDevStoreListApi(params) {
  return request({
    url: `/store/queryUserStore`,
    method: 'GET',
    params
  })
}


// 运营
// 轮播管理 - 列表
export function getOperateBannerListApi(data) {
  return request({
    url: '/banner/queryBannerList',
    data
  })
}
// 轮播管理 - 新增
export function addOperateBannerApi(data) {
  return request({
    url: '/banner/addBanner',
    data,
    isHeader: 1
  })
}
// 轮播管理 - 修改
export function editOperateBannerApi(data) {
  return request({
    url: '/banner/editBanner',
    data,
    isHeader: 1
  })
}
// 轮播管理 - 删除
export function deleteOperateBannerApi(params) {
  return request({
    url: '/banner/removeBanner',
    params,
    isHeader: 1
  })
}
// 查询客服电话
export function getServerPhoneApi() {
  return request({
    url: 'config/queryConfigPhone',
    method: 'GET'
  })
}
// 修改客服电话
export function gupdateServerPhoneApi(params) {
  return request({
    url: 'config/updateConfigPhone',
    params,
    isHeader: 1
    // method: 'GET'
  })
}


 
// 查询代理  
export function userTerritoryListApi() {
  return request({
    url: '/userTerritory/userTerritoryList',
  })
}

// 新增代理
export function userTerritoryAddApi(data) {
  return request({
    url: '/userTerritory/userTerritoryAdd',
    data,
    isHeader: 1
  })
}

// 删除代理
export function removeUserTerritoryApi(data) {
  return request({
    url: '/userTerritory/removeUserTerritory',
    params:data,
    isHeader: 1
  })
}

//查询公告
export function inquiryNotice(data) {
  return request({
    url: '/announcement/query',
    data,
    isHeader: 1
  })
}

// 公告管理 - 新增
export function addOperateAnnouncementApi(data) {
  return request({
    url: '/announcement/add',
    data,
    isHeader: 1
  })
}
// 公告管理 - 修改
export function editOperateAnnouncementApi(data) {
  return request({
    url: '/announcement/edit',
    data,
    isHeader: 1
  })
}
// 公告管理 - 删除
export function deleteOperateAnnouncementApi(params) {
  return request({
    url: '/announcement/delete',
    params,
  })
}