<template>
  <!-- 商品分类 -->
  <div>
    <!-- 页面布局 -->
    <!-- <wPageCrumbs> -->
    <!-- 商品分类区域 -->
    <div class="wGC-categoryArea">
      <div class="wGC-head">
        <!-- 栏目 -->
        <div class="wGCH-column">
          <template v-for="item in wColumn.options">
            <div
              class="wGCHC-item"
              :class="{ wActive: item.id == wColumn.value }"
              :key="item.id"
              @click="wDoChoiceColumn(item.id)"
            >
              {{ item.title }}
            </div>
          </template>
          <el-select
            v-model="wCategoryFrame.storeId"
            placeholder="请选择店铺"
            @change="changeStoreList($event)"
          >
            <el-option
              v-for="(item,index) in wStore.list"
              :key="index"
              :label="item.storeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 操作按钮 -->
        <div class="wGCH-operate">
          <el-button
            type="primary"
            @click="addClassify"
          >添加商品类别</el-button>
        </div>
      </div>

      <!-- 商品分类数据 -->
      <div class="wGC-categoryList">
        <!-- table表 -->
        <div class="wGLG-table">
          <el-table
            :data="wCategory.list"
            border
            style="width: 100%"
          >
            <!-- <el-table-column label="图标" width="100">
              <template slot-scope="scope">
                <el-image :src="scope.row.imgUrl"> </el-image>
              </template>
            </el-table-column> -->
            <el-table-column
              label="分类名称"
              prop="name"
            ></el-table-column>
            <el-table-column
              label="分类排序"
              prop="sort"
            ></el-table-column>
            <el-table-column
              label="分类状态"
              prop="status"
            >
              <template slot-scope="scope">
                <el-tag
                  type="success"
                  v-if="scope.row.status!=0"
                >正常</el-tag>
                <el-tag
                  type="info"
                  v-if="scope.row.status==0"
                >停用</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'edit')"
                ><span>修改</span>
                </el-button>
                <el-button
                  type="text"
                  size="small"
                  @click="wDoTableBtn(scope, 'delete')"
                ><span>删除</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div
          class="wPagination"
          v-if="false"
        >
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="wCategory.page"
            :page-size="wCategory.limit"
            :total="wCategory.total"
            :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- </wPageCrumbs> -->

    <!-- 商品分类修改弹框 -->
    <el-dialog
      title="分类信息"
      :visible.sync="wCategoryFrame.isShow"
      width="500px"
      append-to-body
      @close="wELDialogClose()"
    >
      <el-form
        ref="wCFForm"
        :model="wCategoryFrame"
        label-width="100px"
      >
        <el-row>
          <!-- <el-col :span="24">
            <el-form-item label="图标:" prop="cIcon">
              <el-upload
                :action="wGetUploadImgPath()"
                list-type="picture-card"
                :file-list="wCategoryFrame.cIcon.list_v"
                :limit="wCategoryFrame.cIcon.limit"
                :on-remove="wELUpload_remove"
                :on-success="wELUpload_success"
                :on-error="wELUpload_error"
                :on-exceed="wELUpload_limit"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item
              label="店铺:"
              prop="storeId"
            >
              <el-select
                v-model="wCategoryFrame.storeId"
                placeholder="请选择店铺"
              >
                <el-option
                  v-for="(item,index) in wStore.list"
                  :key="index"
                  :label="item.storeName"
                  :value="item.id"
                ></el-option>s
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="分类:"
              prop="cLabel"
            >
              <el-input
                v-model="wCategoryFrame.cLabel"
                placeholder="分类名称"
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="排序:"
              prop="sort"
            >
              <el-input-number
                v-model="wCategoryFrame.sort"
                @change="handleChange"
                :min="0"
                :max="99"
                label="分类排序"
              >
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="状态:"
              prop="status"
            >
              <el-switch
                v-model="wCategoryFrame.status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value=1
                :inactive-value=0
              >
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            style="text-align: center"
          >
            <el-button
              type="primary"
              @click="wDoEditCategory()"
            >保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";

import {
  getClassify,
  addClassify,
  deleteClassify,
  updateClassify
} from "@/network/api.js";

export default {
  name: "GoodClassifyList",
  components: {
    wPageCrumbs
  },
  data() {
    return {
      // 栏目
      wColumn: {
        value: 0,
        options: [
          {
            id: 0,
            title: "全部"
          }
        ]
      },
      // 商品分类数据
      wCategory: {
        page: 1,
        limit: 1000,
        total: 0,
        list: []
      },
      // 商品列表数据
      wStore: {
        page: 1,
        limit: 1000,
        total: 0,
        list: []
      },
      // 修改分类信息弹框
      wCategoryFrame: {
        isShow: false, // 是否显示
        storeId: undefined, // 店铺
        cId: null, // 分类id
        cIcon: {
          limit: 1, // 数量限制
          list: [], // 图片集合
          list_v: [] // 图片预览集合
        }, // 分类图标
        cLabel: "", // 分类文本
        sort: 1, // 分类排序
        status: 1 // 分类状态
      },
      storeId: null
    };
  },
  created() {
    // 查询商品分类列表
    this.wGetGoodsCategoryList();
    // 查询门店列表
    this.wGetStoreList();
  },
  methods: {
    // 根据店铺切换列表
    changeStoreList(e) {
      console.log("storeInfo", e);
      this.wCategoryFrame.storeId = e;
      this.wGetGoodsCategoryList();
    },
    /**
     * wGetStoreList
     * 获取门店列表数据
     */
    wGetStoreList() {
      let { page, limit } = this.wStore;

      // 请求参数
      let wData = {};
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;
      // wData["pageModel.sortField"] = "id";
      // wData["pageModel.sortWay"] = "asc";

      // 请求函数
      this.$api.store_list({
        data: wData,
        success: res => {
          // 更新数据
          this.wStore.total = res.total;
          this.wStore.list = res.records;
          // this.wStore.list.label = res.records.storeName;
          // this.wStore.list.value = res.records.id;
        }
      });
    },
    handleChange(value) {
      console.log(value);
    },
    // 获取图片上传的地址
    wGetUploadImgPath() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`;
    },
    // 删除图片
    wELUpload_remove(file, fileList) {
      console.log(file, fileList);
      // // 数据处理
      let imgArr = [];
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].response.data
          });
        } else {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].url
          });
        }
      }
      // 更新数据
      this.wCategoryFrame.cIcon.list = imgArr;
    },
    // 图片上传成功
    wELUpload_success(response, file, fileList) {
      console.log(response, file, fileList);
      // 数据处理
      let imgArr = this.wCategoryFrame.cIcon.list;
      imgArr.push({
        name: file.name,
        url: file.response.data
      });
      // 更新数据
      this.wCategoryFrame.cIcon.list = imgArr;
    },
    // 图片上传失败
    wELUpload_error(err, file, fileList) {
      console.log(err);
      this.$message({
        message: `图片上传失败!`,
        type: `error`
      });
    },
    // 超出图片上传限制
    wELUpload_limit(files, fileList) {
      this.$message({
        message: `图片上传已达上限!`,
        type: `warning`
      });
    },
    /**
     * wDoChoiceColumn
     * 栏目选择
     */
    wDoChoiceColumn(wP_id) {
      this.wCategoryFrame.storeId = 0;
      this.wGetGoodsCategoryList();
      this.wCategoryFrame.storeId = null;
    },

    /**
     * wGetGoodsCategoryList
     * 查询商品分类列表
     */
    wGetGoodsCategoryList() {
      let Id = this.wCategoryFrame.storeId;
      if (!Id) {
        Id = 0;
      }
      // 请求函数
      getClassify({ storeId: Id }).then(res => {
        // 数据处理
        let newArr = res.map((item, index) => {
          return item;
        });

        // 更新数据
        this.wCategory.total = res.total;
        this.wCategory.list = newArr;
      });
      return false;
    },

    /**
     * wDoTableBtn
     * 表格操作
     */
    wDoTableBtn(wP_scope, wP_type) {
      console.log(wP_scope, wP_type);
      // 根据分类做操作
      switch (`${wP_type}`) {
        case `edit`:
          // 修改
          {
            // 修改数据
            this.wCategoryFrame.cId = wP_scope.row.id;
            this.wCategoryFrame.cLabel = wP_scope.row.name;
            this.wCategoryFrame.storeId = wP_scope.row.storeId;
            this.wCategoryFrame.sort = wP_scope.row.sort;
            this.wCategoryFrame.status = wP_scope.row.status;
            this.wCategoryFrame.cIcon.list = [];
            this.wCategoryFrame.cIcon.list.push({
              name: "图标修改",
              url: wP_scope.row.imgUrl
            });
            this.wCategoryFrame.cIcon.list_v = [];
            this.wCategoryFrame.cIcon.list_v.push({
              name: "图标修改",
              url: wP_scope.row.imgUrl
            });
            // 打开修改弹框
            this.wCategoryFrame.isShow = true;
          }
          break;

        case `delete`:
          // 删除
          {
            let id = wP_scope.row.id;
            // console.log("id", id);
            deleteClassify({ categorysId: id }).then(res => {
              this.$message({
                message: `删除成功！`,
                type: `success`
              });
              this.wGetGoodsCategoryList();
            });
          }
          break;

        default:
          this.$message({
            message: `功能待开发~`,
            type: `warning`
          });
          break;
      }
    },
    // 添加分类
    addClassify() {
      this.wCategoryFrame.isShow = true;
    },
    /**
     * wDoEditCategory
     * 修改商品分类信息
     */
    wDoEditCategory() {
      // 请求参数
      let wData = {};

      // 商品分类信息
      let {
        cId: wC_id,
        cIcon: { list: wC_iconArr },
        cLabel: wC_label,
        sort: sort,
        storeId: storeId,
        status: status
      } = this.wCategoryFrame;

      // 分类ID
      wData.id = wC_id;
      // 分类类名
      if (!wC_label) {
        this.$message({
          message: `分类类名不能为空！`,
          type: `warning`
        });
        return;
      }
      // 添加参数
      wData.name = wC_label;
      wData.storeId = storeId;

      // 分类图标
      // if (wC_iconArr.length == 0) {
      //   this.$message({
      //     message: `分类图标不能为空！`,
      //     type: `warning`,
      //   });
      //   return;
      // }
      // 添加参数
      // wData.imgUrl = wC_iconArr[0].url;

      // 分类排序
      wData.sort = sort;
      // 分类状态
      wData.status = status;
      // 请求函数
      wData.id
        ? updateClassify(wData).then(res => {
            this.$message({
              message: `修改成功！`,
              type: `success`
            });
            // 关闭修改弹框
            this.wCategoryFrame.isShow = false;
            this.wCategoryFrame.cId = null;
            this.wCategoryFrame.cLabel = "";
            this.wCategoryFrame.cIcon.list = [];
            this.wCategoryFrame.cIcon.list_v = [];
            // 查询商品分类列表
            this.wGetGoodsCategoryList();
          })
        : addClassify(wData).then(res => {
            this.$message({
              message: `添加成功！`,
              type: `success`
            });
            // 关闭修改弹框
            this.wCategoryFrame.isShow = false;
            this.wCategoryFrame.cId = null;
            this.wCategoryFrame.cLabel = "";
            this.wCategoryFrame.cIcon.list = [];
            this.wCategoryFrame.cIcon.list_v = [];
            // 查询商品分类列表
            this.wGetGoodsCategoryList();
          });
    },
    /**
     * wELDialogClose
     * Dialog 关闭的回调
     */
    wELDialogClose() {
      // 关闭修改弹框
      this.wCategoryFrame.isShow = false;
      this.wCategoryFrame.cId = null;
      this.wCategoryFrame.cLabel = "";
      this.wCategoryFrame.cIcon.list = [];
      this.wCategoryFrame.cIcon.list_v = [];
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 商品分类区域
.wGC-categoryArea {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  .wGC-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    // 栏目
    .wGCH-column {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      user-select: none;

      .wGCHC-item {
        flex: none;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 10px;
        box-sizing: border-box;
        background-color: #f3f3f3;
        // border: 1px solid #999999;
        font-size: @wFontSize;
        cursor: pointer;
        transition: all 0.3s;

        &:hover,
        &.wActive {
          background-color: @wColor_theme;
          border-color: @wColor_theme;
          color: #ffffff;
        }
      }
    }
  }

  // 商品分类数据
  .wGC-categoryList {
    display: block;
    width: 100%;

    // table表
    .wGLG-table {
      display: block;
      width: 100%;
    }

    // 分页
    .wGLG-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>