<template>
  <!-- 商品分类 => 门店列表 -->
  <div>
    <!-- 页面布局 -->
    <!-- <wPageCrumbs> -->
    <!-- 商品分类区域 -->
    <div class="wGC-categoryArea">
      <div class="wGC-head">
        <!-- 栏目 -->
        <div class="wGCH-column">
          <!-- <template v-for="item in wColumn.options">
            <div
              class="wGCHC-item"
              :class="{ wActive: item.id == wColumn.value }"
              :key="item.id"
              @click="wDoChoiceColumn(item.id)"
            >
              {{ item.title }}
            </div>
          </template> -->
        </div>
        <!-- 操作按钮 -->
        <!-- <div class="wGCH-operate">
          <el-button type="primary" @click="wDoUpdateStore">更新门店</el-button>
        </div> -->
        <div class="wGCH-operate">
          <el-button type="primary" @click="wDoTableBtn('', 'add')">新增门店</el-button>
        </div>
      </div>

      <!-- 商品分类数据 -->
      <div class="wGC-categoryList">
        <!-- table表 -->
        <div class="wGLG-table">
          <el-table :data="wCategory.list" border style="width: 100%" v-loading="tableLoading">
            <el-table-column label="店铺名称" prop="storeName" width="200">
            </el-table-column>
            <el-table-column label="图片" width="150">
              <template slot-scope="scope">
                <el-image style="width: 100%" :src="scope.row.headImage" :preview-src-list="[scope.row.headImage]">
                </el-image>
              </template>
            </el-table-column>

            <!-- <el-table-column label="营业执照" width="150">
              <template slot-scope="scope">
                <el-image style="width: 100%" :src="scope.row.license" :preview-src-list="[scope.row.license]">
                </el-image>
              </template>
            </el-table-column> -->

            <el-table-column label="地址" prop="address"></el-table-column>
            <el-table-column label="银行账户" prop="bankCard"></el-table-column>
            <el-table-column label="桌位" prop="tableNumber" width="100">
              <template slot-scope="scope">
                <el-link type="success" @click="wToTableList(scope.row)">{{
                  scope.row.tableNumber
                  }}</el-link>
              </template>
            </el-table-column>
            <el-table-column label="负责人" prop="beneficiaryName" width="100"></el-table-column>
            <el-table-column label="状态" prop="openOrCloseName" width="100">
              <template slot-scope="scope">
                <span :class="{ isOpenOrClose: scope.row.openOrClose == 1 }">{{
                  scope.row.openOrCloseName
                  }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开店时间" prop="openDate">
            </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="wDoTableBtn(scope, 'edit')"><span>{{ scope.row.headImage ?
                  "查看信息" : "补录信息" }}</span>
                </el-button>
                <el-button type="text" size="small" @click="wDoTableBtn(scope, 'business')"><span>{{
                  scope.row.openOrClose == 0 ? "开始营业" : "暂停营业"
                    }}</span>
                </el-button>
                <el-button type="text" size="small" @click="wDoTableBtn(scope, 'delete')"><span>删除</span>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="wCategory.page"
            :page-size="wCategory.limit" :total="wCategory.total" :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false" @current-change="wELPageCurrentChange" @size-change="wELPageSizeChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- </wPageCrumbs> -->

    <!-- 新增/修改门店信息 -->
    <el-dialog :title="wStoreDialog.form.id ? '修改门店信息' : '新增门店信息'" :visible.sync="wStoreDialog.isShow"
      :close-on-click-modal="false" append-to-body @close="wStoreDialogClose()">
      <!-- 门店信息表单 -->
      <div class="wDialogForm">
        <vue-scroll :ops="wVueScrollOps">
          <div class="wDialogForm-box">
            <el-form ref="wStaffFrom" :model="wStoreDialog.form" label-width="100px">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="门店名称" prop="storeName">
                    <el-input v-model="wStoreDialog.form.storeName" placeholder="请输入门店名称"></el-input>
                    <!-- <span v-else>{{ wStoreDialog.form.storeName || "未知的门店名称" }}</span> -->
                  </el-form-item>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item
                    label="门店分类"
                    prop="category"
                  >
                    <el-select
                      v-model="wStoreDialog.form.category.value"
                      placeholder="门店分类"
                      style="width: 230px"
                    >
                      <template v-for="item in wStoreDialog.form.category.options">
                        <el-option
                          :key="item.id"
                          :label="item.label"
                          :value="item.id"
                        ></el-option>
                      </template>
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col :span="16">
                  <el-form-item label="打印机编号" prop="sn">
                    <!-- <el-input type="textarea" v-model="wStoreDialog.form.address" placeholder="请输入门店地址"></el-input> -->
                    <el-select v-model="wStoreDialog.form.sn" clearable multiple filterable allow-create default-first-option
                      placeholder="输入打印机编号，用回车添加">
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="门店地址" prop="address">
                    <el-input type="textarea" v-model="wStoreDialog.form.address" placeholder="请输入门店地址"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="门店电话" prop="tel">
                    <el-input v-model="wStoreDialog.form.tel" placeholder="请输入门店电话"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="银行卡号" prop="bankCard">
                    <el-input v-model="wStoreDialog.form.bankCard" placeholder="请输入银行卡号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="门店位置经度" prop="tel">
                    <el-input v-model="wStoreDialog.form.lon" placeholder="请输入地图经度值"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="门店位置纬度" prop="tel">
                    <el-input v-model="wStoreDialog.form.lat" placeholder="请输入地图纬度值"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="16">
                  <el-form-item label="负责人" prop="beneficiary">
                    <el-button @click="selectBeneficiary">{{ wStoreDialog.form.beneficiary ? '已选择' +
                      wStoreDialog.form.beneficiaryName : '选择' }}</el-button>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="开始运营" prop="startTime">
                    <el-time-select placeholder="起始时间" v-model="wStoreDialog.form.startTime"
                      :picker-options="{ start: '08:30', step: '00:15', end: '23:30' }" />
                  </el-form-item>
                  <el-form-item label="结束运营" prop="endTime">
                    <el-time-select placeholder="结束时间" v-model="wStoreDialog.form.endTime"
                      :picker-options="{ start: '08:30', step: '00:15', end: '23:30', minTime: wStoreDialog.form.startTime }" />
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-row type="flex" class="row-bg">
                    <el-form-item label="门店主图">
                      <el-upload :action="wGetUploadImgPath()" list-type="picture-card"
                        :limit="wStoreDialog.form.imgCover.limit" :file-list="wStoreDialog.form.imgCover.list_v"
                        :on-preview="wELUpload_preview" :on-remove="wELUpload_remove_cover"
                        :on-success="wELUpload_success_cover" :on-error="wELUpload_error" :on-exceed="wELUpload_limit"
                        :before-upload="wELUpload_beforeUpload">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">
                          只能上传jpg/png文件，推荐尺寸: 630 * 420
                        </div>
                      </el-upload>
                    </el-form-item>

                    <!-- <el-form-item label="营业执照">
                      <el-upload :action="wGetUploadImgPath()" list-type="picture-card"
                        :limit="wStoreDialog.form.license.limit" 
                        :file-list="wStoreDialog.form.license.list_v"
                        :on-preview="wELUpload_preview" 
                        :on-remove="wELUpload_remove_license"
                        :on-success="wELUpload_success_license" 
                        :on-error="wELUpload_error" 
                        :on-exceed="wELUpload_limit"
                        :before-upload="wELUpload_beforeUpload">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">
                          只能上传jpg/png文件，推荐尺寸: 630 * 420
                        </div>
                      </el-upload>
                    </el-form-item> -->

                  </el-row>
                </el-col>
                <!-- <el-col :span="24">
                  <el-form-item label="门店图片">
                    <el-upload
                      :action="wGetUploadImgPath()"
                      list-type="picture-card"
                      :limit="wStoreDialog.form.imgAlbum.limit"
                      :file-list="wStoreDialog.form.imgAlbum.list_v"
                      :on-preview="wELUpload_preview"
                      :on-remove="wELUpload_remove_album"
                      :on-success="wELUpload_success_album"
                      :on-error="wELUpload_error"
                      :on-exceed="wELUpload_limit"
                      :before-upload="wELUpload_beforeUpload"
                    >
                      <i class="el-icon-plus"></i>
                      <div
                        slot="tip"
                        class="el-upload__tip"
                      >
                        只能上传jpg/png文件，推荐尺寸: 630 * 420
                      </div>
                    </el-upload>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </el-form>
          </div>
        </vue-scroll>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="wStoreDialog.isShow = false">取 消</el-button>
        <el-button type="primary" @click="wDoStoreDialogOperate">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 图片预览 -->
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <!-- 负责人 -->
    <el-dialog title="负责人" :visible.sync="beneficiary.isShow" append-to-body :close-on-click-modal="false">
      <!-- 搜索区 -->
      <div class="wETJRFSerach-area">
        <el-input style="width: 100%; max-width: 400px" type="text" v-model="beneficiary.keyword" maxlength="11"
          placeholder="请输入手机号"></el-input>
        <el-button type="primary" @click="wETJRFSerachBtn">搜索</el-button>
      </div>
      <!-- 表格数据区 -->
      <div class="wETJRFTable-area">
        <el-table :data="beneficiary.list" style="width: 100%">
          <el-table-column label="会员">
            <template slot-scope="scope">
              <div class="wETJRFTable-user">
                <el-avatar :size="30" :src="scope.row.avatar"></el-avatar>
                <div class="wETJRFTable-userName">{{ scope.row.nickName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="wETJRFSerachChoice(scope.row)">
                选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination background layout="total, prev, pager, next, jumper" :current-page="beneficiary.page"
            :page-size="beneficiary.limit" :total="beneficiary.total" :hide-on-single-page="false"
            @current-change="wELPageCurrentChange_edit" @size-change="wELPageSizeChange_edit">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import wPageCrumbs from "@/components/layout/PageCrumbs.vue";
import {
  store_storeUpdate,
  store_storeInfoById,
  store_editStoreInfo,
  store_addStoreInfo
} from "@/network/wApi.js";

import { deleteStoreApi } from "@/network/api";
export default {
  components: {
    wPageCrumbs
  },
  data() {
    return {
      tableLoading: false,
      // 栏目
      wColumn: {
        value: 0,
        options: [
          {
            id: 0,
            title: "全部"
          },
          {
            id: 1,
            title: "旗舰店"
          },
          {
            id: 2,
            title: "中心店"
          },
          {
            id: 3,
            title: "社区店"
          }
        ]
      },

      // 滚动条的配置
      wVueScrollOps: {
        scrollPanel: {
          scrollingX: false
        }
      },

      // 商品分类数据
      wCategory: {
        page: 1,
        limit: 10,
        total: 0,
        list: []
      },

      // 图片预览
      dialogImageUrl: "",
      dialogVisible: false,

      // 门店信息修改弹框
      wStoreDialog: {
        isShow: false, // 是否展示
        // 门店信息
        form: {
          id: 0, // id
          startTime: "",
          endTime: "",
          beneficiary: undefined,
          beneficiaryName: "",
          storeName: "", // 门店名称
          address: "",
          sn: [],//打印机编号
          tel: "",
          bankCard: "",
          lan: '',
          lon: '',
          // 门店分类
          category: {
            value: "", // 选中值
            options: [
              {
                id: 1,
                label: "旗舰店"
              },
              {
                id: 2,
                label: "中心店"
              },
              {
                id: 3,
                label: "社区店"
              }
            ]
          },
          imgCover: {
            limit: 1, // 数量限制
            list: [], // 图片集合，更新以后的值
            list_v: [] // 修改数据默认值和list一样
          }, // 商品主图
          imgAlbum: {
            limit: 20, // 数量限制
            list: [], // 图片集合，更新以后的值
            list_v: [] // 修改数据默认值和list一样
          }, // 商品相册图

          // 营业执照
          // license:{
          //   limit: 1, // 数量限制
          //   list: [], // 图片集合，更新以后的值
          //   list_v: [] // 修改数据默认值和list一样
          // }

        }
      },

      beneficiary: {
        isShow: false,
        keyword: "",
        page: 1, // 分页
        limit: 10, // 条数
        total: 0, // 总数
        list: [] // 数据
      }
    };
  },
  created() {
    // 获取门店列表数据
    this.wGetStoreList();
  },
  methods: {
    wETJRFSerachChoice(data) {
      this.wStoreDialog.form.beneficiary = data.id;
      this.wStoreDialog.form.beneficiaryName = data.nickName;
      this.beneficiary.isShow = false;
    },
    selectBeneficiary() {
      this.wGetMemberBySearch();
    },
    wETJRFSerachBtn() {
      // 更改当前页码数据
      this.beneficiary.page = 1;
      this.$nextTick(() => {
        // 获取搜索列表数据
        this.wGetMemberBySearch();
      });
    },
    wELPageSizeChange_edit(wP_cPageSize) {
      // 更改当前页码数据
      this.beneficiary.page = 1;
      this.beneficiary.limit = wP_cPageSize;
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberBySearch();
      });
    },
    wELPageCurrentChange_edit(wP_cPage) {
      // 更改当前页码数据
      this.beneficiary.page = wP_cPage;
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberBySearch();
      });
    },
    wGetMemberBySearch() {
      // 请求参数
      let wData = {};
      // 查询条件
      let {
        keyword, // 搜索关键字
        page, // 分页
        limit // 条数
      } = this.beneficiary;
      // 判断条件
      if (keyword) {
        wData.phone = keyword;
      }
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;

      // 请求函数
      this.$api.member_list({
        data: wData,
        success: res => {
          // 更新数据
          this.beneficiary.total = res.total;
          this.beneficiary.list = res.records;
          // 打开弹框
          this.$nextTick(() => {
            this.beneficiary.isShow = true;
          });
        }
      });
    },
    // 获取图片上传的地址
    wGetUploadImgPath() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`;
    },
    // 图片预览
    wELUpload_preview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 删除图片
    wELUpload_remove(file, fileList) {
      console.log(file, fileList);
    },
    // 图片上传成功
    wELUpload_success(response, file, fileList) {
      console.log(response);
    },
    // 图片上传失败
    wELUpload_error(err, file, fileList) {
      console.log(err);
      this.$message("图片上传失败");
    },
    // 超出图片上传限制
    wELUpload_limit(files, fileList) {
      this.$message("图片上传已达上限");
    },
    // 图片上传之前做限制
    wELUpload_beforeUpload(file) {
      this.loading = true;
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      // const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isJPG && !isPNG) {
        this.$message.error("上传图片只能是 JPG 或者 PNG 格式!");
      }
      // if (!isLt10M) {
      //   this.$message.error("上传图片大小不能超过 10MB!");
      // }
      // return (isJPG || isPNG) && isLt10M;
      return isJPG || isPNG;
    },
    /**
     * wELUpload_success_cover
     * 商品主图上传成功
     */
    wELUpload_success_cover(response, file, fileList) {
      console.log(response, file, fileList);
      // 数据处理
      let imgArr = this.wStoreDialog.form.imgCover.list;
      imgArr.push({
        name: file.name,
        url: file.response.data
      });
      // 更新数据
      this.wStoreDialog.form.imgCover.list = imgArr;
    },
    /**
     * wELUpload_remove_cover
     * 删除商品主图图片
     */
    wELUpload_remove_cover(file, fileList) {
      console.log(file, fileList);
      // 数据处理
      let imgArr = [];
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].response.data
          });
        } else {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].url
          });
        }
      }
      // 更新数据
      this.wStoreDialog.form.imgCover.list = imgArr;
    },
    /**
 * wELUpload_success_album
 * 营业执照图片上传成功
 */

    //  wELUpload_success_license(response, file, fileList) {
    //   console.log(response, file, fileList);
    //   console.log("123123",fileList);
    //   // 数据处理
    //   let imgArr = this.wStoreDialog.form.license.list;
    //   imgArr.push({
    //     name: file.name,
    //     url: file.response.data
    //   });
    //   // 更新数据
    //   this.wStoreDialog.form.license.list = imgArr;
    // },

    // wELUpload_remove_license(file, fileList){
    //   console.log(file, fileList);
    //   // 数据处理
    //   let imgArr = [];
    //   for (let i = 0; i < fileList.length; i++) {
    //     if (fileList[i].response) {
    //       imgArr.push({
    //         name: fileList[i].name,
    //         url: fileList[i].response.data
    //       });
    //     } else {
    //       imgArr.push({
    //         name: fileList[i].name,
    //         url: fileList[i].url
    //       });
    //     }
    //   }
    //   // 更新数据
    //   this.wStoreDialog.form.license.list = imgArr;
    // },

    /**
     * wELUpload_success_album
     * 商品相册图片上传成功
     */
    wELUpload_success_album(response, file, fileList) {
      console.log(response, file, fileList);
      // 数据处理
      let imgArr = this.wStoreDialog.form.imgAlbum.list;
      imgArr.push({
        name: file.name,
        url: file.response.data
      });
      // 更新数据
      this.wStoreDialog.form.imgAlbum.list = imgArr;
    },

    /**
     * wELUpload_remove_album
     * 删除商品相册图片
     */
    wELUpload_remove_album(file, fileList) {
      console.log(file, fileList);
      // // 数据处理
      let imgArr = [];
      for (let i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].response.data
          });
        } else {
          imgArr.push({
            name: fileList[i].name,
            url: fileList[i].url
          });
        }
      }
      // 更新数据
      this.wStoreDialog.form.imgAlbum.list = imgArr;
    },
    /**
     * wDoChoiceColumn
     * 栏目选择
     */
    wDoChoiceColumn(wP_id) {
      this.wColumn.value = wP_id;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    /**
     * wGetStoreList
     * 获取门店列表数据
     */
    wGetStoreList() {
      this.tableLoading = true
      let { value: wStoreType } = this.wColumn;
      let { page, limit } = this.wCategory;

      // 请求参数
      let wData = {};

      // 门店类型 1-旗舰店 2-中心店 3-社区店
      if (wStoreType != 0) {
        wData.type = wStoreType;
      }
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;
      // wData["pageModel.sortField"] = "id";
      // wData["pageModel.sortWay"] = "asc";

      // 请求函数
      this.$api.store_list({
        data: wData,
        success: res => {
          // 更新数据
          this.tableLoading = false
          this.wCategory.total = res.total;
          this.wCategory.list = res.records;
          console.log("门店数据", res.records);
        }
      });
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发 - 当前页
     */
    wELPageCurrentChange(wP_cPage) {
      this.wCategory.page = wP_cPage;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    /**
     * wELPageSizeChange
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      this.wCategory.page = 1;
      this.wCategory.limit = wP_cPageSize;
      // 获取门店列表数据
      this.wGetStoreList();
    },
    /**
     * wDoTableBtn
     * 表格操作按钮
     */
    wDoTableBtn(wP_scope, wP_type) {
      console.log("123123", wP_scope, wP_type);
      let wIndex = wP_scope.$index;
      switch (wP_type) {
        case `add`:
          // 新增一个门店
          {
            // 清空数据
            this.wStoreDialog.form.startTime = "";
            this.wStoreDialog.form.endTime = "";
            this.wStoreDialog.form.id = "";
            this.wStoreDialog.form.storeName = "";
            this.wStoreDialog.form.sn = [];
            this.wStoreDialog.form.category.value = "";
            // 门店主图
            this.wStoreDialog.form.imgCover.list = [];
            this.wStoreDialog.form.imgCover.list_v = [];
            // 门店图片
            this.wStoreDialog.form.imgAlbum.list = [];
            this.wStoreDialog.form.imgAlbum.list_v = [];

            // // 营业执照
            // this.wStoreDialog.form.license.list = [];
            // this.wStoreDialog.form.license.list_v = [];
            // 打开弹框
            this.wStoreDialog.isShow = true;
          }
          break;

        case `business`:
          // 门店开业与关业的管理
          {
            let { id } = wP_scope.row;
            // 请求函数
            this.$api.store_operateBusiness({
              data: {
                id: id
              },
              success: res => {
                console.log(res);
                this.$message({
                  type: "success",
                  message: "修改成功!"
                });
                this.wCategory.list[wIndex].openOrClose = res.openOrClose;
                this.wCategory.list[wIndex].openOrCloseName =
                  res.openOrCloseName;
              }
            });
          }
          break;

        case `delete`:
          // 门店删除
          {
            let { id } = wP_scope.row;
            this.$confirm("此操作将永久删除该门店, 是否继续?", "删除门店", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                // 请求函数
                deleteStoreApi(id)
                  .then(res => {
                    // 获取门店列表数据
                    this.wGetStoreList();
                    this.$message({
                      type: "success",
                      message: "删除成功!"
                    });
                  })
                  .catch(err => {
                    // this.$message({
                    //   type: "error",
                    //   message: "删除失败!"
                    // });
                  });
              })
              .catch(() => { });
          }
          break;

        case `edit`:
          // 修改信息
          {
            // 获取门店详情
            this.wGetStoreInfoById(wP_scope.row.id)
              .then(res => {
                // 更改数据
                console.log(res);
                this.wStoreDialog.form.startTime = res.startTime;
                this.wStoreDialog.form.endTime = res.endTime;
                this.wStoreDialog.form.id = res.id;
                this.wStoreDialog.form.storeName = res.storeName;
                this.wStoreDialog.form.sn = res.sn ? res.sn.split(',') : [];
                this.wStoreDialog.form.address = res.address;
                this.wStoreDialog.form.tel = res.tel;
                this.wStoreDialog.form.bankCard = res.bankCard;
                this.wStoreDialog.form.beneficiary = wP_scope.row.beneficiary;
                this.wStoreDialog.form.beneficiaryName =
                  wP_scope.row.beneficiaryName;
                // this.wStoreDialog.form.category.value = res.type
                //   ? parseInt(res.type)
                //   : 1;
                // 门店主图
                this.wStoreDialog.form.imgCover.list = [];
                this.wStoreDialog.form.imgCover.list_v = [];

                // // 营业执照
                // this.wStoreDialog.form.license.list = [];
                // this.wStoreDialog.form.license.list_v = [];

                if (res.headImage) {
                  this.wStoreDialog.form.imgCover.list = [
                    {
                      name: "门店主图",
                      url: res.headImage
                    }
                  ];
                  this.wStoreDialog.form.imgCover.list_v = [
                    {
                      name: "门店主图",
                      url: res.headImage
                    }
                  ];
                }
                // if (res.license) {
                //   this.wStoreDialog.form.license.list = [
                //     {
                //       name: "营业执照",
                //       url: res.license
                //     }
                //   ];
                //   this.wStoreDialog.form.license.list_v = [
                //     {
                //       name: "营业执照",
                //       url: res.license
                //     }
                //   ];
                // }

                // 门店图片
                // this.wStoreDialog.form.imgAlbum.list = [];
                // this.wStoreDialog.form.imgAlbum.list_v = [];
                // if (res.images && res.images.length > 0) {
                //   for (let i = 0; i < res.images.length; i++) {
                //     this.wStoreDialog.form.imgAlbum.list.push({
                //       name: "门店图片",
                //       url: res.images[i]
                //     });
                //     this.wStoreDialog.form.imgAlbum.list_v.push({
                //       name: "门店图片",
                //       url: res.images[i]
                //     });
                //   }
                // }
                // 打开弹框
                this.wStoreDialog.isShow = true;
              })
              .catch(err => { });
          }
          break;

        default:
          this.$message("功能待开发~");
          break;
      }
    },

    /**
     * wDoUpdateStore
     * 更新门店数据
     */
    wDoUpdateStore() {
      // 请求函数
      store_storeUpdate().then(res => {
        this.$message({
          message: "门店数据更新成功~",
          type: "success"
        });
        // 获取门店列表数据
        this.wGetStoreList();
      });
    },

    /**
     * wToTableList
     * 跳转至门店桌子列表数据
     */
    wToTableList(e) {
      // 跳转至门店桌子列表页面
      this.$router.push({
        name: "storeTable",
        query: { sid: e.id, name: e.storeName }
      });
    },

    /**
     * wGetStoreInfoById
     * 通过门店id获取商品详情
     */
    wGetStoreInfoById(wP_id) {
      return new Promise((resolve, reject) => {
        // 请求函数
        store_storeInfoById({
          id: wP_id
        })
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    /**
     * wDoStoreDialogOperate
     * 修改门店信息
     */
    wDoStoreDialogOperate() {
      // 请求参数
      let wData = {};

      // 表单数据
      let {
        id: wS_id,
        category: { value: wS_type },
        imgCover: { list: wS_imgCoverArr },
        imgAlbum: { list: wS_imgAlbumArr },
        // license:  { list: wS_licenseArr}
      } = this.wStoreDialog.form;

      // 数据处理
      // 门店id主键
      wData.id = wS_id || "";
      // 门店类型 1-旗舰店 2-中心店 3-社区店
      // wData.type = wS_type;
      wData.type = 1;

      // 经度纬度
      wData.lon = this.wStoreDialog.form.lon;
      wData.lat = this.wStoreDialog.form.lat;
      // 门店主要信息
      wData.storeName = this.wStoreDialog.form.storeName;
      wData.address = this.wStoreDialog.form.address;
      wData.sn = this.wStoreDialog.form.sn.join(',');
      wData.bankCard = this.wStoreDialog.form.bankCard;
      wData.tel = this.wStoreDialog.form.tel;
      wData.startTime = this.wStoreDialog.form.startTime;
      wData.endTime = this.wStoreDialog.form.endTime;
      wData.beneficiary = this.wStoreDialog.form.beneficiary;
      // 地址
      if (!wData.address) {
        this.$message({
          message: "请填写门店地址",
          type: "error"
        });
        return;
      }

      // 银行卡号
      if (!wData.bankCard) {
        this.$message({
          message: "请填写银行卡号",
          type: "error"
        });
        return;
      }

      // 门店主图
      if (wS_imgCoverArr.length == 0) {
        this.$message({
          message: "请选择门店主图",
          type: "error"
        });
        return;
      }
      // // 营业执照
      // if (wS_licenseArr.length == 0) {
      //   this.$message({
      //     message: "请选择营业执照",
      //     type: "error"
      //   });
      //   return;
      // }
      wData.headImage = wS_imgCoverArr[0].url;

      // wData.license = wS_licenseArr[0].url;

      // 门店图片
      // if (wS_imgAlbumArr.length == 0) {
      //   this.$message({
      //     message: "请选择门店图片",
      //     type: "error",
      //   });
      //   return;
      // }
      wData.images = [];
      wS_imgAlbumArr.forEach((value, index) => {
        wData.images.push(value.url);
      });

      // 请求函数
      wData.id
      console.log("新增门店表单", wData)
        ? store_editStoreInfo(wData).then(res => {
          console.log(res);
          // 操作成功
          this.$message({
            message: "修改成功",
            type: "success"
          });
          // 关闭弹框
          this.wStoreDialog.isShow = false;
          // 获取门店列表数据
          this.wGetStoreList();
        })
          .catch(err => {
            console.log(err);
          })
        : store_addStoreInfo(wData).then(res => {
          console.log(res);
          // 操作成功
          this.$message({
            message: "新增成功",
            type: "success"
          });
          // 关闭弹框
          this.wStoreDialog.isShow = false;
          // 获取门店列表数据
          this.wGetStoreList();
        })
          .catch(err => {
            console.log(err);
          });
    },
    /**
     * wStoreDialogClose
     * 修改门店信息弹框-Dialog 关闭的回调
     */
    wStoreDialogClose() {
      // 门店信息修改弹框
      // wStoreDialog: {
      //   isShow: false, // 是否展示
      //   // 门店信息
      //   form: {
      //     id: 0, // id
      //     storeName: "", // 门店名称
      //     // 门店分类
      //     category: {
      //       value: "", // 选中值
      //       options: [
      //         {
      //           id: 1,
      //           label: "旗舰店",
      //         },
      //         {
      //           id: 2,
      //           label: "中心店",
      //         },
      //         {
      //           id: 3,
      //           label: "社区店",
      //         },
      //       ],
      //     },
      //     imgCover: {
      //       limit: 1, // 数量限制
      //       list: [], // 图片集合，更新以后的值
      //       list_v: [], // 修改数据默认值和list一样
      //     }, // 商品主图
      //     imgAlbum: {
      //       limit: 20, // 数量限制
      //       list: [], // 图片集合，更新以后的值
      //       list_v: [], // 修改数据默认值和list一样
      //     }, // 商品相册图
      //   },
      // },

      // 重置数据
      this.wStoreDialog.form.id = 0;
      this.wStoreDialog.form.storeName = "";
      this.wStoreDialog.form.address = "";
      this.wStoreDialog.form.sn = "";
      this.wStoreDialog.form.bankCard = "";
      this.wStoreDialog.form.tel = "";
      this.wStoreDialog.form.category.value = "";
      this.wStoreDialog.form.imgCover.list = [];
      this.wStoreDialog.form.imgCover.list_v = [];
      this.wStoreDialog.form.imgAlbum.list = [];
      this.wStoreDialog.form.imgAlbum.list_v = [];

      this.beneficiary.keyword = "";
      this.beneficiary.page = 1;
      this.beneficiary.limit = 10;
      this.beneficiary.total = 0;
    }
  }
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 商品分类区域
.wGC-categoryArea {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;

  .wGC-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    // 栏目
    .wGCH-column {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      user-select: none;

      .wGCHC-item {
        flex: none;
        height: 40px;
        line-height: 40px;
        padding: 0 20px;
        margin-right: 10px;
        box-sizing: border-box;
        background-color: #f3f3f3;
        // border: 1px solid #999999;
        font-size: @wFontSize;
        cursor: pointer;
        transition: all 0.3s;

        &:hover,
        &.wActive {
          background-color: @wColor_theme;
          border-color: @wColor_theme;
          color: #ffffff;
        }
      }
    }
  }

  // 商品分类数据
  .wGC-categoryList {
    display: block;
    width: 100%;

    // table表
    .wGLG-table {
      display: block;
      width: 100%;

      .isOpenOrClose {
        color: @wColor_theme;
      }
    }

    // 分页
    .wGLG-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}

// 职务信息表单
.wDialogForm {
  display: block;
  width: 100%;
  height: 450px;

  .wDialogForm-box {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}

.wETJRFTable-user {
  display: flex;
  align-items: center;

  .wETJRFTable-userName {
    margin-left: 10px;
  }
}
</style>