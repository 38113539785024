<template>
    <div class="container">
        <!-- 数据总览 -->
        <div class="overview">
            <div class="overview-listA" @click="clickDetail('all')" style="background-color: #ff6600;">
                <div class="list-title" style="color:#fff">累计收入</div>
                <div class="list-info">
                    <span class="info-amount" style="color:#fff">￥ {{detail.sumAmount}}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/总收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listB" @click="clickDetail('today')">
                <div class="list-title">今日收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{detail.sumCurrentToday}}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/日收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listC" @click="clickDetail('month')">
                <div class="list-title">本月收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{detail.sumCurrentMonth}}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/月收入.png')" fit="fit"></el-image>
                </div>
            </div>
            <div class="overview-listD" @click="clickDetail('year')">
                <div class="list-title">本年收入</div>
                <div class="list-info">
                    <span class="info-amount">￥ {{detail.sumCurrentYear}}</span>
                    <el-image style="width: 40px;" :src="require('@/assets/image/年收入.png')" fit="fit"></el-image>
                </div>
            </div>
        </div>
        <!-- 门店数据总览 -->
        <div class="overview-store">
            <div class="store-list" style="">
                <div class="top-row">
                    <div class="row-title">订单总览</div>
                    <!-- <el-radio-group v-model="radio" size="medium">
                        <el-radio-button v-for="(item,index) in radioGroup" :key="index" :label="item" border></el-radio-button>
                    </el-radio-group> -->
                    
                    <!-- <el-date-picker v-model="datePicker" @change="changeDatePicker" size="small" type="daterange" align="right" unlink-panels
                      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="datePickerOptions">
                    </el-date-picker> -->
                </div>
                <el-table  :data="table" v-loading="loading">
                    <el-table-column align="center" prop="id" label="订单编号" width="180"> </el-table-column>
                    
                    <el-table-column align="center" prop="userSnapshot.nickName" label="用户昵称" > </el-table-column>
                    <el-table-column align="center" prop="userSnapshot.phone" label="用户手机" width="180"> </el-table-column>
                    <el-table-column align="center" prop="totalMoney" label="订单金额" width="180"> </el-table-column>
                    <el-table-column align="center" prop="state" label="订单状态" width="180">
                        <template slot-scope="scope">
                            <el-tag type="success" v-if="scope.row.state == 11 ">已完成</el-tag>
                            <el-tag type="success" v-else-if="scope.row.state == 14 ">待发货</el-tag>
                            <el-tag v-else>未完成</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="payTime" label="支付时间" width="180"> </el-table-column>
                    <el-table-column align="center" prop="createTime" label="创建时间" width="180"> </el-table-column>
                    
                    <!-- <el-table-column align="center" prop="" label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="selectStore(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <!-- 分页 -->
                <div class="wPagination">
                    <el-pagination 
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pages.current"
                        :page-sizes="pages.sizes"
                        :page-size="pages.size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pages.total">
                    </el-pagination>
                </div>
            </div>

            <!-- <div class="store-list" style="height: 605px;">
                <div class="top-row">
                    <div class="row-title">门店详情</div>
                    <div>当前门店 ：<el-select v-model="storeValue" filterable clearable size="small" placeholder="请选或搜索择门店">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select></div>
                </div>
                <div>
                    <el-table  :data="tableDetail" v-loading="loading" style="height: 500px;">
                        <el-table-column align="center" prop="storeId" label="订单编号" width="100"> </el-table-column>
                        <el-table-column align="center" prop="storeName" label="商品名称" > </el-table-column>
                        <el-table-column align="center" prop="orderNum" label="商品数量" width="100"> </el-table-column>
                        <el-table-column align="center" prop="amount" label="订单金额" width="100"> </el-table-column>
                        <el-table-column align="center" prop="" label="操作" width="100">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="selectStore(scope.row)">选择</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>
import { orderListByStore, getStoreAmountApi, getStoreListApi} from "@/network/wApi.js";
export default {
    data() {
        return {
            // radio: "今日",
            // radioGroup: ['今日','本月','本年'],
            datePicker:"",
            datePickerOptions: {
              shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近一个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                  picker.$emit('pick', [start, end]);
                }
              }, {
                text: '最近三个月',
                onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                }
              }]
            },

            arrayData:[],
            table: [],
            tableDetail: [],
            loading: false,
            pages:{
                current: 1,
                sizes: [10,20],
                size: 10,
                total: 0,
            },
            storeValue: null,
            options:[],
            // 查询data
            detail:{
                // header data
                sumAmount: 0,       //所有门店累计收入
                sumCurrentToday: 0, //所有门店今日收入
                sumCurrentMonth: 0, //所有门店本月收入
                sumCurrentYear: 0,  //所有门店本年收入                
            }
        }
    },
    mounted() {
        this.getStoreOverview()
    },
    computed: {

    },
    methods: {
        // 筛选
        getOrderList(e){
            let data = {
                "start"    : e.start,
                "end"      : e.end,
                "pageModel.pageNo"   : this.pages.current,
                "pageModel.pageSize" : this.pages.size,
                "pageModel.sortField": "createTime",
                "pageModel.sortWay"  : "desc",
                "storeId":"52",
                "atype":"3",
                // "state": "11"
            }
            console.log("data",data)
            orderListByStore(data).then(res => {
                this.pages.total = res.total
                this.table = res.records
            })
        },
        // 查看明细
        clickDetail(e){
            let date = new Date()
            if (e == 'all') {
                // this.datePicker = []
                const obj = {
                    start: "",
                    end: ""
                }
                this.getOrderList(obj)
            }
            if (e == 'today') {
                // this.datePicker = [new Date(), new Date()]
                const obj = {
                    start: new Date(),
                    end: new Date()
                }
                this.getOrderList(obj)
            }
            if (e == 'month') {
                // this.datePicker = [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]
                const obj = {
                    start: new Date(date.getFullYear(), date.getMonth(), 1),
                    end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
                }
                this.getOrderList(obj)
            }
            if (e == 'year') {
                // this.datePicker = [new Date(date.getFullYear(), 0), new Date(date.getFullYear(), 11 , 31) ]
                const obj = {
                    start: new Date(date.getFullYear(), 0),
                    end: new Date(date.getFullYear(), 11 , 31)
                }
                this.getOrderList(obj)
            }
        },
        // 门店时间选择器
        changeDatePicker(e){
            const start = e[0]
            const end = e[1]
            const startMM = start.getMonth() < 9 ? "0" + (start.getMonth() + 1) : start.getMonth() + 1;
            const startDD = start.getDate() <= 9 ? "0" + start.getDate() : start.getDate();
            const endMM   = end.getMonth() < 9 ? "0" + (end.getMonth() + 1) : end.getMonth() + 1;
            const endDD   = end.getDate() <= 9 ? "0" + end.getDate() : end.getDate();

            const startDate = start.getFullYear() + '-' + startMM + '-' + startDD
            const endDate   = end.getFullYear() + '-' + endMM + '-' + endDD
            console.log(startDate + ' ' + endDate)
        },
        // 选择门店详情
        selectStore(e){
            console.log("store",e)
            this.storeValue = e.storeId
        },
        // 获取门店数据统计
        getStoreOverview(){
            // this.loading = true
            // 查询供应链年月日累计收入
            getStoreAmountApi({atype:"3"}).then(res => {
                console.log(res)
                this.detail.sumAmount = res.sum
                this.detail.sumCurrentToday = res.today
                this.detail.sumCurrentMonth = res.month
                this.detail.sumCurrentYear = res.year
            })
            
            // 查询供应链订单
            let data = {
                "pageModel.pageNo"   : this.pages.current,
                "pageModel.pageSize" : this.pages.size,
                "pageModel.sortField": "createTime",
                "pageModel.sortWay"  : "desc",
                "storeId":"52",
                "atype":"3",
                // "state":"14"
            }
            console.log(data)
            orderListByStore(data).then(res => {
                this.pages.total = res.total
                this.table = res.records
            })
        },

        // 当前页面显示多少条数据
        handleSizeChange(e){
            this.pages.size = e
            this.pages.current = 1
            this.getStoreOverview()
        },
        // 跳转第n页
        handleCurrentChange(e){
            this.pages.current = e
            this.getStoreOverview()
        }
    }
}
</script>
<style lang='less' scoped>
@import "@/assets/css/publicStyle.less";

.container {
    .overview {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .overview-listA:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listB:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listC:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listD:hover{
            background-color: rgb(233, 233, 233);
        }
        .overview-listA,
        .overview-listB,
        .overview-listC,
        .overview-listD {
            background-color: #fff;
            width: calc((100%)/4 - 60px);
            border-radius: 10px;
            padding: 20px;
            .list-title {
                font-size: 14px;
            }
            .list-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 50px;
                .info-amount {
                    font-size: 25px;
                    font-weight: bold;
                    color: #ff6600;
                }
            }
        }
        
    }

    .overview-store {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .store-list {
            background-color: #fff;
            border-radius: 20px;
            width: calc(100% - 40px);
            padding: 20px;
            .top-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
        
                .row-title {
                    font-size: 20px;
                }
            }
        }
    }
}
</style>