import axios from "axios";
import qs from 'qs';
import router from '@/router/index';
import { Message } from 'element-ui';
export default (wP_obj) => {
  wP_obj || (wP_obj = {});
  // 默认配置
  const config = {
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL: (wP_obj.baseURL ? wP_obj.baseURL : process.env.VUE_APP_URE),
    // `url` 是用于请求的服务器 URL
    url: wP_obj.url ? wP_obj.url : '',
    // `method` 是创建请求时使用的方法
    method: wP_obj.method ? wP_obj.method : 'POST',
    // `headers` 是即将被发送的自定义请求头
    headers: wP_obj.headers ? wP_obj.headers : {
      'Content-Type': 'application/json', // 默认请求头-内容类型
    },
    // `params` 是即将与请求一起发送的 URL 参数
    // 必须是一个无格式对象(plain object-纯粹的对象（通过 "{}" 或者 "new Object" 创建的）)或 URLSearchParams 对象
    params: wP_obj.params ? wP_obj.params : {},
    // `data` 是作为请求主体被发送的数据
    // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
    // 在没有设置 `transformRequest` 时，必须是以下类型之一：
    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
    // - 浏览器专属：FormData, File, Blob
    // - Node 专属： Stream
    data: wP_obj.data ? wP_obj.data : {},
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType: wP_obj.responseType ? wP_obj.responseType : "json",
    // `responseEncoding` indicates encoding to use for decoding responses
    // Note: Ignored for `responseType` of 'stream' or client-side requests
    responseEncoding: wP_obj.responseEncoding ? wP_obj.responseEncoding : 'utf8', // default
    // `timeout` 指定请求超时的毫秒数(0 表示无超时时间)
    // 如果请求花费了超过 `timeout` 的时间，请求将被中断
    timeout: wP_obj.timeout ? wP_obj.timeout : 30000,
    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    transformRequest: wP_obj.transformRequest ? wP_obj.transformRequest : [(data, headers) => {
      // 对 data 进行任意转换处理
      // 添加token
      localStorage.getItem('USER_TOKEN') && headers && (headers.token = localStorage.getItem('USER_TOKEN'));
      // 根据 请求头-内容类型 对数据做处理
      if (headers['Content-Type'] && headers['Content-Type'].indexOf('application/x-www-form-urlencoded') != -1) {
        data = qs.stringify(data);
      }
      return data;
    }],
    // `transformResponse` 在传递给 then/catch 前，允许修改响应数据
    transformResponse: wP_obj.transformResponse ? wP_obj.transformResponse : [(data) => {
      // 对 data 进行任意转换处理
      try {
        return JSON.parse(data);
      } catch(e) {
        return data;
      }
    }],
    // `validateStatus` 定义对于给定的HTTP 响应状态码是 resolve 或 reject  promise 。如果 `validateStatus` 返回 `true` (或者设置为 `null` 或 `undefined`)，promise 将被 resolve; 否则，promise 将被 reject
    validateStatus: wP_obj.validateStatus ? wP_obj.validateStatus : (status) => {
      return true;
    },
    // `onUploadProgress` 允许为上传处理进度事件
    onUploadProgress: wP_obj.onUploadProgress ? wP_obj.onUploadProgress : (progressEvent) => {
      // Do whatever you want with the native progress event
    },
    // `onDownloadProgress` 允许为下载处理进度事件
    onDownloadProgress: wP_obj.onDownloadProgress ? wP_obj.onDownloadProgress : (progressEvent) => {
      // 对原生进度事件的处理
    },
    // 其他配置
    wDebug: wP_obj.wDebug ? wP_obj.wDebug : true, // 是否开启打印调试
    wAlert_success: wP_obj.wAlert_success ? wP_obj.wAlert_success : false, // 成功提示弹框
    wAlert_error: wP_obj.wAlert_error ? wP_obj.wAlert_error : true, // 错误提示弹框
    wAlert_msg: wP_obj.wAlert_msg ? wP_obj.wAlert_msg : null, // 提示语
  }
  return new Promise((resolve, reject) => {
    // 创建实例
    const instance = axios.create();
    // 拦截器方法：与上面的transformRequest、transformResponse冲突
    // Add a request interceptor ——添加请求拦截器
    instance.interceptors.request.use(function (config) {
      // Do something before request is sent
      // 请求参数
      if (config.wDebug) {
        console.log('接口:', config.url, '请求参数data:', config.data, '请求参数params:', config.params);
      }
      // 添加token
      // localStorage.getItem('USER_TOKEN') && config.headers && (config.headers.token = localStorage.getItem('USER_TOKEN'));
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
    // Add a response interceptor ——添加一个响应拦截器
    instance.interceptors.response.use(function (response) {
      // Do something with response data
      return response;
    }, function (error) {
      // Do something with response error
      const { config, code, message } = error;
      if (code === 'ECONNABORTED' || message === 'Network Error') { // 请求超时
        // // 默认alert弹框, 可以用其他弹框替换
        // alert('请求超时');
        // Message 弹框替换
        Message.error('请求超时');
      }
      return Promise.reject(error);
    });
    // 实例方法-请求配置
    instance.request({
      ...config
    }).then((res) => {
      const { status: wStatus, data: wData, } = res;
      // `status` 来自服务器响应的 HTTP 状态码
      if (wStatus == 200) {
        // 成功
        // 以下是个人服务器返回的值
        let { code: wCode, msg: wMsg } = wData;
        // 请求结果
        if (config.wDebug) {
          console.log('接口:', config.url, '请求结果:', wData);
        }
        switch (wCode) {
          case 200:
            // 成功
            {
              if (config.wAlert_success) {
                // // 默认alert弹框, 可以用其他弹框替换
                // alert(config.wAlert_msg ? config.wAlert_msg : wMsg);
                // Message 弹框替换
                Message.success(config.wAlert_msg ? config.wAlert_msg : wMsg);
              }
              resolve(wData);
            }
            break;
          case 401:
            // 请重新登录
            {
              if (config.wAlert_error) {
                // // 默认alert弹框, 可以用其他弹框替换
                // alert(config.wAlert_msg ? config.wAlert_msg : wMsg);
                // Message 弹框替换
                Message.error(config.wAlert_msg ? config.wAlert_msg : wMsg);
              }
              let setT = setTimeout(() => {
                // 跳转至登录页面
                router.push({ name: 'login' });
                clearTimeout(setT);
                reject(res);
              }, 800);
            }
            break;

          default:
            // 默认
            if (config.wAlert_error) {
              // // 默认alert弹框, 可以用其他弹框替换
              // alert(config.wAlert_msg ? config.wAlert_msg : wMsg);
              // Message 弹框替换
              Message.error(config.wAlert_msg ? config.wAlert_msg : wMsg);
            }
            reject(res);
            break;
        }
        resolve(res);
      } else {
        reject(res);
        if (wStatus === 500) {
          // 服务器内部错误!
          // 跳转至500页面
          router.push({ name: 'error' });
        } else if (wStatus === 404) {
          // 请求地址不存在!
          // 跳转至404页面
          router.push({ name: 'notfound' });
        } else {
          // 失败
          if (config.wAlert_error) {
            // // 默认alert弹框, 可以用其他弹框替换
            // alert(wData.error);
            // Message 弹框替换
            Message.error(wData.error);
          }
        }
      }
    }).catch((err) => {
      // 请求结果
      if (config.wDebug) {
        console.log('接口:', config.url, '请求结果:', err);
      }
      reject(err);
    });
  });
}