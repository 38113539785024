<template>
    <div>
        <!-- 筛选查询 -->
        <div class="wScreenArea">
            <el-card class="box-card">
                <div slot="header" class="wScreenCardHeader">
                    <span>筛选查询</span>
                    <div class="wScreenCardHeader-right">
                        <!-- <div class="wSCHR-title"> 总计<span style="color: #ff6321">{{ wTable.wOrderRealMoneyTotal }}</span>元  </div>
                          <el-button type="primary" size="mini" @click="wFun_doExportData" > 导出数据 </el-button> -->
                    </div>
                </div>
                <!-- 筛选列表 -->
                <el-form ref="wScreenForm" label-position="left" label-width="90px" :model="wScreen" @submit.native.prevent>
                    <el-row :gutter="20">
                        <!-- 订单状态 -->
                        <el-col :span="6">
                            <el-form-item label="订单状态" prop="wOrderState">
                                <!-- <el-select
                    v-model="wScreen.wOrderState"
                    placeholder="全部状态"
                    :multiple="true"
                    @change="wchange_wOrderState"
                  >
                    <el-option
                      v-for="item in wC_wOrderState"
                      :key="item.wValue"
                      :label="item.wLabel"
                      :value="item.wValue"
                    >
                    </el-option>
                  </el-select> -->
                                <el-select v-model="wScreen.wOrderState" placeholder="全部状态" @change="wchange_wOrderState">
                                    <el-option v-for="item in orderState" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <!-- 查询时间 -->
                        <el-col :span="6">
                            <el-form-item label="查询时间" prop="wTimeSlot">
                                <el-date-picker style="width: 100%" v-model="wScreen.wTimeSlot" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    @change="wchange_wTimeSlot">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <!-- 订单编号 -->
                        <el-col :span="6">
                            <el-form-item label="订单编号" prop="wOrderNumber">
                                <el-input style="width: 100%; max-width: 200px" type="text" v-model="wScreen.wOrderNumber"
                                    placeholder="请输入订单编号" @keyup.enter.native="wDoEnter_wOrderNumber"></el-input>
                            </el-form-item>
                        </el-col>
                        <!-- 用户 -->
                        <el-col :span="6">
                            <el-form-item label="手机号" prop="wUserKey">
                                <el-input style="width: 100%; max-width: 200px" type="text" v-model="wScreen.wUserKey"
                                    placeholder="请输入手机号" @keyup.enter.native="wDoEnter_wUserKey"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <!-- 操作按钮 -->
                        <el-col :span="24">
                            <el-button size="medium" @click="wFun_formScreenReset">
                                重置
                            </el-button>
                            <el-button size="medium" @click="wFun_formScreenSubmit">
                                查询
                            </el-button>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
        </div>
        <!-- 表格数据 -->
        <div class="wTableArea">
            <!-- 订单列表 -->
            <template>
                <!-- 表格 -->
                <el-table border :data="wTable.wList" v-loading="tableLoading">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="order-box">
                                <el-table border :data="props.row.goodsList">
                                    <el-table-column label="商品图片" align="center">
                                        <template slot-scope="scope">
                                            <el-image style="width: 50px; height: 50px" :src="scope.row.headImg" fit="cover"
                                                :preview-src-list="[scope.row.headImg]"></el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="goodsName" label="商品名称" align="center">
                                    </el-table-column>
                                    <el-table-column prop="price" label="单价（元）" align="center">
                                    </el-table-column>
                                    <el-table-column prop="num" label="数量" align="center">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="订单编号" align="center">
                    </el-table-column>
                    <el-table-column prop="orderName" label="订单名称" align="center">
                    </el-table-column>
                    <el-table-column prop="atype" label="订单类型" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.atype == 3">供应链</span>
                            <span v-if="scope.row.atype == 1">普通菜品</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="下单时间" align="center">
                    </el-table-column>
                    <el-table-column label="用户" align="center">
                        <template slot-scope="scope">
                            <div style="text-align: left">
                                <div style="display: flex; align-items: center">
                                    <span>头像：</span>
                                    <el-avatar size="small" :src="scope.row.userSnapshot.avatar"></el-avatar>
                                </div>
                                <div>昵称：{{ scope.row.userSnapshot.nickName }}</div>
                                <div>手机：{{ scope.row.userSnapshot.phone }}</div>
                                <div v-if="scope.row.userSnapshot.realName">
                                    真实姓名：{{ scope.row.userSnapshot.realName }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="realMoney" label="订单金额(元)" align="center">
                    </el-table-column>
                    <el-table-column prop="orderState" label="订单状态" align="center">
                        <template slot-scope="scope">
                            <div>
                                <el-tag v-if="scope.row.state == 9" type="info"> 未支付</el-tag>
                                <el-tag v-if="scope.row.state == 10" type="info">支付中</el-tag>
                                <el-tag v-if="scope.row.state == 11 && !scope.row.additionData" type="danger">已完成(旧版本)</el-tag>
                                <el-tag v-if="scope.row.state == 11 && scope.row.additionData == 2" type="danger">待发货</el-tag>
                                <el-tag v-if="scope.row.state == 11 && scope.row.additionData == 1" type="danger">自提</el-tag>
                                <el-tag v-if="scope.row.state == 13" type="danger">用户已删除</el-tag>
                                <el-tag v-if="scope.row.state == 14" type="success">已发货</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="支付方式" align="center">
                        <!-- 支付方式 1.线下支付 2.余额支付 3.微信支付 -->
                        <template slot-scope="scope">
                            <div>
                                <div v-if="scope.row.payWay == 201">线下支付</div>
                                <!-- <div v-if="scope.row.payWay == 2">余额支付</div> -->
                                <div v-if="scope.row.payWay == 102">微信支付</div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" align="center" v-if="cwshow">
                        <template slot-scope="scope">
                            <div>
                                <el-button type="primary" size="small" v-if="scope.row.state == 11 && scope.row.additionData == 2" @click="goDeliverGoods(scope.row)">发货</el-button>
                                <el-button type="success" size="small"  v-if="scope.row.state == 14 && scope.row.additionData == 2" @click="goUpdateGoods(scope.row)">修改单号</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <!-- 分页 -->
            <div class="wPagination">
                <el-pagination background :layout="wC_layout" :current-page="wTable.wPage" :page-size="wTable.wLimit"
                    :total="wTable.wTotal" :page-sizes="wC_pageSizes" :hide-on-single-page="false"
                    @current-change="wELPageCurrentChange" @size-change="wELPageSizeChange">
                </el-pagination>
            </div>
        </div>
        <!-- 发货 -->
        <el-dialog :title="title" :visible.sync="deliverGoodsView" width="800px" append-to-body :close-on-click-modal="false" v-loading.fullscreen.lock="fullscreenLoading">
            <el-form ref="form" :model="form" label-width="100px">
                <el-form-item label="订单编号">
                    <div>{{form.id}}</div>
                    <el-table :data="tableData" style="width: 100%">
                        <el-table-column label="图片">
                            <template slot-scope="scope">
                                <el-image style="width: 50px; height: 50px" :src="scope.row.headImg" fit="cover"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
                        <el-table-column prop="num" label="数量" width="100"> </el-table-column>
                        <!-- <el-table-column prop="price" label="单价" width="100"> </el-table-column> -->
                    </el-table>
                </el-form-item>

                <el-form-item label="发货信息">
                    <div>姓名：{{infoName}}</div>
                    <div>联系电话：{{infoPhone}}</div>
                    <div>收货地址：{{infoAddress}}</div>
                </el-form-item>
                <el-form-item label="物流单号">
                    <el-input v-model="form.logisticsId" placeholder="请输入快递单号"></el-input>
                </el-form-item>

                <!-- <el-form-item label="发货状态" v-if="title == '修改发货'">
                    <el-select v-model="form.orderState" placeholder="请选择">
                        <el-option v-for="item in optionOrderState" :label="item.label" :value="item.value" :key="item.value"></el-option>
                    </el-select>
                </el-form-item> -->
            </el-form>
            
            <span class="operation">
                <el-button type="primary" @click="clickDeliverGoods">确认提交</el-button>
            </span>
        </el-dialog>
        <!-- 小票预览 -->
        <el-dialog title="小票预览" :visible.sync="printView" width="800px" append-to-body :close-on-click-modal="false" v-loading.fullscreen.lock="fullscreenLoading">
            <span class="operation">
                <el-button @click="printView = false">返回</el-button>
                <el-button type="primary" v-print="printOption">确定打印</el-button>
            </span>
            <div class="print" id="printID">

                <div class="title">{{ printData.title }}</div>
                <div class="top-info">

                    <div class="info-row">
                        <div class="row-name" style="width: 100%;">单号：{{ printData.id }}</div>
                    </div>

                    <div class="info-row">
                        <div class="row-name">属性：{{ printData.identity }}</div>
                    </div>

                    <div class="info-row">
                        <div class="row-name">服务员：{{ waiter }}</div>
                        <div class="row-name">收银员：{{ cashier }}</div>
                    </div>

                    <div class="info-row">
                        <div class="row-name">桌位：{{ printData.tableId }}</div>
                        <div class="row-name">人数：{{ printData.dinersNum }}</div>
                    </div>


                    <div class="info-row">
                        <div class="row-name" style="width: 100%;">时间：{{ printData.createTime }}</div>
                    </div>
                </div>
                <div class="goods">
                    <div class="goods-row">
                        <div class="row-title" style="width: 220px;">菜品</div>
                        <div class="row-title" style="width: 80px;">单价</div>
                        <div class="row-title" style="width: 80px;">数量</div>
                        <div class="row-title" style="width: 80px;">总额</div>
                    </div>
                    <div class="goods-list" v-for="(item, index) in printData.goodsList" :key="index">
                        <div class="row-value" style="width: 220px ;">{{ item.goodsName }}</div>
                        <div class="row-value" style="width: 80px;">{{ item.price }}</div>
                        <div class="row-value" style="width: 80px;">{{ item.num }}</div>
                        <div class="row-value" style="width: 80px;">{{ item.price * item.num }}</div>
                    </div>
                </div>
                <div class="bottom-info">
                    <div class="info-title" v-if="printData.identity == `渠道代理` || printData.identity == `VIP`">
                        优惠信息：百威酒水第2瓶半价，第3瓶免费
                    </div>
                    <div class="info-title" v-if="printData.identity == `会员`">优惠信息：百威酒水第2瓶半价</div>
                    <div class="info-title">共计：{{ printData.totalMoney }}</div>
                    <div class="info-title">优惠：{{ printData.amountDiscount }}</div>
                    <div class="info-title">实付：{{ printData.realMoney }}</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
  
<script>
// 引入文件
import Stomp from "stompjs"
import {
    wApi_order_queryOrderScreenType,
    wApi_order_exportOrderList
} from "@/plugins/wApi.js";

import { orderListByStore, confirmCashPayApi,goDeliverGoodsApi,getAddressApi,updateLogisticsApi,queryLogisticsApi } from "@/network/wApi.js";
// import getLodop from "@/utils/LodopFuncs";

import eventBus from "@/utils/event-bus";
export default {
    name: "OrderList",
    data() {
        return {
            tableLoading: false,
            title:"",
            infoName   :"",
            infoPhone  :"",
            infoAddress:"",
            tableData:[],
            optionOrderState:[{
                value: 11,
                label: "待发货"
            },{
                value: 14,
                label: "已发货"
            }],
            mqtt_service: "ws://192.168.1.200/ws",
            mqtt_user: "admin",
            mqtt_passd: "123.com",
            fullscreenLoading: false,
            printOption: {
                id: 'printID', // 打印元素的id 不需要携带#号
                type: "html", // 打印类型
                // preview: true, // 开启打印预览
                previewBeforeOpenCallback: () => {
                    console.log("触发打印预览打开前回调");
                },
                previewOpenCallback: () => {
                    console.log("触发打开打印预览回调");
                },
                beforeOpenCallback: () => {
                    console.log("触发打印工具打开前回调");
                },
                openCallback: () => {
                    console.log("触发打开打印工具回调");
                },
                closeCallback: () => {
                    console.log("触发关闭打印工具回调");
                },
                clickMounted: () => {
                    console.log("触发点击打印回调");
                }
            },
            printView: false, //小票弹窗
            deliverGoodsView: false,  //发货弹窗
            form:{
                orderId: null,     //订单ID
                logisticsId: null, //物流单号
                orderState: null,  //订单状态 14 已发货
            },
            printData: {
                id: "",
                createTime: "",
                tableId: "",
                dinersNum: "",
                identity: "",
                totalMoney: "",
                amountDiscount: "",
                realMoney: "",
                goodsList: [
                    {
                        goodsName: "",
                        price: "",
                        num: "",
                    }
                ],
            },
            // 字典数据-订单状态
            wDic_orderState: [],
            // 筛选查询
            wScreen: {
                wOrderState: null, // 订单状态，默认0-全部状态
                wTimeSlot: null, // 查询时间段
                wOrderNumber: null, // 订单编号, 默认null
                wUserKey: null // 用户信息关键字，默认null
            },
            // 表格数据
            wTable: {
                wPage: 1,
                wLimit: 10,
                wTotal: 0,
                wList: [],
                wOrderRealMoneyTotal: "0.00"
            },
            orderState: [
                {
                    id: null,
                    name: "全部"
                },
                {
                    id: 9,
                    name: "未支付"
                },
                {
                    id: 10,
                    name: "支付中"
                },
                {
                    id: 11,
                    name: "待发货"
                },
                {
                    id: 14,
                    name: "已发货"
                }
            ],
            cwshow: true
        };
    },
    mounted() {
        // 权限处理
        this.changePermission()
        // 打印初始化
        // setTimeout(() => {
        //   let LODOP = getLodop()
        //   LODOP.PRINT_INIT("123")
        //   console.log("初始化完成")
        // }, 5000);

        // mqtt连接初始化
        // this.setRabitMQ()

        // Promise.allSettled()方法，用来确定一组异步操作是否都结束了（不管成功或失败）
        // Promise.allSettled([this.wFun_queryOrderTypeData()]).then(results => {
        //   this.$nextTick(() => {
        //     // 查询表格数据
        //     this.wFun_queryTableData(true);
        //   });
        // });
        this.wFun_queryTableData(true);
        eventBus.$on("getOrderData", this.getOrderData);
    },
    computed: {
        waiter() {
            return JSON.parse(localStorage.getItem('LOGININFO')).account
        },
        cashier() {
            return JSON.parse(localStorage.getItem('LOGININFO')).account
        },
        // 获取分页配置数据
        wC_layout() {
            return this.$store.state.wPage.wLayout;
        },
        wC_pageSizes() {
            return this.$store.state.wPage.wPageSizes;
        },
        // 获取筛选条件的订单状态数据
        wC_wOrderState() {
            let [wOrderStateData, { wDic_orderState }] = [
                [
                    // 新添默认值
                    // {
                    //   wLabel: "全部状态", // 标题
                    //   wValue: "0", // 值
                    // },
                ],
                this
            ];
            wDic_orderState.forEach((item, index) => {
                wOrderStateData.push({
                    wLabel: item.orderStateName, // 标题
                    wValue: item.orderState // 值
                });
            });
            return wOrderStateData;
        }
    },
    methods: {
        // 权限判断
    changePermission(){
      const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
      if (userInfo.roleId == 5) {
          console.log("财务账户")
          this.cwshow = false
      }
    },
        // 重置表单
        resetForm(){
            this.form = {
                orderId: null,     //订单ID
                logisticsId: null, //物流单号
                orderState: null,  //订单状态 14 已发货
            }
        },
        // 去发货
        goDeliverGoods(e) {
            this.tableData = e.goodsList
            this.resetForm()
            this.title = "供应链发货"
            this.form.id = e.id
            getAddressApi({addressId:e.addressId}).then(res => {
                console.log("地址",res)
                if (res) {
                    this.infoName    = res.name
                    this.infoPhone   = res.phone
                    this.infoAddress = res.areaCode +','+ res.address
                    this.deliverGoodsView = true
                } else {
                    this.$message.error("此订单用户已删除收货地址!请自行联系收货人。")
                }
                
            })
        },
        // 修改发货
        goUpdateGoods(e){
            this.tableData = e.goodsList
            this.resetForm()
            this.title = "修改发货"
            this.form.id = e.id
            // this.form.orderState = e.state
            queryLogisticsApi({orderId:e.id}).then(res => {
                console.log(res)
                this.form.logisticsId = res.logisticsId

                getAddressApi({addressId:e.addressId}).then(res => {
                    console.log("地址",res)
                    this.infoName    = res.name
                    this.infoPhone   = res.phone
                    this.infoAddress = res.areaCode +','+ res.address
                    this.deliverGoodsView = true
                })
            })
        },
        // 点击确定发货按钮
        clickDeliverGoods(){
            if (this.form.logisticsId.length < 7) {
                this.$message.error("物流单号格式填写错误!")
                return
            }
            if (this.title == "供应链发货") {
                let obj = {
                    orderId: this.form.id,
                    logisticsId: this.form.logisticsId,
                    orderState: 14,
                }
                goDeliverGoodsApi(obj).then( res=>{
                    console.log(res)
                    this.deliverGoodsView = false
                    this.$message.success("发货成功!")
                    this.wFun_formScreenSubmit()
                })
            }
            if (this.title == '修改发货') {
                let obj = {
                    orderId: this.form.id,
                    logisticsId: this.form.logisticsId,
                    // orderState: this.form.orderState,
                }
                console.log(obj)
                updateLogisticsApi(obj).then(res => {
                    console.log(res)
                    this.deliverGoodsView = false
                    this.$message.success("修改成功!");
                    this.wFun_formScreenSubmit()
                })
            }
        },
        // mqtt初始化
        setRabitMQ() {
            this.client = Stomp.client(this.mqtt_service)
            this.connect()
        },
        // 开始连接
        connect() {
            const headers = {
                login: this.mqtt_user,
                passcode: this.mqtt_passd
            }
            this.client.connect(headers, this.onConnected, this.onFailed)
        },
        onConnected(frame) {
            // 订阅频道
            "/exchange/epai.xihan.ui",
                this.responseCallback
        },
        // 回传消息 MQ连接成功后的 数据相应 接收消息处理
        responseCallback(frame) {
            // console.log(frame.body);
            try {
                // 格式化MQ消息
                let data = JSON.parse(JSON.parse(frame.body))
                // 将mq信息放到vuex中
                // this.$store.dispatch("setMQCutterDataList", data);
            } catch (e) {
                console.log('shibai', e)
            }
        },
        // 失败后的处理
        onFailed(frame) {
            console.log("MQ Failed: " + frame)
            // 失败后  等待5秒后重新连接
            setTimeout(() => {
                this.setRabitMQ()
            })
        },

        // 打印点击事件
        clickPrint() {

        },

        getPrintData(e, result) {
            // 处理用餐人数 & 获取总价 & !小票菜单分类排序
            let obj = { dinersNum: 0, identity: "", totalMoney: 0, goodsList: [] }
            let m = null
            const ary = e.goodsList
            console.log("菜品", ary)
            for (let i = 0; i < ary.length; i++) {
                // 计算用餐人数
                if (ary[i].goodsName == '油碟') {
                    obj.dinersNum = ary[i].num
                }
                // 计算订单总价
                m = ary[i].price * ary[i].num
                obj.totalMoney = obj.totalMoney + m

            }
            // 查询用户身份
            this.$api.member_list({
                data: { phone: e.userSnapshot.phone },
                success: res => {
                    obj.identity = res.records[0].identityName
                    result(obj)
                }
            })
        },
        // 打印小票
        printOrder(e) {
            // this.fullscreenLoading = true
            console.log("订单数据", e)
            // 获取用餐人数、用户身份
            this.getPrintData(e, res => {
                // console.log("返回参数",res)
                this.printData.title = e.orderName
                this.printData.id = e.id
                this.printData.createTime = e.createTime
                this.printData.tableId = e.tableName
                this.printData.dinersNum = res.dinersNum
                this.printData.identity = res.identity
                this.printData.totalMoney = res.totalMoney  // 订单总计金额
                this.printData.amountDiscount = res.totalMoney - e.realMoney   // 优惠金额 = 订单总计金额 - 订单实付金额 
                this.printData.realMoney = e.realMoney      // 订单实付金额
                this.printData.goodsList = e.goodsList
                this.printView = true
            })

        },
        getOrderData() {
            this.wFun_queryTableData(true);
        },
        /**
         * wFun_queryOrderTypeData
         * 方法-查询订单类型数据
         */
        wFun_queryOrderTypeData() {
            return new Promise((resolve, reject) => {
                // 请求方法
                wApi_order_queryOrderScreenType()
                    .then(res => {
                        resolve(res);
                        let [resData] = [res.data];
                        // 更新数据
                        // 字典数据-订单类型
                        this.wDic_orderType = resData.orderTypeConfigs;
                        // 字典数据-订单类型
                        this.wDic_orderState = resData.orderStateConfigs;
                    })
                    .catch(err => {
                        // 字典数据-订单类型
                        this.wDic_orderType = [];
                        // 字典数据-订单类型
                        this.wDic_orderState = [];
                        reject(err);
                    });
            });
        },
        /**
         * wFun_formScreenReset
         * 方法-重置表单数据
         */
        wFun_formScreenReset() {
            this.$refs["wScreenForm"].resetFields();
            this.$nextTick(() => {
                // 方法-查询表格数据
                this.wFun_queryTableData(true);
            });
        },
        /**
         * wFun_formScreenSubmit
         * 方法-提交表单数据
         */
        wFun_formScreenSubmit() {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
        },
        /**
         * wELPageCurrentChange
         * 分页 currentPage 改变时会触发
         */
        wELPageCurrentChange(wP_cPage) {
            this.wTable.wPage = wP_cPage;
            this.$nextTick(() => {
                // 方法-查询表格数据
                this.wFun_queryTableData();
            });
        },
        /**
         * wELPageSizeChange
         * 修改分页条数
         * 分页 pageSize 改变时会触发 - 每页条数
         */
        wELPageSizeChange(wP_cPageSize) {
            this.wTable.wLimit = wP_cPageSize;
            this.$nextTick(() => {
                // 方法-查询表格数据
                this.wFun_queryTableData(true);
            });
        },
        /**
         * wchange_wOrderState
         * 订单状态切换选择事件
         * @param {String} wP_value 目前的选中值
         */
        wchange_wOrderState(wP_value) {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
        },
        /**
         * wDoEnter_wOrderNumber
         * 订单编号回车事件
         */
        wDoEnter_wOrderNumber() {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
        },
        /**
         * wDoEnter_wUserKey
         * 用户信息关键字回车事件
         */
        wDoEnter_wUserKey() {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
        },
        /**
         * wchange_wTimeSlot
         * 订单查询时间筛选切换选择事件
         * @param {Array} wP_value 组件绑定值。格式与绑定值一致，可受 value-format 控制
         */
        wchange_wTimeSlot(wP_value) {
            // 方法-查询表格数据
            this.wFun_queryTableData(true);
        },
        /**
         * wFun_queryTableData
         * 方法-查询表格数据
         * @param wP_truthy 真值,true-初始值,false-加载更多
         */
        wFun_queryTableData(wP_truthy) {
            this.tableLoading = true
            let [
                wData,
                {
                    wScreen: {
                        wOrderState, // 订单状态，默认0-全部状态
                        wTimeSlot, // 查询时间段
                        wOrderNumber, // 订单编号, 默认null
                        wUserKey // 用户信息关键字，默认null
                    },
                    wTable: { wPage, wLimit, wTotal, wList }
                }
            ] = [{}, this];
            // 添加订单状态
            if (wOrderState != null) {
                wData.state = wOrderState;
            }
            // 添加查询时间
            if (wTimeSlot && wTimeSlot.length == 2) {
                wData.start =
                    wTimeSlot[0].getFullYear() +
                    "-" +
                    // (wTimeSlot[0].getMonth() + 1) +
                    (wTimeSlot[0].getMonth() + 1 < 10
                        ? "0" + (wTimeSlot[0].getMonth() + 1)
                        : wTimeSlot[0].getMonth() + 1) +
                    "-" +
                    // wTimeSlot[0].getDate();
                    (wTimeSlot[0].getDate() < 10
                        ? "0" + wTimeSlot[0].getDate()
                        : wTimeSlot[0].getDate());
                wData.end =
                    wTimeSlot[1].getFullYear() +
                    "-" +
                    // (wTimeSlot[1].getMonth() + 1) +
                    (wTimeSlot[1].getMonth() + 1 < 10
                        ? "0" + (wTimeSlot[1].getMonth() + 1)
                        : wTimeSlot[1].getMonth() + 1) +
                    "-" +
                    // wTimeSlot[1].getDate();
                    (wTimeSlot[1].getDate() < 10
                        ? "0" + wTimeSlot[1].getDate()
                        : wTimeSlot[1].getDate());
            }
            // 添加订单编号
            if (wOrderNumber) {
                wData.id = wOrderNumber;
            }
            // 添加用户信息关键字
            if (wUserKey) {
                wData.phone = wUserKey;
            }
            // 添加分页参数
            wData["pageModel.pageNo"   ] = wP_truthy ? 1 : parseInt(wPage);
            wData["pageModel.pageSize" ] = wLimit;
            wData["pageModel.sortField"] = "createTime";
            wData["pageModel.sortWay"  ] = "desc";
            wData["storeId"] = 52;
            wData["atype"] = 3;
            console.log("1", wData["pageModel.pageNo"])
            console.log("2", wData["pageModel.pageSize"])
            console.log("3", wData["pageModel.sortField"])
            console.log("4", wData["pageModel.sortWay"])

            console.log(wData, "wData");

            // 重置数据
            // this.wTable.wList = [];

            // 请求函数
            orderListByStore(wData).then(res => {
                this.tableLoading = false
                    console.log("订单数据", res)
                    // 后期权限处理

                    // let wResRecords = []
                    // let wResTotal = res.total
                    // for (let i = 0; i < res.records.length; i++) {
                    //   if (res.records[i].storeId == 58) {
                    //     wResRecords.push(res.records[i])
                    //   }
                    // }

                    let [
                        {
                            total: wResTotal,
                            records: wResRecords
                            // orderRealMoneyTotal: wOrderRealMoneyTotal
                        }
                    ] = [res];


                    // 总计金额
                    // wOrderRealMoneyTotal = (
                    //   Math.floor((wOrderRealMoneyTotal || 0) * 100) / 100
                    // ).toFixed(2);
                    // 更新数据
                    this.wTable.wPage = wP_truthy ? 1 : parseInt(wPage);
                    this.wTable.wTotal = wResTotal;
                    this.wTable.wList = wResRecords;

                    // this.wTable.wOrderRealMoneyTotal = wOrderRealMoneyTotal; // 订单总计金额

                    // console.log(this.wTable);
                })
                .catch(err => {
                    // 更新数据
                    this.wTable.wPage = wP_truthy ? 1 : parseInt(wPage);
                    this.wTable.wList = [];
                });
        },


        /**
         * wFun_doExportData
         * 方法-导出数据
         */
        wFun_doExportData() {
            let [
                wData,
                {
                    wScreen: {
                        wOrderState, // 订单状态，默认0-全部状态
                        wTimeSlot, // 查询时间段
                        wOrderNumber, // 订单编号, 默认null
                        wUserKey // 用户信息关键字，默认null
                    }
                },
                wDataStr
            ] = [{}, this, ""];

            // 添加订单状态
            if (wOrderState != null) {
                wData.orderState = wOrderState;
                wDataStr += `orderState=${wData.orderState}&`;
            }
            // 添加查询时间
            if (wTimeSlot && wTimeSlot.length == 2) {
                // wData.startTime =
                //   wTimeSlot[0].getFullYear() +
                //   "-" +
                //   (wTimeSlot[0].getMonth() + 1) +
                //   "-" +
                //   wTimeSlot[0].getDate();
                // wData.endTime =
                //   wTimeSlot[1].getFullYear() +
                //   "-" +
                //   (wTimeSlot[1].getMonth() + 1) +
                //   "-" +
                //   wTimeSlot[1].getDate();
                wData.startTime =
                    wTimeSlot[0].getFullYear() +
                    "-" +
                    // (wTimeSlot[0].getMonth() + 1) +
                    (wTimeSlot[0].getMonth() + 1 < 10
                        ? "0" + (wTimeSlot[0].getMonth() + 1)
                        : wTimeSlot[0].getMonth() + 1) +
                    "-" +
                    // wTimeSlot[0].getDate();
                    (wTimeSlot[0].getDate() < 10
                        ? "0" + wTimeSlot[0].getDate()
                        : wTimeSlot[0].getDate());
                wData.endTime =
                    wTimeSlot[1].getFullYear() +
                    "-" +
                    // (wTimeSlot[1].getMonth() + 1) +
                    (wTimeSlot[1].getMonth() + 1 < 10
                        ? "0" + (wTimeSlot[1].getMonth() + 1)
                        : wTimeSlot[1].getMonth() + 1) +
                    "-" +
                    // wTimeSlot[1].getDate();
                    (wTimeSlot[1].getDate() < 10
                        ? "0" + wTimeSlot[1].getDate()
                        : wTimeSlot[1].getDate());
                wDataStr += `startTime=${wData.startTime}&`;
                wDataStr += `endTime=${wData.endTime}&`;
            }
            // 添加订单编号
            if (wOrderNumber) {
                wData.id = wOrderNumber;
                wDataStr += `id=${wData.id}&`;
            }
            // 添加用户信息关键字
            if (wUserKey) {
                wData.userSnapshot = wUserKey;
                wDataStr += `userSnapshot=${wData.userSnapshot}&`;
            }
            // 请求文件
            // window.location.href =
            //   process.env.VUE_APP_URE +
            //   "/order/exportOrderList" +
            //   (wDataStr ? `?${wDataStr}` : "");
            // 请求函数
            wApi_order_exportOrderList({
                data: JSON.stringify(wData)
                // params: JSON.stringify(wData),
            })
                .then(res => { })
                .catch(err => { });
        },
        /**
         * wFun_confirmOrderById
         * 线下支付确认收款
         */
        wFun_confirmOrderById(wP_id, storeId) {
            this.$confirm("此操作将更改该订单的状态, 是否继续?", "完成订单", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    console.log(1);
                    // 请求函数
                    confirmCashPayApi({
                        id: wP_id,
                        storeId: storeId
                    })
                        .then(res => {
                            this.$message.success("操作成功!");
                            // 更新数据
                            this.wFun_queryTableData();
                        })
                        .catch(err => { });
                })
                .catch(() => { });
        }
    },
    beforeDestroy() {
        eventBus.$off("getOrderData", this.getOrderData);
    }
};
</script>
  
<style lang='less' scoped>
@import "@/assets/css/publicStyle.less";

.operation {
    display: flex;
    justify-content: space-around;
}

.print {
    padding: 15px;
    font-size: 18px;

    .title {
        font-size: 30px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        padding: 15px 0;
    }

    .top-info {
        .info-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .row-name {
                width: 50%;
                padding: 5px 0;
            }
        }
    }

    .goods {
        border-top: 1px solid;

        .goods-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: space-around;
            padding: 10px 0;


            .row-title {}
        }

        .goods-list {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            padding: 5px 0;

            .row-value {}
        }
    }

    .bottom-info {
        padding: 10px 0;
        border-top: 1px solid;

        .info-title {
            padding: 5px 0;
        }
    }

}

.wScreenCardHeader-right {
    display: flex;
    align-items: center;

    .wSCHR-title {
        flex: none;
        padding-right: 20px;
        box-sizing: border-box;
        color: #333333;
    }
}

// 订单详情
.wOrderDetailsArea {
    position: fixed;
    top: 50px;
    left: @wWidth_sidebar_o;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;

    .wOrderDetails {
        position: absolute;
        top: 20px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        background-color: #ffffff;

        .wOD-head {
            position: absolute;
            height: 70px;
            top: 0;
            left: 40px;
            right: 40px;
            padding-top: 20px;
            box-sizing: border-box;
            border-bottom: 1px solid #dddddd;

            .wODH-label {
                font-size: 16px;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
            }

            .wODH-btn {
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                font-size: 18px;
                color: #333333;
                cursor: pointer;
                border-radius: 4px;
                overflow: hidden;

                &:hover {
                    color: #000000;
                    background-color: #f6f8fb;
                }
            }
        }

        .wOD-body {
            position: absolute;
            top: 70px;
            left: 40px;
            right: 40px;
            bottom: 20px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;

            // 详情
            .wODB-main {
                display: block;
                width: calc(100% - 10px);
            }
        }
    }
}

// 表格板块
.wTable-block {
    display: block;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    .wTableB-head {
        margin-bottom: 10px;

        .wTableBH-label {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }

        .wTableBH-orderState {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
    }

    .wTableB-body {
        display: block;
        width: 100%;

        // 表格数据
        .wTableBB-table {
            display: block;
            width: 100%;
            border: 1px solid #dddddd;
            box-sizing: border-box;

            .wTableBBT-tr {
                width: 100%;
                background-color: #ffffff;
                border-top: 1px solid #dddddd;

                &.wTableBBT-tHead {
                    min-height: 45px;
                    padding: 10px 0;
                    box-sizing: border-box;
                    background-color: #eeeeee;
                    border-top: 0;

                    .wTableBBT-td {
                        color: #aaaaaa;
                    }
                }

                &.wTableBBT-tBody {
                    min-height: 45px;
                    padding: 10px 0;
                    box-sizing: border-box;
                }

                .wTableBBT-td {
                    padding: 0 5px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>