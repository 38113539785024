<template>
  <!-- 菜单管理 -->
  <div>
    <div class="wSet-menu">
      <div class="wSM-head">
        <el-button type="primary" @click="wDoOpenFrame('add')">新增</el-button>
      </div>
      <div class="wSM-body">
        <div class="wSMB-list">
          <div class="wSMB-item">
            <div class="wSMB-name">菜单名称</div>
            <div class="wSMB-sort">排序</div>
            <div class="wSMB-operate">操作</div>
          </div>
          <template v-for="item in wMenu.list">
            <!-- 一级 -->
            <div class="wSMB-item classA" :key="item.id">
              <div class="wSMB-name">
                <div class="wSMB-classAName" @click="wDoOpenClassB(item.id)">
                  <i
                    class="wSMBCAN-more"
                    :class="
                      wMenu.current == item.id
                        ? 'el-icon-caret-bottom'
                        : 'el-icon-caret-right'
                    "
                  ></i>
                  <i class="wSMBCAN-icon" :class="item.icon"></i>
                  <span class="wSMBCAN-text">{{ item.title }}</span>
                </div>
              </div>
              <div class="wSMB-sort">{{ item.sort }}</div>
              <div class="wSMB-operate">
                <el-button type="text" @click="wDoOpenFrame('add', item)"
                  >新增</el-button
                >
                <el-button type="text" @click="wDoOpenFrame('edit', item)"
                  >编辑</el-button
                >
                <el-button type="text" @click="wDoDelete(item.id)"
                  >删除</el-button
                >
              </div>
            </div>
            <!-- 二级 -->
            <template v-if="wMenu.current == item.id">
              <template v-for="item01 in item.subs">
                <div class="wSMB-item classB" :key="item01.id">
                  <div class="wSMB-name">
                    <div class="wSMB-classBName">{{ item01.title }}</div>
                  </div>
                  <div class="wSMB-sort">{{ item01.sort }}</div>
                  <div class="wSMB-operate">
                    <el-button type="text" @click="wDoOpenFrame('edit', item01)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="wDoDelete(item01.id)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </template>
            </template>
          </template>
        </div>
      </div>
    </div>

    <!-- 新增弹框 -->
    <el-dialog
      :title="wFrameAdd.type == 0 ? '新增' : '编辑'"
      :visible.sync="wFrameAdd.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wFrameAdd" :model="wFrameAdd.form" label-width="100px">
        <el-form-item label="上级菜单:" prop="pid">
          <el-select
            v-model="wFrameAdd.form.pid"
            placeholder="请选择"
            :disabled="wFrameAdd.type == 1"
            style="width: 100%; max-width: 250px"
          >
            <el-option
              v-for="item in wFrameAdd.form.pidArr"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="菜单名称:" prop="name">
          <el-input
            v-model="wFrameAdd.form.name"
            placeholder="菜单名称"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单路由:" prop="path">
          <el-input
            v-model="wFrameAdd.form.path"
            placeholder="菜单路由"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜单排序:" prop="sort">
          <el-input-number
            v-model="wFrameAdd.form.sort"
            :min="1"
            :max="999"
            label="菜单排序"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          label="菜单图标:"
          prop="icon"
          v-if="wFrameAdd.form.pid == 0"
        >
          <i :class="wFrameAdd.form.icon" style="margin-right: 10px"></i>
          <el-button type="text" @click="wFrameIcon.isShow = true">
            更换
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="wDoSaveMenu()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 图标弹框 -->
    <el-dialog
      title="图标"
      :visible.sync="wFrameIcon.isShow"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="wFrameIcon-list">
        <template v-for="(item, index) in wFrameIcon.list">
          <div
            class="wFrameIcon-item"
            :key="index"
            @click="wDoChoiceIcon(item)"
          >
            <i :class="item"></i>
            <!-- <div>{{item}}</div> -->
          </div>
        </template>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  pub_sidebarArr,
  set_queryMenuAll,
  set_doMenuAdd,
  set_doMenuEdit,
  set_doMenuDelete,
} from "@/network/wApi.js";

export default {
  data() {
    return {
      // 菜单
      wMenu: {
        current: 0, // 默认展开
        list: [],
      },

      // 新增弹框
      wFrameAdd: {
        isShow: false, // 是否展示
        type: 0, // 弹框类型，默认0-新增，1-编辑修改
        form: {
          cid: 0, // 当前栏目id,默认0-新增
          pid: 0, // 上级id,默认0-主菜单
          pidArr: [
            // {
            //   id: 0,
            //   title: "主目录",
            // },
          ], // 一级数据,新增： 0-主目录(没有上级)
          name: "", // 栏目昵称
          path: "", // 栏目路由
          sort: 1, // 排序设置，默认-1，数值越大越后
          icon: "el-icon-s-tools", // 栏目路由
        }, // 表单数据
      },

      // 图标弹框
      wFrameIcon: {
        isShow: false, // 是否展示
        list: [
          "el-icon-platform-eleme",
          "el-icon-eleme",
          "el-icon-delete-solid",
          "el-icon-delete",
          "el-icon-s-tools",
          "el-icon-setting",
          "el-icon-user-solid",
          "el-icon-user",
          "el-icon-phone",
          "el-icon-phone-outline",
          "el-icon-more",
          "el-icon-more-outline",
          "el-icon-star-on",
          "el-icon-star-off",
          "el-icon-s-goods",
          "el-icon-goods",
          "el-icon-warning",
          "el-icon-warning-outline",
          "el-icon-question",
          "el-icon-info",
          "el-icon-remove",
          "el-icon-circle-plus",
          "el-icon-success",
          "el-icon-error",
          "el-icon-zoom-in",
          "el-icon-zoom-out",
          "el-icon-remove-outline",
          "el-icon-circle-plus-outline",
          "el-icon-circle-check",
          "el-icon-circle-close",
          "el-icon-s-help",
          "el-icon-help",
          "el-icon-minus",
          "el-icon-plus",
          "el-icon-check",
          "el-icon-close",
          "el-icon-picture",
          "el-icon-picture-outline",
          "el-icon-picture-outline-round",
          "el-icon-upload",
          "el-icon-upload2",
          "el-icon-download",
          "el-icon-camera-solid",
          "el-icon-camera",
          "el-icon-video-camera-solid",
          "el-icon-video-camera",
          "el-icon-message-solid",
          "el-icon-bell",
          "el-icon-s-cooperation",
          "el-icon-s-order",
          "el-icon-s-platform",
          "el-icon-s-fold",
          "el-icon-s-unfold",
          "el-icon-s-operation",
          "el-icon-s-promotion",
          "el-icon-s-home",
          "el-icon-s-release",
          "el-icon-s-ticket",
          "el-icon-s-management",
          "el-icon-s-open",
          "el-icon-s-shop",
          "el-icon-s-marketing",
          "el-icon-s-flag",
          "el-icon-s-comment",
          "el-icon-s-finance",
          "el-icon-s-claim",
          "el-icon-s-custom",
          "el-icon-s-opportunity",
          "el-icon-s-data",
          "el-icon-s-check",
          "el-icon-s-grid",
          "el-icon-menu",
          "el-icon-share",
          "el-icon-d-caret",
          "el-icon-caret-left",
          "el-icon-caret-right",
          "el-icon-caret-bottom",
          "el-icon-caret-top",
          "el-icon-bottom-left",
          "el-icon-bottom-right",
          "el-icon-back",
          "el-icon-right",
          "el-icon-bottom",
          "el-icon-top",
          "el-icon-top-left",
          "el-icon-top-right",
          "el-icon-arrow-left",
          "el-icon-arrow-right",
          "el-icon-arrow-down",
          "el-icon-arrow-up",
          "el-icon-d-arrow-left",
          "el-icon-d-arrow-right",
          "el-icon-video-pause",
          "el-icon-video-play",
          "el-icon-refresh",
          "el-icon-refresh-right",
          "el-icon-refresh-left",
          "el-icon-finished",
          "el-icon-sort",
          "el-icon-sort-up",
          "el-icon-sort-down",
          "el-icon-rank",
          "el-icon-loading",
          "el-icon-view",
          "el-icon-c-scale-to-original",
          "el-icon-date",
          "el-icon-edit",
          "el-icon-edit-outline",
          "el-icon-folder",
          "el-icon-folder-opened",
          "el-icon-folder-add",
          "el-icon-folder-remove",
          "el-icon-folder-delete",
          "el-icon-folder-checked",
          "el-icon-tickets",
          "el-icon-document-remove",
          "el-icon-document-delete",
          "el-icon-document-copy",
          "el-icon-document-checked",
          "el-icon-document",
          "el-icon-document-add",
          "el-icon-printer",
          "el-icon-paperclip",
          "el-icon-takeaway-box",
          "el-icon-search",
          "el-icon-monitor",
          "el-icon-attract",
          "el-icon-mobile",
          "el-icon-scissors",
          "el-icon-umbrella",
          "el-icon-headset",
          "el-icon-brush",
          "el-icon-mouse",
          "el-icon-coordinate",
          "el-icon-magic-stick",
          "el-icon-reading",
          "el-icon-data-line",
          "el-icon-data-board",
          "el-icon-pie-chart",
          "el-icon-data-analysis",
          "el-icon-collection-tag",
          "el-icon-film",
          "el-icon-suitcase",
          "el-icon-suitcase-1",
          "el-icon-receiving",
          "el-icon-collection",
          "el-icon-files",
          "el-icon-notebook-1",
          "el-icon-notebook-2",
          "el-icon-toilet-paper",
          "el-icon-office-building",
          "el-icon-school",
          "el-icon-table-lamp",
          "el-icon-house",
          "el-icon-no-smoking",
          "el-icon-smoking",
          "el-icon-shopping-cart-full",
          "el-icon-shopping-cart-1",
          "el-icon-shopping-cart-2",
          "el-icon-shopping-bag-1",
          "el-icon-shopping-bag-2",
          "el-icon-sold-out",
          "el-icon-sell",
          "el-icon-present",
          "el-icon-box",
          "el-icon-bank-card",
          "el-icon-money",
          "el-icon-coin",
          "el-icon-wallet",
          "el-icon-discount",
          "el-icon-price-tag",
          "el-icon-news",
          "el-icon-guide",
          "el-icon-male",
          "el-icon-female",
          "el-icon-thumb",
          "el-icon-cpu",
          "el-icon-link",
          "el-icon-connection",
          "el-icon-open",
          "el-icon-turn-off",
          "el-icon-set-up",
          "el-icon-chat-round",
          "el-icon-chat-line-round",
          "el-icon-chat-square",
          "el-icon-chat-dot-round",
          "el-icon-chat-dot-square",
          "el-icon-chat-line-square",
          "el-icon-message",
          "el-icon-postcard",
          "el-icon-position",
          "el-icon-turn-off-microphone",
          "el-icon-microphone",
          "el-icon-close-notification",
          "el-icon-bangzhu",
          "el-icon-time",
          "el-icon-odometer",
          "el-icon-crop",
          "el-icon-aim",
          "el-icon-switch-button",
          "el-icon-full-screen",
          "el-icon-copy-document",
          "el-icon-mic",
          "el-icon-stopwatch",
          "el-icon-medal-1",
          "el-icon-medal",
          "el-icon-trophy",
          "el-icon-trophy-1",
          "el-icon-first-aid-kit",
          "el-icon-discover",
          "el-icon-place",
          "el-icon-location",
          "el-icon-location-outline",
          "el-icon-location-information",
          "el-icon-add-location",
          "el-icon-delete-location",
          "el-icon-map-location",
          "el-icon-alarm-clock",
          "el-icon-timer",
          "el-icon-watch-1",
          "el-icon-watch",
          "el-icon-lock",
          "el-icon-unlock",
          "el-icon-key",
          "el-icon-service",
          "el-icon-mobile-phone",
          "el-icon-bicycle",
          "el-icon-truck",
          "el-icon-ship",
          "el-icon-basketball",
          "el-icon-football",
          "el-icon-soccer",
          "el-icon-baseball",
          "el-icon-wind-power",
          "el-icon-light-rain",
          "el-icon-lightning",
          "el-icon-heavy-rain",
          "el-icon-sunrise",
          "el-icon-sunrise-1",
          "el-icon-sunset",
          "el-icon-sunny",
          "el-icon-cloudy",
          "el-icon-partly-cloudy",
          "el-icon-cloudy-and-sunny",
          "el-icon-moon",
          "el-icon-moon-night",
          "el-icon-dish",
          "el-icon-dish-1",
          "el-icon-food",
          "el-icon-chicken",
          "el-icon-fork-spoon",
          "el-icon-knife-fork",
          "el-icon-burger",
          "el-icon-tableware",
          "el-icon-sugar",
          "el-icon-dessert",
          "el-icon-ice-cream",
          "el-icon-hot-water",
          "el-icon-water-cup",
          "el-icon-coffee-cup",
          "el-icon-cold-drink",
          "el-icon-goblet",
          "el-icon-goblet-full",
          "el-icon-goblet-square",
          "el-icon-goblet-square-full",
          "el-icon-refrigerator",
          "el-icon-grape",
          "el-icon-watermelon",
          "el-icon-cherry",
          "el-icon-apple",
          "el-icon-pear",
          "el-icon-orange",
          "el-icon-coffee",
          "el-icon-ice-tea",
          "el-icon-ice-drink",
          "el-icon-milk-tea",
          "el-icon-potato-strips",
          "el-icon-lollipop",
          "el-icon-ice-cream-square",
          "el-icon-ice-cream-round",
        ],
      },
    };
  },
  mounted() {
    // 查询所有的菜单列表
    this.wQueryMenuList();
  },
  methods: {
    /**
     * wQueryMenuList
     * 查询所有的菜单列表
     */
    wQueryMenuList() {
      // 请求函数
      set_queryMenuAll().then((res) => {
        // 更新数据
        this.wMenu.list = [];
        this.wFrameAdd.form.pidArr = [
          {
            id: 0,
            title: "主目录",
          },
        ];
        this.wMenu.list = res;
        this.wFrameAdd.form.pidArr.push(...res);
      });
    },

    /**
     * wDoOpenClassB
     * 打开二级目录
     * wP_id 一级栏目的id
     */
    wDoOpenClassB(wP_id) {
      if (this.wMenu.current == wP_id) {
        this.wMenu.current = 0;
      } else {
        this.wMenu.current = wP_id;
      }
    },

    /**
     * wDoOpenFrame
     * 打开新增编辑菜单弹框
     * wP_type 类型,
     * wP_info 修改的信息
     */
    wDoOpenFrame(wP_type, wP_info) {
      // 打开弹框
      this.wFrameAdd.isShow = true;

      this.$nextTick(() => {
        // 对该表单项进行重置
        this.$refs["wFrameAdd"].resetFields();

        // 数据处理
        switch (wP_type) {
          case "add":
            // 新增
            {
              this.wFrameAdd.type = 0;
              // 二级新增的时候
              this.wFrameAdd.form.pid = (wP_info && wP_info.id) || 0;
            }
            break;
          case "edit":
            // 修改
            {
              if (!wP_info) {
                this.wFrameAdd.isShow = false;
                this.$message.error("系统繁忙，请稍后再试!");
                return;
              }
              // 赋值
              this.wFrameAdd.type = 1;
              this.wFrameAdd.form.cid = wP_info.id;
              this.wFrameAdd.form.pid = wP_info.pid;
              this.wFrameAdd.form.name = wP_info.title;
              this.wFrameAdd.form.path = wP_info.index;
              this.wFrameAdd.form.sort = wP_info.sort;
              this.wFrameAdd.form.icon = wP_info.icon;
            }
            break;

          default:
            break;
        }
      });
    },

    /**
     * wDoChoiceIcon
     * 图标选择
     * wP_icon 选中的图标
     */
    wDoChoiceIcon(wP_icon) {
      // 更换图标
      this.wFrameAdd.form.icon = wP_icon;
      // 关闭弹框
      this.wFrameIcon.isShow = false;
    },

    /**
     * wDoSaveMenu
     * 保存菜单数据
     */
    wDoSaveMenu() {
      // 请求参数
      let wData = {};

      // 获取值
      let { type: wM_type, form: wM_form } = this.wFrameAdd;

      // 数据处理
      wData.pid = wM_form.pid;
      if (!wM_form.name) {
        this.$message.error("菜单名称不能为空！");
        return;
      } else {
        wData.title = wM_form.name;
      }
      if (!wM_form.path) {
        this.$message.error("菜单路由不能为空！");
        return;
      } else {
        wData.flag = wM_form.path;
      }
      if (!wM_form.sort) {
        this.$message.error("菜单排序不能为空！");
        return;
      } else {
        wData.sort = wM_form.sort;
      }

      // 弹框类型，默认0-新增，1-编辑修改
      switch (`${wM_type}`) {
        case "0":
          // 新增
          {
            wData.icon = wM_form.icon;
            set_doMenuAdd(wData).then((res) => {
              this.wFrameAdd.isShow = false;
              this.wQueryMenuList();
              // 更新一级目录
              this.wMenu.current = wData.pid;
              // // 刷新页面
              // this.$router.go(0);
              // 更新侧边导航数据
              this.wDoUpdateSidebar();
            });
          }
          break;
        case "1":
          // 编辑修改
          {
            wData.id = wM_form.cid;
            set_doMenuEdit(wData).then((res) => {
              this.wFrameAdd.isShow = false;
              this.wQueryMenuList();
              // // 刷新页面
              // this.$router.go(0);
              // 更新侧边导航数据
              this.wDoUpdateSidebar();
            });
          }
          break;

        default:
          this.$message("功能待开发!");
          break;
      }
    },

    /**
     * wDoDelete
     * 删除菜单数据
     * wP_id
     */
    wDoDelete(wP_id) {
      this.$confirm("此操作将永久删除该菜单, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 请求函数
          set_doMenuDelete({
            ids: [wP_id],
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.wQueryMenuList();
            // // 刷新页面
            // this.$router.go(0);
            // 更新侧边导航数据
            this.wDoUpdateSidebar();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    /**
     * wDoUpdateSidebar
     * 更新侧边导航数据
     */
    wDoUpdateSidebar() {
      // 请求函数
      pub_sidebarArr().then((res) => {
        // 更新数据
        this.$store.commit("sidebarArr", res);
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 菜单管理
.wSet-menu {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;

  // 头部按钮
  .wSM-head {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }

  // 内容区
  .wSM-body {
    display: block;
    width: 100%;

    .wSMB-list {
      display: block;
      width: 100%;

      .wSMB-item {
        display: flex;
        width: 100%;
        min-height: 40px;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0;
        box-sizing: border-box;
        font-size: 16px;

        &:hover {
          background-color: #f5f5f5;
        }

        &.classA {
          border-top: 1px solid #cccccc;
        }

        &:first-child {
          font-size: 14px;
          background-color: #eff3f8;
        }

        .wSMB-name {
          flex: none;
          display: flex;
          width: 500px;
          justify-content: flex-start;
          align-items: center;
          padding: 0 20px;
          box-sizing: border-box;

          .wSMB-classAName {
            flex: none;
            display: flex;
            align-items: center;
            cursor: pointer;

            .wSMBCAN-more {
              font-size: 18px;
            }
            .wSMBCAN-icon {
              font-size: 18px;
              margin: 0 5px;
            }
          }

          .wSMB-classBName {
            padding-left: 50px;
            box-sizing: border-box;
          }
        }

        .wSMB-sort {
          flex: none;
          display: flex;
          width: 100px;
          justify-content: center;
          align-items: center;
        }

        .wSMB-operate {
          flex: none;
          display: flex;
          width: 400px;
          justify-content: center;
          align-items: center;
        }

        &.classB {
          font-size: 14px;

          .wSMB-sort {
            color: @wColor_theme;
          }
        }
      }
    }
  }
}

// 图标
.wFrameIcon-list {
  display: flex;
  width: 100%;
  height: 600px;
  overflow: auto;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  .wFrameIcon-item {
    flex: none;
    display: flex;
    width: 100px;
    height: 100px;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    cursor: pointer;

    &:hover {
      color: @wColor_theme;
    }
  }
}
</style>