<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    
  },
  methods: {}
};
</script>

<style>
/* 页面容器 */
.wPageContainer {
  display: block;
  width: 100%;
  min-width: 1200px;
  padding: 20px 16px;
  box-sizing: border-box;
}

/* 筛选查询 */
.wScreenArea {
  display: block;
  width: 100%;
  user-select: none;
  margin-bottom: 20px;
}
.wScreenCardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 表格区域 */
.wTableArea {
  display: block;
  width: 100%;
  padding: 20px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
}

/* 页码 */
.wPagination {
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: right;
}
</style>
