<template>
  <!-- 财务记录 -->
  <div>
    <div class="wMember-financialRecords">
      <div class="wMFR-head">
        <div class="wMFRH-list">
          <template v-for="item in wScreen.options">
            <div
              class="wMFRH-item"
              :class="{ wActive: item.id == wScreen.value }"
              :key="item.id"
              @click="wDoChangeTab(item.id)"
            >
              {{ item.label }}
            </div>
          </template>
        </div>
      </div>
      <div class="wMFR-line"></div>
      <div class="wMFR-body">
        <div class="wMFRB-list">
          <van-empty description="暂无数据" v-if="wFRecords.list.length == 0" />
          <template v-for="(item, index) in wFRecords.list">
            <div class="wMFRB-item" :key="index">
              <div class="wMFRB-info">
                <div class="wMFRBI-title">{{ item.info }}</div>
                <div class="wMFRBI-time">{{ item.createTime }}</div>
              </div>
              <div class="wMFRB-money">{{ item.orderMoney }}</div>
            </div>
          </template>
        </div>

        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="wFRecords.page"
            :page-size="wFRecords.limit"
            :total="wFRecords.total"
            :page-sizes="[5, 10, 15, 20, 50]"
            :hide-on-single-page="false"
            @current-change="wELPageCurrentChange"
            @size-change="wELPageSizeChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { member_getFinancialRecords } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 分类筛选
      wScreen: {
        value: "0",
        options: [
          {
            id: "0",
            label: "全部",
          },
          {
            id: "1",
            label: "收入",
          },
          {
            id: "2",
            label: "提现",
          },
          {
            id: "3",
            label: "充值",
          },
          {
            id: "4",
            label: "支出",
          },
        ],
      },

      // 财务记录数据
      wFRecords: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
      },
    };
  },
  created() {
    // 获取用户财务记录信息
    this.wGetFinancialRecords();
  },
  methods: {
    /**
     * wDoChangeTab
     * 切换选择分类
     * wP_id
     */
    wDoChangeTab(wP_id) {
      this.wScreen.value = wP_id;
      this.wFRecords.page = 1;

      this.$nextTick(() => {
        // 获取用户财务记录信息
        this.wGetFinancialRecords();
      });
    },

    /**
     * wGetFinancialRecords
     * 获取用户财务记录信息
     */
    wGetFinancialRecords() {
      // 请求参数
      let wData = {};
      // 获取值
      let { userid: wFR_userId } = this.$route.query;
      let { value: wFRS_value } = this.wScreen;
      let { page, limit } = this.wFRecords;
      // 数据处理
      if (!wFR_userId) {
        this.$message.error("系统繁忙，请稍后再试~");
        return;
      }
      wData.userId = wFR_userId;
      // 分类筛选: 0-全部, 1-收入, 2-提现, 3-充值, 4-支出
      // 收支类型:101.直推收入 102.管理奖收入 103.分公司奖收入 104.分公司扶持奖 105.充值 106.自身退款 201.提现支出 202.转账支出 203.消费支出
      switch (`${wFRS_value}`) {
        case "0":
          // 全部
          {
            // wData.incomeType = [101, 102, 103, 104, 105, 106, 201, 202, 203];
          }
          break;
        case "1":
          // 收入
          {
            wData.incomeType = [101, 102, 103, 104, 106];
          }
          break;
        case "2":
          // 提现
          {
            wData.incomeType = [201];
          }
          break;
        case "3":
          // 充值
          {
            wData.incomeType = [105];
          }
          break;
        case "4":
          // 支出
          {
            wData.incomeType = [202, 203];
          }
          break;

        default:
          break;
      }
      wData["pageModel.pageNo"] = page;
      wData["pageModel.pageSize"] = limit;

      // 请求函数
      member_getFinancialRecords(wData).then((res) => {
        this.wFRecords.total = res.total;
        this.wFRecords.list = res.records;
      });
    },

    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      // 更改当前页码数据
      this.wFRecords.page = wP_cPage;
      // // 获取列表数据
      this.$nextTick(() => {
        // 获取用户财务记录信息
        this.wGetFinancialRecords();
      });
    },

    /**
     * wELPageSizeChange
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wFRecords.page = 1;
      this.wFRecords.limit = wP_cPageSize;
      // // 获取列表数据
      this.$nextTick(() => {
        // 获取用户财务记录信息
        this.wGetFinancialRecords();
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 财务记录
.wMember-financialRecords {
  display: block;
  width: 100%;
  padding: 40px 60px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.wMFR-head {
  display: flex;
  user-select: none;
  align-items: center;
  justify-content: space-between;

  .wMFRH-list {
    flex: none;
    display: flex;
    align-items: center;
    background: #eeeeee;

    .wMFRH-item {
      flex: none;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #dddddd;
      cursor: pointer;
      font-size: 16rpx;
      font-weight: 400;
      color: #666666;

      &.wActive,
      &:hover {
        background-color: #ff6321;
        border-color: #ff6321;
        color: #ffffff;
      }
    }
  }
}

.wMFR-line {
  display: block;
  width: 100%;
  height: 1px;
  margin: 20px 0 36px;
  background-color: #dddddd;
}

.wMFR-body {
  display: block;
  width: 100%;

  .wMFRB-list {
    display: block;
    width: 100%;
    border: 1px solid #dddddd;

    .wMFRB-item {
      display: flex;
      padding: 15px 40px;
      box-sizing: border-box;
      border-top: 1px solid #dddddd;
      justify-content: space-between;
      align-items: center;

      &:first-child {
        border-top: none;
      }

      .wMFRB-info {
        flex: auto;

        .wMFRBI-title {
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
        }

        .wMFRBI-time {
          display: block;
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          margin-top: 5px;
        }
      }

      .wMFRB-money {
        flex: none;
        width: 10%;
        min-width: 100px;
        text-align: right;
        font-size: 20px;
        font-weight: 500;
        color: #ff6321;
        letter-spacing: 1px;
        user-select: none;
      }
    }
  }
}
</style>