<template>
  <!-- 会员列表 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wML-screen">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>筛选查询</span>
        </div>
        <!-- 筛选列表 -->
        <div class="wMLS-list">
          <el-form
            ref="wScreenForm"
            label-position="left"
            label-width="90px"
            :model="wScreen"
          >
            <el-row :gutter="20">
              <!-- 手机号 -->
              <el-col :span="6">
                <el-form-item label="搜索关键字" prop="phone">
                  <el-input
                    style="width: 100%; max-width: 200px"
                    type="text"
                    v-model="wScreen.phone"
                    placeholder="请输入手机号或昵称"
                    @keyup.enter.native="wDoEnter_keywords"
                  ></el-input>
                </el-form-item>
              </el-col>
              <!-- 合伙人等级 -->
              <el-col :span="6">
                <el-form-item label="用户等级" prop="level.value">
                  <el-select
                    v-model="wScreen.level.value"
                    placeholder="请选择用户等级"
                    @change="wchange_select"
                  >
                    <el-option
                      v-for="item in wScreen.level.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 账号状态 -->
              <el-col :span="6" v-if="false">
                <el-form-item label="账号状态" prop="state.value">
                  <el-select
                    v-model="wScreen.state.value"
                    placeholder="全部状态"
                    @change="wchange_select"
                  >
                    <el-option
                      v-for="item in wScreen.state.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 注册时间 -->
              <el-col :span="6">
                <el-form-item label="注册时间" prop="timeGroup">
                  <el-date-picker
                    style="width: 100%"
                    v-model="wScreen.timeGroup"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="wchange_select"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- 操作按钮 -->
              <el-col :span="24">
                <el-button size="medium" @click="wResetForm"> 重置 </el-button>
                <el-button size="medium" @click="wSubmitForm"> 查询 </el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card>
    </div>

    <!-- 会员数据列表 -->
    <div class="wML-member">
      <el-tabs
        v-model="wTabs.value"
        type="border-card"
        @tab-click="wDoChangeELTabs"
      >
        <el-tab-pane
          :key="item.name"
          v-for="(item, index) in wTabs.options"
          :name="item.name"
          :label="item.val"
        >
          <!-- 合伙人列表 -->
          <div class="wMLM-block" v-show="item.name == 'HHRLB'">
            <!-- table表 -->
            <div class="wMLM-table">
              <el-table :data="item.list" border style="width: 100%" v-loading="tableLoading">
                <el-table-column label="头像" width="100" align="center">
                  <template slot-scope="scope">
                    <el-avatar :size="60" :src="scope.row.avatar"></el-avatar>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="nickName"
                  label="名称"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="phone"
                  label="手机号"
                  align="center"
                ></el-table-column>
                <!-- <el-table-column
                    label="二维码"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <el-image
                        style="width: 100px; height: 100px"
                        :src="scope.row.qrCode"
                        fit="cover"
                        :preview-src-list="[scope.row.qrCode]"
                      ></el-image>
                    </template>
                  </el-table-column> -->
                <!-- <el-table-column
                  prop="realName"
                  label="真实姓名"
                  align="center"
                ></el-table-column> -->
                <el-table-column
                  prop="identityName"
                  label="会员等级"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div v-html="scope.row.identityName"></div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="balance"
                  label="余额"
                  align="center"
                ></el-table-column>
                <el-table-column
                  prop="vipIntegral"
                  label="积分"
                  align="center"
                ></el-table-column>
                <!-- <el-table-column
                    prop="assessScore"
                    label="测评积分"
                    align="center"
                  ></el-table-column> -->
                <!-- <el-table-column
                    prop="share"
                    label="股份分配"
                    align="center"
                  ></el-table-column> -->
                <!-- <el-table-column
                    prop="createWayName"
                    label="创建方式"
                    align="center"
                  ></el-table-column> -->
                <el-table-column
                  prop="superiorName"
                  label="推荐人"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.superiorName }}</div>
                    <div>{{ scope.row.superiorPhone }}</div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createDate"
                  label="注册时间"
                  align="center"
                ></el-table-column>
                <el-table-column prop="wT_state" label="状态" v-if="false">
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.locked"
                      :active-text="scope.row.locked ? '账号有效' : '账号失效'"
                      @change="wELSwitch_state(scope.row, index)"
                    >
                    </el-switch>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="wDoTableBtn(scope, 'FXXQ')">
                      <span>分销详情</span>
                    </el-button>
                    <el-button type="text" size="small" @click="changeIdentity(scope)" >
                      <span>修改身份</span>
                    </el-button>
                    <template v-if="wIsAdmin">
                      <!-- <el-button
                        type="text"
                        size="small"
                        @click="wDoTableBtn(scope, 'RJ')"
                        v-if="scope.row.isSubscribe == 0"
                        ><span>认缴</span>
                      </el-button>
                      <el-button type="text" size="small" v-else
                        ><span>已认缴</span>
                      </el-button> -->
                      <!-- <el-button
                        type="text"
                        size="small"
                        @click="wDoTableBtn(scope, 'RJ')"
                        ><span>认缴</span>
                      </el-button> -->
                    </template>
                    <!-- <template v-if="false">
                        <el-button
                          type="text"
                          size="small"
                          @click="wDoTableBtn(scope, 'RJGXHHR')"
                          v-if="scope.row.isAccessory == 0"
                        ><span>认缴共享合伙人</span>
                        </el-button>
                        <el-button
                          type="text"
                          size="small"
                          v-else
                        ><span>已认缴共享合伙人</span>
                        </el-button>
                      </template> -->
                    <!-- <el-button
                        type="text"
                        size="small"
                        @click="wDoTableBtn(scope, 'GGTJR')"
                        v-if="wIsAdmin"
                      ><span>更改推荐人</span>
                      </el-button> -->
                    <!-- <el-button
                        type="text"
                        size="small"
                        @click="wDoTableBtn(scope, 'PESZ')"
                        v-if="wIsAdmin || wAccountValue == 'jiangjiyue'"
                      ><span>配额设置</span>
                      </el-button> -->
                    <!-- <el-button
                      type="text"
                      size="small"
                      @click="wDoTableBtn(scope, 'XGZL')"
                      v-if="wIsAdmin"
                      ><span>修改资料</span>
                    </el-button> -->
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="wPagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :current-page="item.page"
                :page-size="item.limit"
                :total="item.total"
                :page-sizes="[5, 10, 15, 20, 50]"
                :hide-on-single-page="false"
                @current-change="wELPageCurrentChange"
                @size-change="wELPageSizeChange"
              >
              </el-pagination>
            </div>
          </div>
          <!-- 认缴列表 -->
          <div class="wMLM-block" v-show="item.name == 'RJLB'">
            <!-- table表 -->
            <div class="wMLM-table">
              <el-table :data="item.list" border style="width: 100%">
                <el-table-column label="头像">
                  <template slot-scope="scope">
                    <el-avatar :src="scope.row.wT_imgHP"></el-avatar>
                  </template>
                </el-table-column>
                <el-table-column prop="wT_name" label="名称"></el-table-column>
                <el-table-column
                  prop="wT_phone"
                  label="手机号"
                ></el-table-column>
                <el-table-column
                  prop="wT_level"
                  label="会员等级"
                ></el-table-column>
                <el-table-column
                  prop="wT_payMoney"
                  label="实缴金额"
                ></el-table-column>
                <el-table-column
                  prop="wT_createDate"
                  label="创建时间"
                ></el-table-column>
                <el-table-column prop="wT_state" label="状态">
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      size="small"
                      @click="wDoTableBtn(scope, 'BJ')"
                      ><span>补缴</span>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="wPagination">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next, jumper"
                :current-page="item.page"
                :page-size="item.limit"
                :total="item.total"
                :page-sizes="[5, 10, 15, 20, 50]"
                :hide-on-single-page="false"
                @current-change="wELPageCurrentChange"
                @size-change="wELPageSizeChange"
              >
              </el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <!-- 添加会员按钮 -->
      <el-button
        class="wML-addBtn"
        size="small"
        type="primary"
        @click="wDoOpenAddMemberFrame"
        v-if="wIsAdmin"
      >
        添加会员
      </el-button>
    </div>
    <!-- 修改身份 -->
    <el-dialog title="修改身份" :visible.sync="dialogIdentity" width="800px" append-to-body :close-on-click-modal="false">
      <el-form  :model="formIdentity" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="用户名称">
              <el-input v-model="formIdentity.nickName" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户手机">
              <el-input v-model="formIdentity.phone" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <!-- <el-col :span="12">
            <el-form-item label="真实姓名">
              <el-input v-model="formIdentity.name" :disabled="true" />
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="会员等级">
              <el-select v-model="formIdentity.identity" placeholder="请选择会员等级" style="width: 100%;" >
                <el-option v-for="item in optionsIdentity" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogIdentity = false">取 消</el-button>
        <el-button type="primary" @click="updateIdentityBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 认缴弹框 -->
    <el-dialog
      title="认缴"
      :visible.sync="wPayment.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wPaymentForm" :model="wPayment" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像:" prop="imgHP">
              <el-avatar size="large" :src="wPayment.imgHP"></el-avatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <span>{{ wPayment.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号:" prop="phone">
              <span>{{ wPayment.phone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="会员等级:" prop="level.value">
              <el-select
                v-model="wPayment.level.value"
                placeholder="会员等级"
                style="width: 100%; max-width: 200px"
                @change="wELSelect_change"
              >
                <el-option
                  v-for="item in achievementList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="股份分配:" prop="shares">
              <el-input
                v-model="wPayment.shares"
                :placeholder="wC_levelSharePla"
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="认缴金额:" prop="money">
              <el-input
                v-model="wPayment.money"
                :placeholder="wC_moneyPla"
                style="width: 100%; max-width: 200px"
              >
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item
              label="是否分佣:"
              prop="subcom"
            >
              <el-switch v-model="wPayment.subcom"> </el-switch>
            </el-form-item>
          </el-col> -->
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" @click="wDoPayment_confirm()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 认缴弹框确认弹框 -->
    <el-dialog
      title="认缴"
      :visible.sync="wPayment.confirm"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wPaymentForm" :model="wPayment" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像:" prop="imgHP">
              <el-avatar size="large" :src="wPayment.imgHP"></el-avatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <span>{{ wPayment.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号:" prop="phone">
              <span>{{ wPayment.phone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="会员等级:" prop="level.value">
              <span>{{ wGetPaymentLevel(wPayment.level.value) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="股份分配:" prop="shares">
              <span>{{ wPayment.shares }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="认缴金额:" prop="money">
              <span>{{ wPayment.money }}元</span>
            </el-form-item>
          </el-col>
          <!-- <el-col
            :span="24"
            v-if="false"
          >
            <el-form-item
              label="积分:"
              prop="integral"
            >
              <span>{{ wPayment.integral }}</span>
            </el-form-item>
          </el-col> -->
          <!-- <el-col :span="24">
            <el-form-item
              label="是否分佣:"
              prop="subcom"
            >
              <span>{{ wPayment.subcom ? "分佣" : "不分佣" }}</span>
            </el-form-item>
          </el-col>  -->
          <el-col :span="24" style="text-align: center">
            <el-button @click="wDoCanclePayment()">取消</el-button>
            <el-button type="primary" @click="wDoPayment()">确定</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 修改推荐人弹框 -->
    <el-dialog
      title="推荐人"
      :visible.sync="wEditTJR.isShow"
      append-to-body
      :close-on-click-modal="false"
    >
      <!-- 搜索区 -->
      <div class="wETJRFSerach-area">
        <el-input
          style="width: 100%; max-width: 400px"
          type="text"
          v-model="wEditTJR.keyword"
          maxlength="11"
          placeholder="请输入手机号"
        ></el-input>
        <el-button type="primary" @click="wETJRFSerachBtn">搜索</el-button>
      </div>
      <!-- 表格数据区 -->
      <div class="wETJRFTable-area">
        <el-table :data="wEditTJR.list" style="width: 100%">
          <el-table-column label="会员">
            <template slot-scope="scope">
              <div class="wETJRFTable-user">
                <el-avatar :size="30" :src="scope.row.avatar"></el-avatar>
                <div class="wETJRFTable-userName">{{ scope.row.nickName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" @click="wETJRFSerachChoice(scope)">
                选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="wPagination">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :current-page="wEditTJR.page"
            :page-size="wEditTJR.limit"
            :total="wEditTJR.total"
            :hide-on-single-page="false"
            @current-change="wELPageCurrentChange_edit"
            @size-change="wELPageSizeChange_edit"
          >
          </el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 添加会员弹框 -->
    <el-dialog
      title="添加会员"
      :visible.sync="wAddMember.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wAddMemberForm" :model="wAddMember.form" label-width="80px">
        <el-form-item label="手机号" required>
          <el-input
            v-model="wAddMember.form.phone"
            placeholder="手机号"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input
            v-model="wAddMember.form.name"
            :placeholder="wAddMember.form.phone || '姓名'"
          ></el-input>
        </el-form-item>
        <el-form-item label="推荐人">
          <span style="margin-right: 20px" v-if="wAddMember.form.referrerId">{{
            wAddMember.form.referrerNickName
          }}</span>
          <el-button size="mini" type="primary" @click="wDoChoiceReferrer"
            >选择推荐人</el-button
          >
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            :action="wGetUploadImgPath()"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <div class="avatar wAvatar-imgBox">
              <!-- 删除 -->
              <div
                class="wAvatar-imgDel"
                v-if="wAddMember.form.imgHP"
                @click.stop="wDoAvatarRemove"
              >
                <i class="el-icon-delete-solid"></i>
              </div>
              <img
                v-if="wAddMember.form.imgHP"
                :src="wAddMember.form.imgHP"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
        <div class="wAddMemberFrame-btnGroup">
          <el-button
            class="wAddMemberFrame-btn"
            type="primary"
            @click="wDoAddMember"
            >添加</el-button
          >
        </div>
      </el-form>
    </el-dialog>

    <!-- 认缴共享合伙人弹框 -->
    <el-dialog
      title="认缴共享合伙人"
      :visible.sync="wPayment_gxhhr.isShow"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wPaymentForm" :model="wPayment_gxhhr" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像:" prop="imgHP">
              <el-avatar size="large" :src="wPayment_gxhhr.imgHP"></el-avatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <span>{{ wPayment_gxhhr.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号:" prop="phone">
              <span>{{ wPayment_gxhhr.phone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="会员等级:" prop="level.value">
              <el-select
                v-model="wPayment_gxhhr.level.value"
                placeholder="会员等级"
                style="width: 100%; max-width: 200px"
                @change="wELSelect_change_gxhhr"
              >
                <el-option
                  v-for="item in wPayment_gxhhr.level.options"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分红股:" prop="shares">
              <el-input
                v-model="wPayment_gxhhr.shares"
                :placeholder="wC_levelSharePla_gxhhr"
                style="width: 100%; max-width: 200px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否分佣:" prop="subcom">
              <el-switch v-model="wPayment_gxhhr.subcom"> </el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align: center">
            <el-button type="primary" @click="wDoPayment_gxhhr_confirm()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 确认认缴共享合伙人弹框 -->
    <el-dialog
      title="认缴共享合伙人"
      :visible.sync="wPayment_gxhhr.confirm"
      width="400px"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-form ref="wPaymentForm" :model="wPayment_gxhhr" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="头像:" prop="imgHP">
              <el-avatar size="large" :src="wPayment_gxhhr.imgHP"></el-avatar>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="名称:" prop="name">
              <span>{{ wPayment_gxhhr.name }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号:" prop="phone">
              <span>{{ wPayment_gxhhr.phone }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="会员等级:" prop="level.value">
              <span>{{
                wGetPayment_gxhhrLevel(wPayment_gxhhr.level.value)
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分红股:" prop="shares">
              <span>{{ wPayment_gxhhr.shares }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否分佣:" prop="subcom">
              <span>{{ wPayment_gxhhr.subcom ? '分佣' : '不分佣' }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24" style="text-align: center">
            <el-button @click="wDoCanclePayment_gxhhr()">取消</el-button>
            <el-button type="primary" @click="wDoPayment_gxhhr()"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <!-- 配额设置弹框 -->
    <el-dialog :visible.sync="wDQuota.wIsShow" :append-to-body="true" width="50%" :close-on-click-modal="false" title="配额修改" >
      <div class="wDialog-quota">
        <div class="wDQuota-type">
          <div class="wDQType-list">
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 0 }"
              @click="wFun_doChoose_wDQType(0)"
              v-if="wIsAdmin || wAccountValue == 'jiangjiyue'"
            >
              测评积分
            </div>
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 1 }"
              @click="wFun_doChoose_wDQType(1)"
              v-if="wIsAdmin"
            >
              余额
            </div>
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 2 }"
              @click="wFun_doChoose_wDQType(2)"
              v-if="wIsAdmin"
            >
              积分
            </div>
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 3 }"
              @click="wFun_doChoose_wDQType(3)"
              v-if="wIsAdmin"
            >
              股份
            </div>
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 4 }"
              @click="wFun_doChoose_wDQType(4)"
              v-if="wIsAdmin"
            >
              业绩
            </div>
            <div
              class="wDQType-item"
              :class="{ wActive: wDQuota.wType == 5 }"
              @click="wFun_doChoose_wDQType(5)"
            >
              优惠券
            </div>
          </div>
        </div>
        <div class="wDQuota-body">
          <!-- 测评积分 -->
          <div v-show="wDQuota.wType == 0">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-assess"
                :model="wDQuota.wAssess"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="当前积分:" v-if="wDQuota.wOther">
                  <div>
                    {{ wDQuota.wOther.assessScore
                    }}<el-button
                      type="primary"
                      size="mini"
                      style="margin-left: 30px"
                      @click="wFun_doPPopup(true)"
                      >详情</el-button
                    >
                  </div>
                </el-form-item>
                <el-form-item label="变化类型:" prop="wType">
                  <el-radio-group v-model="wDQuota.wAssess.wType">
                    <el-radio label="addition">增加积分</el-radio>
                    <el-radio label="subtraction">减少积分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="变化数量:" prop="wNumber">
                  <el-input-number
                    :min="0"
                    placeholder="积分数量"
                    v-model="wDQuota.wAssess.wNumber"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="变化时间:" prop="wTime">
                  <el-date-picker
                    v-model="wDQuota.wAssess.wTime"
                    type="datetime"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="变化原因:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请备注积分变化的原因"
                    v-model="wDQuota.wAssess.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 余额 -->
          <div v-show="wDQuota.wType == 1">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-balance"
                :model="wDQuota.wBalance"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="当前余额:" v-if="wDQuota.wOther">
                  <div>{{ wDQuota.wOther.balance }}</div>
                </el-form-item>
                <el-form-item label="变化类型:" prop="wType">
                  <el-radio-group v-model="wDQuota.wBalance.wType">
                    <el-radio label="addition">增加余额</el-radio>
                    <el-radio label="subtraction">减少余额</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="变化数量:" prop="wNumber">
                  <el-input-number
                    :min="0"
                    placeholder="余额数量"
                    v-model="wDQuota.wBalance.wNumber"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="变化原因:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请备注余额变化的原因"
                    v-model="wDQuota.wBalance.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 积分 -->
          <div v-show="wDQuota.wType == 2">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-integral"
                :model="wDQuota.wIntegral"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="积分类型:" prop="wValue">
                  <el-select
                    v-model="wDQuota.wIntegral.wValue"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in wDQuota.wIntegral.wOptions"
                      :key="item.wValue"
                      :label="item.wLabel"
                      :value="item.wValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="当前积分:"
                  prop="wType"
                  v-show="wDQuota.wIntegral.wValue"
                >
                  <div
                    v-for="item in wDQuota.wIntegral.wOptions"
                    :key="item.wOther.integralId"
                    v-show="item.wOther.integralId == wDQuota.wIntegral.wValue"
                  >
                    {{ item.wOther.integralValue }}
                  </div>
                </el-form-item>
                <el-form-item label="变化类型:" prop="wType">
                  <el-radio-group v-model="wDQuota.wIntegral.wType">
                    <el-radio label="addition">增加积分</el-radio>
                    <el-radio label="subtraction">减少积分</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="变化数量:" prop="wNumber">
                  <el-input-number
                    :min="0"
                    placeholder="积分数量"
                    v-model="wDQuota.wIntegral.wNumber"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="变化原因:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请备注积分变化的原因"
                    v-model="wDQuota.wIntegral.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 股份 -->
          <div v-show="wDQuota.wType == 3">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-shares"
                :model="wDQuota.wShares"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="当前股份:" v-if="wDQuota.wOther">
                  <div>{{ wDQuota.wOther.share }}</div>
                </el-form-item>
                <el-form-item label="变化类型:" prop="wType">
                  <el-radio-group v-model="wDQuota.wShares.wType">
                    <el-radio label="addition">增加股份</el-radio>
                    <el-radio label="subtraction">减少股份</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="变化数量:" prop="wNumber">
                  <el-input-number
                    :min="0"
                    placeholder="股份数量"
                    v-model="wDQuota.wShares.wNumber"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="变化原因:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请备注股份变化的原因"
                    v-model="wDQuota.wShares.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 业绩 -->
          <div v-show="wDQuota.wType == 4">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-achievements"
                :model="wDQuota.wAchievements"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="业绩类型:" prop="wValue">
                  <el-select
                    v-model="wDQuota.wAchievements.wValue"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in wDQuota.wAchievements.wOptions"
                      :key="item.wValue"
                      :label="item.wLabel"
                      :value="item.wValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="当前业绩:"
                  prop="wType"
                  v-show="wDQuota.wAchievements.wValue"
                >
                  <div
                    :key="item.wOther.type"
                    v-for="item in wDQuota.wAchievements.wOptions"
                    v-show="item.wOther.type == wDQuota.wAchievements.wValue"
                  >
                    {{ item.wOther.performanceValue }}
                  </div>
                </el-form-item>
                <el-form-item label="变化类型:" prop="wType">
                  <el-radio-group v-model="wDQuota.wAchievements.wType">
                    <el-radio label="addition">增加业绩</el-radio>
                    <el-radio label="subtraction">减少业绩</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="变化数量:" prop="wNumber">
                  <el-input-number
                    :min="0"
                    placeholder="业绩数量"
                    v-model="wDQuota.wAchievements.wNumber"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="变化原因:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="请备注业绩变化的原因"
                    v-model="wDQuota.wAchievements.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <!-- 优惠券 -->
          <div v-show="wDQuota.wType == 5">
            <div class="wDQuota-form">
              <el-form
                ref="wDQForm-coupon"
                :model="wDQuota.wCoupon"
                label-width="100px"
                @submit.native.prevent
              >
                <el-form-item label="类型:" prop="wValue">
                  <el-select
                    v-model="wDQuota.wCoupon.wValue"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in wDQuota.wCoupon.wOptions"
                      :key="item.wValue"
                      :label="item.wLabel"
                      :value="item.wValue"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="价值(选填):"
                  prop="wCost"
                  style="width: 320px"
                >
                  <el-input
                    type="number"
                    placeholder="价值"
                    v-model="wDQuota.wCoupon.wCost"
                  >
                    <template slot="append">元</template>
                  </el-input>
                </el-form-item>
                <el-form-item label="数量:" prop="wNumber" style="width: 320px">
                  <el-input-number
                    v-model="wDQuota.wCoupon.wNumber"
                    :min="0"
                    :max="99999"
                    label="数量"
                  ></el-input-number>
                </el-form-item>
                <el-form-item label="失效时间:" prop="wTime">
                  <el-date-picker
                    v-model="wDQuota.wCoupon.wTime"
                    type="datetime"
                    placeholder="选择日期时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="备注:" prop="wTextarea">
                  <el-input
                    type="textarea"
                    :rows="4"
                    placeholder="备注"
                    v-model="wDQuota.wCoupon.wTextarea"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <div class="wDQuota-footer">
          <div class="wDQFooter-btn" @click="wFun_wDQuota_submit">提交</div>
        </div>
      </div>
    </el-dialog>

    <!-- 修改用户信息弹框 -->
    <el-dialog
      :visible.sync="wIEdit.wIsShow"
      title="信息修改"
      :fullscreen="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <div class="wMember-infoEdit">
        <el-form ref="wMIEForm-info" :model="wIEdit.wInfo">
          <el-form-item prop="wRealName">
            <div class="wMIEForm-wRealName">
              <el-input
                v-model="wIEdit.wInfo.wRealName"
                placeholder="请输入用户真实姓名"
              ></el-input>
              <el-button type="primary" @click="wFun_saveInfo('wRealName')"
                ><span>保存</span>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 测评积分 -->
    <el-dialog
      title="测评积分"
      width="800px"
      :visible.sync="wPPopup.wIsShow"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-table :data="wPPopup.wList" style="width: 100%">
        <el-table-column prop="score" label="积分" width="100">
        </el-table-column>
        <el-table-column prop="exchangeType" label="类型">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.exchangeType == 1">增加积分</div>
              <div v-if="scope.row.exchangeType == 0">减少积分</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"> </el-table-column>
        <el-table-column prop="exchangeTime" label="变化时间">
        </el-table-column>
        <el-table-column prop="createTime" label="操作时间"> </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination
          background
          :layout="wC_layout"
          :current-page="wPPopup.wPage"
          :page-size="wPPopup.wLimit"
          :total="wPPopup.wTotal"
          :page-sizes="wC_pageSizes"
          :hide-on-single-page="false"
          @current-change="wELPageCurrentChange_pointsRecords"
          @size-change="wELPageSizeChange_pointsRecords"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { regexp, date as wUtil_date } from '@/plugins/utils.js'
import wPageCrumbs from '@/components/layout/PageCrumbs.vue'
import {
  member_payment,
  member_changeReferences,
  member_addMember,
  member_getPartner,
  member_addAccessory,
  updateIdentityApi
} from '@/network/wApi.js'
import {
  wApi_member_updatePartnerAssessScore,
  wApi_member_updatePartnerBalance,
  wApi_member_queryIntegralType,
  wApi_member_updatePartnerIntegration,
  wApi_member_updatePartnerShare,
  wApi_member_queryAchievementsType,
  wApi_member_updatePartnerPerformance,
  wApi_member_updateUserInfo,
  wApi_member_queryPointsRecords,
  wApi_member_couponTypeList,
  wApi_member_addCouponByUserId,
} from '@/plugins/wApi.js'

import {
  getAchievementListApi,
  setUserCommissionApi,
  getUserCommissionApi,
} from '@/network/api.js'

export default {
  components: {
    wPageCrumbs,
  },
  data() {
    return {
      tableLoading: false,
      // 是否是超级管理员
      wIsAdmin: false,
      wAccountValue: null,

      // 修改用户身份
      dialogIdentity: false,
      formIdentity: {
        id: null,
        nickName: null,
        // realName: null,
        phone: null,
        identity: null,
      },
      optionsIdentity: [
        {label:"顾客",value:"HN"},
        {label:"会员",value:"V1"},
        {label:"VIP",value:"V2"},
        {label:"社区团长",value:"SN"},
        {label:"渠道代理",value:"CN"}
      ],

      // 筛选查询
      wScreen: {
        phone: '',
        timeGroup: '',
        level: {
          value: '',
          options: [],
        },
        state: {
          value: '',
          options: [
            {
              value: '',
              label: '全部状态',
            },
            {
              value: '0',
              label: '生效状态',
            },
            {
              value: '1',
              label: '失效状态',
            },
          ],
        },
      },

      // tab标签页
      wTabs: {
        value: 'HHRLB', // 当前选中值
        options: [
          {
            name: 'HHRLB',
            val: '会员列表',
            page: 1,
            limit: 10,
            total: 0,
            list: [],
          },
          // {
          //   name: "RJLB",
          //   val: "认缴列表",
          //   page: 1,
          //   limit: 10,
          //   total: 0,
          //   list: [],
          // },
        ],
      },

      // 认缴弹框
      wPayment: {
        isShow: false, // 是否展示
        confirm: false, // 确定弹框
        userid: null, // 用户id
        imgHP: '', // 用户头像
        name: '', // 用户昵称
        phone: '', // 用户手机号
        // 认缴等级
        level: {
          value: '',
          // 认缴值(现在写死)
          options: [
            // {
            //   id: "J",
            //   label: "VIP合伙人",
            //   val: "40000", // 股权分配
            //   money: "4000", // 认缴金额
            // },
          ],
        },
        shares: '', // 股权分配
        money: '', // 认缴金额
        integral: '', // 积分
        subcom: true, // 是否分佣
      },

      // 认缴共享合伙人弹框
      wPayment_gxhhr: {
        isShow: false, // 是否展示
        confirm: false, // 确认弹框
        imgHP: '', // 用户头像
        name: '', // 用户昵称
        phone: '', // 用户手机号
        // 认缴等级
        level: {
          value: '',
          // 认缴值(现在写死)
          options: [
            // {
            //   id: "J",
            //   label: "合伙人",
            //   val: "40000",
            // },
            // {
            //   id: "M",
            //   label: "中级合伙人",
            //   val: "60000",
            // },
            // {
            //   id: "S",
            //   label: "高级合伙人",
            //   val: "80000",
            // },
          ],
        },
        shares: '', // 股权分配
        subcom: true, // 是否分佣
      },

      // 修改推荐人弹框
      wEditTJR: {
        isShow: false, // 是否展示
        ctype: '', // 打开类型，默认为空-修改推荐人，add-代表添加会员的时候选择推荐人
        keyword: '', // 搜索关键字
        userid: null, // 需要修改的用户id
        page: 1, // 分页
        limit: 10, // 条数
        total: 0, // 总数
        list: [], // 数据
      },

      // 添加会员
      wAddMember: {
        isShow: false, // 是否展示
        form: {
          phone: '', // 手机号码
          name: '', // 昵称
          referrerId: '', // 推荐人id
          referrerNickName: '', // 推荐人昵称
          imgHP: '', // 头像
        },
      },

      // 配额设置弹框
      wDQuota: {
        wIsShow: false, // 是否显示
        wUserId: null, // 用户id
        wType: 0, // 类型。默认0-测评积分，1-余额，2-积分，3-股份,4-业绩,5-优惠券
        wOther: null, // 其他数据
        // 测评积分
        wAssess: {
          wType: 'addition', // 积分测评类型，addition-增加积分，subtraction-减少积分
          wNumber: 0, // 积分变化数量
          wTime: '', // 积分变化时间
          wTextarea: '', // 积分变化原因
        },
        // 余额
        wBalance: {
          wType: 'addition', // 余额测评类型，addition-增加余额，subtraction-减少余额
          wNumber: 0, // 余额变化数量
          wTextarea: '', // 余额变化原因
        },
        // 积分
        wIntegral: {
          wValue: null, // 积分类型选择值
          wOptions: [], // 积分类型所有值
          wType: 'addition', // 积分测评类型，addition-增加积分，subtraction-减少积分
          wNumber: 0, // 积分变化数量
          wTextarea: '', // 积分变化原因
        },
        // 股份
        wShares: {
          wType: 'addition', // 股份测评类型，addition-增加股份，subtraction-减少股份
          wNumber: 0, // 股份变化数量
          wTextarea: '', // 股份变化原因
        },
        // 业绩
        wAchievements: {
          wValue: null, // 业绩类型选择值
          wOptions: [], // 业绩类型所有值
          wType: 'addition', // 业绩类型，addition-增加业绩，subtraction-减少业绩
          wNumber: 0, // 业绩变化数量
          wTextarea: '', // 业绩变化原因
        },
        // 优惠券
        wCoupon: {
          wValue: null, // 优惠券类型选择值
          wOptions: [], // 优惠券类型所有值
          wCost: '', // 优惠券价值
          wNumber: 0, // 优惠券数量
          wTime: '', // 优惠券失效时间
          wTextarea: '', // 优惠券备注
        },
      },

      // 信息修改
      wIEdit: {
        wIsShow: false, // 是否显示
        wUserId: null, // 用户id
        // 信息
        wInfo: {
          wRealName: '', // 真实姓名
        },
      },

      // 测评积分
      wPPopup: {
        wIsShow: false,
        wPage: 0,
        wLimit: 10,
        wTotal: 0,
        wList: [],
      },

      achievementList: [], // 业绩收益分佣列表
    }
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes
    },
    // 动态计算股权分配显示值
    wC_levelSharePla() {
      let wShares = this.wPayment.level.options.find((item) => {
        return item.id == this.wPayment.level.value
      })
      if (wShares) {
        return wShares.val
      } else {
        return '股权分配'
      }
    },
    // 动态计算认缴金额
    wC_moneyPla() {
      let wShares = this.wPayment.level.options.find((item) => {
        return item.id == this.wPayment.level.value
      })
      if (wShares) {
        return wShares.money
      } else {
        return '认缴金额'
      }
    },
    // 动态计算积分显示值
    wC_integralPla() {
      let wShares = this.wPayment.level.options.find((item) => {
        return item.id == this.wPayment.level.value
      })
      if (wShares) {
        return wShares.val
      } else {
        return '积分'
      }
    },
    // 动态计算认缴共享合伙人的分红股显示值
    wC_levelSharePla_gxhhr() {
      let wShares = this.wPayment_gxhhr.level.options.find((item) => {
        return item.id == this.wPayment_gxhhr.level.value
      })
      if (wShares) {
        return wShares.val
      } else {
        return '分红股'
      }
    },
  },
  created() {
    // 判断是否是admin用户
    if (JSON.parse(localStorage.getItem('LOGININFO')).account == 'admin') {
      this.wIsAdmin = true
    }
    // 获取当前账号
    this.wAccountValue = JSON.parse(localStorage.getItem('LOGININFO')).account

    // this.getAchievementList() // 2023-09-02

    // 获取字典-认缴等级列表
    // this.wDic_getLevel();

    // 获取字典-合伙人配置列表
    // this.wDic_getPartner();

    // 获取合伙人等级数据
    this.wGetLevelList() // 2023-09-02

    // 获取合伙人列表数据
    this.wGetMemberList('HHRLB')
  },
  methods: {
    getAchievementList() {
      getAchievementListApi().then((res) => {
        this.achievementList = res
      })
    },
    /**
     * wGetLevelList
     * 获取合伙人等级数据
     */
    wGetLevelList() {
      
      // 请求函数
      this.$api.member_level({
        success: (res) => {
          console.log(res)
          
          // {
          //   value: "1",
          //   label: "火锅达人",
          // }

          let newList = [
            {
              value: '',
              label: '全部等级',
            },
          ]
          res.forEach((item) => {
            let obj = {}
            obj.value = item.identity
            obj.label = item.name
            newList.push(obj)
          })

          // 更新数据
          console.log('newList',newList)
          // this.wScreen.level.options = newList

          // 暂固定数据
          this.wScreen.level.options = [
            { label:"全部", value:"" },
            // { label:"游客", value:"TN" },
            { label:"顾客", value:"HN" },
            { label:"会员", value:"V1" },
            { label:"VIP", value:"V2" },
            {label:"社区团长",value:"SN"},
            { label:"渠道代理", value:"CN" },
          ]

        },
      })
    },
    /**
     * wResetForm
     * 重置表单数据
     */
    wResetForm() {
      this.$refs['wScreenForm'].resetFields()
    },
    /**
     * wSubmitForm
     * 提交表单数据
     */
    wSubmitForm() {
      console.log('筛选等级参数', this.wScreen.level.value)
      let { value: wTabsType } = this.wTabs
      // 列表数据
      let { wArrIndex } = this.wTabs.options.find((value, index) => {
        value['wArrIndex'] = index
        return value.name == wTabsType
      })
      // 更改当前页码数据
      this.wTabs.options[wArrIndex].page = 1
      // 获取合伙人列表数据
      this.wGetMemberList('HHRLB')
    },
    /**
     * wGetScreenValue
     * 获取筛选条件的数据
     */
    wGetScreenValue() {
      let wScreenValue = {
        phone: this.wScreen.phone,
        level: this.wScreen.level.value,
        state: this.wScreen.state.value,
        timeGroup: '',
        timeGroup_begin: '',
        timeGroup_end: '',
      }
      if (this.wScreen.timeGroup) {
        if (this.wScreen.timeGroup[0]) {
          wScreenValue.timeGroup_begin = new Date(this.wScreen.timeGroup[0])
          wScreenValue.timeGroup_begin =
            wScreenValue.timeGroup_begin.getFullYear() +
            '-' +
            (wScreenValue.timeGroup_begin.getMonth() + 1) +
            '-' +
            wScreenValue.timeGroup_begin.getDate() +
            ' ' +
            wScreenValue.timeGroup_begin.getHours() +
            ':' +
            wScreenValue.timeGroup_begin.getMinutes() +
            ':' +
            wScreenValue.timeGroup_begin.getSeconds()
        }
        if (this.wScreen.timeGroup[1]) {
          wScreenValue.timeGroup_end = new Date(this.wScreen.timeGroup[1])
          wScreenValue.timeGroup_end =
            wScreenValue.timeGroup_end.getFullYear() +
            '-' +
            (wScreenValue.timeGroup_end.getMonth() + 1) +
            '-' +
            wScreenValue.timeGroup_end.getDate() +
            ' ' +
            wScreenValue.timeGroup_end.getHours() +
            ':' +
            wScreenValue.timeGroup_end.getMinutes() +
            ':' +
            wScreenValue.timeGroup_end.getSeconds()
        }
        wScreenValue.timeGroup = `${wScreenValue.timeGroup_begin} 至 ${wScreenValue.timeGroup_end}`
      }
      console.log('wScreenValue');
      console.log(wScreenValue);
      return wScreenValue
    },
    /**
     * wDoChangeELTabs
     * tab切换
     */
    wDoChangeELTabs(tab, event) {},
    /**
     * wGetMemberList
     * 获取列表数据
     * wP_type 类别, HHRLB-合伙人列表,RJLB-认缴列表
     */
    wGetMemberList(wP_type) {
      this.tableLoading = true
      // 筛选条件
      let { phone, level, state, timeGroup_begin, timeGroup_end } =
        this.wGetScreenValue()
      // 列表数据
      let { page, limit, wArrIndex } = this.wTabs.options.find(
        (value, index) => {
          value['wArrIndex'] = index
          return value.name == wP_type
        }
      )
      switch (wP_type) {
        case 'HHRLB':
          // 合伙人列表
          // 请求参数
          let wData = {}

          if (phone) {
            wData.phone = phone
          }
          if (level) {
            wData.identity = level
          }
          if (state) {
            wData.locked = state
          }
          if (timeGroup_begin) {
            wData.startDate = timeGroup_begin
          }
          if (timeGroup_end) {
            wData.endDate = timeGroup_end
          }
          wData['pageModel.pageNo'] = page
          wData['pageModel.pageSize'] = limit
          // wData["pageModel.sortField"] = 'id';
          // wData["pageModel.sortWay"] = 'asc';

          // 请求函数
          // console.log('api',this.$api) 2023-09-02
          this.$api.member_list({
            data: wData,
            success: (res) => {
              console.log('查询结果',res)

              this.tableLoading = false

              // 数据处理
              res.records.map((item, index) => {
                item.locked = !item.locked
              })

              // 更新数据
              this.wTabs.options[wArrIndex].total = res.total
              this.wTabs.options[wArrIndex].list = res.records
            },
          })

          break

        case 'RJLB':
          // 认缴列表

          break

        default:
          break
      }
    },
    /**
     * wGetMemberBySearch
     * 获取查询的会员列表数据
     */
    wGetMemberBySearch() {
      // 请求参数
      let wData = {}
      // 查询条件
      let {
        keyword, // 搜索关键字
        page, // 分页
        limit, // 条数
      } = this.wEditTJR
      // 判断条件
      if (keyword) {
        wData.phone = keyword
      }
      wData['pageModel.pageNo'] = page
      wData['pageModel.pageSize'] = limit
      // wData["pageModel.sortField"] = 'id';
      // wData["pageModel.sortWay"] = 'asc';

      // 请求函数
      this.$api.member_list({
        data: wData,
        success: (res) => {
          console.log(res)

          // 数据处理
          res.records.map((item, index) => {})

          // 更新数据
          this.wEditTJR.total = res.total
          this.wEditTJR.list = res.records
          // 打开弹框
          this.$nextTick(() => {
            this.wEditTJR.isShow = true
          })
        },
      })
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      let { value: wTabsType } = this.wTabs
      // 列表数据
      let { wArrIndex } = this.wTabs.options.find((value, index) => {
        value['wArrIndex'] = index
        return value.name == wTabsType
      })
      // 更改当前页码数据
      this.wTabs.options[wArrIndex].page = wP_cPage
      // // 获取列表数据
      // this.wGetMemberList(wTabsType);
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberList(wTabsType)
      })
    },
    /**
     * wELPageCurrentChange_edit
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange_edit(wP_cPage) {
      // 更改当前页码数据
      this.wEditTJR.page = wP_cPage
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberBySearch()
      })
    },
    /**
     * wELPageSizeChange
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      let { value: wTabsType } = this.wTabs
      // 列表数据
      let { wArrIndex } = this.wTabs.options.find((value, index) => {
        value['wArrIndex'] = index
        return value.name == wTabsType
      })
      // 更改当前页码数据
      this.wTabs.options[wArrIndex].page = 1
      this.wTabs.options[wArrIndex].limit = wP_cPageSize
      // // 获取列表数据
      // this.wGetMemberList(wTabsType);
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberList(wTabsType)
      })
    },
    /**
     * wELPageSizeChange_edit
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange_edit(wP_cPageSize) {
      // 更改当前页码数据
      this.wEditTJR.page = 1
      this.wEditTJR.limit = wP_cPageSize
      this.$nextTick(() => {
        // 获取列表数据
        this.wGetMemberBySearch()
      })
    },
    /**
     * wELSwitch_state
     * 会员状态更改
     */
    wELSwitch_state(wP_scopeRow, wP_index) {
      let { id, locked, wArrIndex } = this.wTabs.options[wP_index].list.find(
        (value, index) => {
          value['wArrIndex'] = index
          return value.id == wP_scopeRow.id
        }
      )

      // 请求函数
      this.$api.member_editLocked({
        data: {
          id: id,
        },
        success: (res) => {
          // 更新会员状态值
          this.wTabs.options[wP_index].list[wArrIndex].locked = !res
        },
        fail: (err) => {
          // 还原会员状态值
          this.wTabs.options[wP_index].list[wArrIndex].locked = !locked
        },
      })
    },
    /**
     * wDoTableBtn
     * 表格操作按钮
     */
    wDoTableBtn(wP_scope, wP_type) {
      console.log(wP_scope, wP_type)
      switch (`${wP_type}`) {
        case `FXXQ`:
          // 分销详情
          {
            // this.$message("分销详情");
            this.$router.push({
              name: 'memberDistribution',
              query: {
                userid: wP_scope.row.id,
              },
            })
          }
          break
        case 'RJ':
          // 认缴
          {
            // 更改弹框数据
            this.wPayment.isShow = true
            this.wPayment.userid = wP_scope.row.id
            this.wPayment.imgHP = wP_scope.row.avatar
            this.wPayment.name = wP_scope.row.nickName
            this.wPayment.phone = wP_scope.row.phone
            this.wPayment.level.value = ''
            this.wPayment.shares = ''
            this.wPayment.money = ''
            // this.wPayment.integral = "";
            // this.wPayment.subcom = true;
            getUserCommissionApi({
              userId: wP_scope.row.id,
            }).then((res) => {
              if (res) {
                this.wPayment.level.value = res.commissionId
                this.wPayment.shares = res.share
                this.wPayment.money = res.paidIn
              }
            })
            // 获取字典-认缴等级列表
            // this.wDic_getLevel(wP_scope.row.id);
          }
          break

        case 'GGTJR':
          // 更改推荐人
          {
            // 缓存用户id
            this.wEditTJR.userId = wP_scope.row.id
            // 获取会员列表，并且更改推荐人
            this.wGetMemberBySearch()
          }
          break

        case 'RJGXHHR':
          // 认缴共享合伙人
          {
            // 更改弹框数据
            this.wPayment_gxhhr.isShow = true
            this.wPayment_gxhhr.imgHP = wP_scope.row.avatar
            this.wPayment_gxhhr.name = wP_scope.row.nickName
            this.wPayment_gxhhr.phone = wP_scope.row.phone
            this.wPayment_gxhhr.level.value = ''
            this.wPayment_gxhhr.shares = ''
            this.wPayment_gxhhr.subcom = true
          }
          break

        case 'PESZ':
          // 配额设置
          {
            this.wDQuota.wUserId = wP_scope.row.id
            this.wDQuota.wType = 0
            this.wDQuota.wIsShow = true
            this.wDQuota.wOther = wP_scope.row
            this.$nextTick(() => {
              this.$refs['wDQForm-assess'].resetFields()
            })
            // 方法-查询积分类型
            this.wFun_queryIntegralType(this.wDQuota.wUserId)
            // 方法-查询业绩类型
            this.wFun_queryAchievementsType(this.wDQuota.wUserId)
            // 方法-查询优惠券类型
            this.wFun_queryCouponType()
          }
          break

        case 'XGZL':
          // 修改资料
          {
            this.wIEdit.wIsShow = true
            this.wIEdit.wUserId = wP_scope.row.id
            // 赋初值
            this.wIEdit.wInfo.wRealName = wP_scope.row.realName
          }
          break

        default:
          this.$message('功能待开发~')
          break
      }
    },
    /**
     * 修改用户身份
    */
    changeIdentity(e){
      this.formIdentity.id = e.row.id
      this.formIdentity.nickName = e.row.nickName
      this.formIdentity.phone = e.row.phone
      // this.formIdentity.realName = e.row.realName
      // 身份name转换value
      switch (e.row.identityName) {
        case "顾客":
          this.formIdentity.identity = "HN"
          break;
        case "会员":
          this.formIdentity.identity = "V1"
          break;
        case "VIP":
          this.formIdentity.identity = "V2"
          break;
        case "社区团长":
          this.formIdentity.identity = "SN"
          break;
        case "渠道代理":
          this.formIdentity.identity = "CN"
          break;
        default:
          break;
      }
      this.dialogIdentity = true
    },
    updateIdentityBtn(){
      const data = {
        userId:this.formIdentity.id,
        identity:this.formIdentity.identity
      }
      updateIdentityApi(data).then(res => {
        if (res == '修改成功') {
          this.$message.success("修改成功")
          this.dialogIdentity = false
        }
      })
    },
    /**
     * wELSelect_change
     * 认缴弹框下拉选择时候重置股权分配数据和积分数据
     */
    wELSelect_change() {
      // let wShares = this.wPayment.level.options.find(item => {
      //   return item.id == this.wPayment.level.value;
      // });
      // this.wPayment.shares = wShares.val;
      // this.wPayment.money = wShares.money;
    },

    /**
     * wELSelect_change_gxhhr
     * 认缴共享合伙人弹框下拉选择时候重置分红股数据
     */
    wELSelect_change_gxhhr() {
      let wShares = this.wPayment_gxhhr.level.options.find((item) => {
        return item.id == this.wPayment_gxhhr.level.value
      })
      this.wPayment_gxhhr.shares = wShares.val
    },

    /**
     * wDoPayment_confirm
     * 提交认缴弹框确认弹框
     */
    wDoPayment_confirm() {
      // 请求数据
      let wData = {}
      // 表单数据
      let {
        userid: wP_userid, // 用户id
        phone: wP_phone,
        level: { value: wP_identity },
        shares: wP_shares,
        money: wP_money, // 认缴金额
        // integral: wP_integral, // 积分
        // subcom: wP_subcom // 是否分佣
      } = this.wPayment
      // 会员id
      if (!wP_userid) {
        this.$message({
          message: '系统繁忙，请稍后再试~',
          type: 'warning',
        })
        console.log('userid不存在~')
        return false
      }
      wData.userId = wP_userid
      wData.phone = wP_phone
      // 会员等级
      if (!wP_identity) {
        this.$message({
          message: '请选择会员等级',
          type: 'warning',
        })
        return false
      }
      wData.identity = wP_identity
      // 股份分配
      if (!wP_shares) {
        this.$message({
          message: '请输入股份分配',
          type: 'warning',
        })
        return false
      }
      wData.share = wP_shares
      // 认缴金额
      if (!wP_money) {
        this.$message({
          message: '请输入认缴金额',
          type: 'warning',
        })
        return false
      }
      wData.money = wP_money
      // // 积分
      // if (!wP_integral) {
      //   this.$message({
      //     message: "请输入积分",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // wData.vipIntegral = wP_integral;
      // 是否分佣
      // wData.isCommission = wP_subcom ? 1 : 0;

      // 打开确定弹框
      this.wPayment.confirm = true
    },
    /**
     * wDoCanclePayment
     * 取消确认认缴弹框
     */
    wDoCanclePayment() {
      this.wPayment.confirm = false
    },

    // 动态获取等级的值
    wGetPaymentLevel(wP_level) {
      // let {
      //   level: { options: wl_options }
      // } = this.wPayment;

      // for (let i = 0; i < wl_options.length; i++) {
      //   if (wP_level == wl_options[i].id) {
      //     return wl_options[i].label;
      //   }
      // }

      for (let i = 0; i < this.achievementList.length; i++) {
        if (wP_level == this.achievementList[i].id) {
          return this.achievementList[i].name
        }
      }
    },
    /**
     * wDoPayment
     * 提交认缴弹框
     */
    wDoPayment() {
      // 开启加载弹框
      const wLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 请求数据
      let wData = {}
      // 表单数据
      let {
        userid: wP_userid,
        phone: wP_phone,
        level: { value: wP_identity },
        shares: wP_shares,
        money: wP_money, // 认缴金额
        // integral: wP_integral, // 积分
        // subcom: wP_subcom // 是否分佣
      } = this.wPayment
      // 会员id
      if (!wP_userid) {
        this.$message({
          message: '系统繁忙，请稍后再试~',
          type: 'warning',
        })
        console.log('userid不存在~')
        return false
      }
      wData.userId = wP_userid
      // wData.phone = wP_phone;
      // 会员等级
      if (!wP_identity) {
        this.$message({
          message: '请选择会员等级',
          type: 'warning',
        })
        return false
      }
      wData.commissionId = wP_identity
      wData.atype = 1
      // 股份分配
      if (!wP_shares) {
        this.$message({
          message: '请输入股份分配',
          type: 'warning',
        })
        return false
      }
      wData.share = wP_shares
      // 认缴金额
      if (!wP_money) {
        this.$message({
          message: '请输入认缴金额',
          type: 'warning',
        })
        return false
      }
      wData.paidIn = wP_money
      // // 积分
      // if (!wP_integral) {
      //   this.$message({
      //     message: "请输入积分",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // wData.vipIntegral = wP_integral;
      // 是否分佣
      // wData.isCommission = wP_subcom ? 1 : 0;
      console.log(wData)
      // 请求函数
      setUserCommissionApi(wData)
        .then((res) => {
          // 关闭加载框
          wLoading.close()

          this.$message({
            message: '认缴成功',
            type: 'success',
          })
          // 获取合伙人列表数据
          this.wGetMemberList('HHRLB')
          // 关闭弹框
          this.wPayment.isShow = false
          this.wPayment.level.value = ''
          this.wPayment.shares = ''
          this.wPayment.integral = ''
          this.wPayment.subcom = true

          // 关闭确定弹框
          this.wPayment.confirm = false
        })
        .catch((err) => {
          // 关闭加载框
          wLoading.close()
        })
    },

    /**
     * wDoPayment_gxhhr_confirm
     * 提交确认认缴共享合伙人弹框
     */
    wDoPayment_gxhhr_confirm() {
      // 请求数据
      let wData = {}
      // 表单数据
      let {
        phone: wP_phone,
        level: { value: wP_identity },
        shares: wP_shares,
        subcom: wP_subcom, // 是否分佣
      } = this.wPayment_gxhhr
      wData.phone = wP_phone
      console.log(wP_identity)
      // 会员等级
      if (!wP_identity) {
        this.$message({
          message: '请选择会员等级',
          type: 'warning',
        })
        return false
      }
      wData.id = wP_identity
      // 分红股
      if (!wP_shares) {
        this.$message({
          message: '请输入分红股',
          type: 'warning',
        })
        return false
      }
      wData.share = wP_shares
      // // // 积分
      // if (!wP_integral) {
      //   this.$message({
      //     message: "请输入积分",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // wData.vipIntegral = wP_integral;
      // 是否分佣
      wData.isCommission = wP_subcom ? 1 : 0

      // 打开确认弹框
      this.wPayment_gxhhr.confirm = true

      // 请求函数
      // member_addAccessory(wData).then((res) => {
      //   this.$message({
      //     message: "认缴共享合伙人成功",
      //     type: "success",
      //   });
      //   // 获取合伙人列表数据
      //   this.wGetMemberList("HHRLB");
      //   // 关闭弹框
      //   this.wPayment_gxhhr.isShow = false;
      //   this.wPayment_gxhhr.level.value = "";
      //   this.wPayment_gxhhr.shares = "";
      //   this.wPayment_gxhhr.subcom = true;
      // });
    },
    /**
     * wDoCanclePayment_gxhhr
     * 取消共享合伙人确认认缴弹框
     */
    wDoCanclePayment_gxhhr() {
      this.wPayment_gxhhr.confirm = false
    },

    // 动态获取等级的值
    wGetPayment_gxhhrLevel(wP_level) {
      let {
        level: { options: wl_options },
      } = this.wPayment_gxhhr

      for (let i = 0; i < wl_options.length; i++) {
        if (wP_level == wl_options[i].id) {
          return wl_options[i].label
        }
      }
    },

    /**
     * wDoPayment_gxhhr
     * 提交认缴共享合伙人弹框
     */
    wDoPayment_gxhhr() {
      // 开启加载弹框
      const wLoading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 请求数据
      let wData = {}
      // 表单数据
      let {
        phone: wP_phone,
        level: { value: wP_identity },
        shares: wP_shares,
        subcom: wP_subcom, // 是否分佣
      } = this.wPayment_gxhhr
      wData.phone = wP_phone
      console.log(wP_identity)
      // 会员等级
      if (!wP_identity) {
        this.$message({
          message: '请选择会员等级',
          type: 'warning',
        })
        return false
      }
      wData.id = wP_identity
      // 分红股
      if (!wP_shares) {
        this.$message({
          message: '请输入分红股',
          type: 'warning',
        })
        return false
      }
      wData.share = wP_shares
      // // // 积分
      // if (!wP_integral) {
      //   this.$message({
      //     message: "请输入积分",
      //     type: "warning",
      //   });
      //   return false;
      // }
      // wData.vipIntegral = wP_integral;
      // 是否分佣
      wData.isCommission = wP_subcom ? 1 : 0

      // 请求函数
      member_addAccessory(wData)
        .then((res) => {
          // 关闭加载框
          wLoading.close()

          this.$message({
            message: '认缴共享合伙人成功',
            type: 'success',
          })
          // 获取合伙人列表数据
          this.wGetMemberList('HHRLB')
          // 关闭弹框
          this.wPayment_gxhhr.isShow = false
          this.wPayment_gxhhr.level.value = ''
          this.wPayment_gxhhr.shares = ''
          this.wPayment_gxhhr.subcom = true
        })
        .catch((err) => {
          // 关闭加载框
          wLoading.close()
        })
    },

    /**
     * wETJRFSerachBtn
     * 执行搜索操作
     */
    wETJRFSerachBtn() {
      // 更改当前页码数据
      this.wEditTJR.page = 1
      this.$nextTick(() => {
        // 获取搜索列表数据
        this.wGetMemberBySearch()
      })
    },

    /**
     * wETJRFSerachChoice
     * 选择推荐人接口
     */
    wETJRFSerachChoice(wP_scope) {
      // 获取参数
      let { userId, ctype } = this.wEditTJR
      let {
        row: { id: wE_userid, nickName: wE_nickName },
      } = wP_scope

      if (ctype == 'add') {
        this.wAddMember.form.referrerId = wE_userid
        this.wAddMember.form.referrerNickName = wE_nickName
        // 关闭弹框，重新获取列表数据
        this.wEditTJR.isShow = false
      } else {
        this.$confirm('此操作将选择此人为推荐人, 是否继续?', '选择推荐人', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // 请求函数
            member_changeReferences({
              superiorId: wE_userid,
              userId: userId,
            }).then((res) => {
              this.$message.success('更改成功!')
              // 关闭弹框，重新获取列表数据
              this.wEditTJR.isShow = false
              // 获取合伙人列表数据
              this.wGetMemberList('HHRLB')
            })
          })
          .catch(() => {})
      }
    },

    /**
     * 头像上传
     */
    // 获取图片上传的地址
    wGetUploadImgPath() {
      return `${process.env.VUE_APP_URE}/file/uploadFile`
    },
    handleAvatarSuccess(res, file) {
      this.wAddMember.form.imgHP = res.data
    },
    beforeAvatarUpload(file) {
      let isImg = false
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      isImg = isJPG || isPNG ? true : false
      const isLt2M = file.size / 1024 / 1024 < 20

      if (!isImg) {
        this.$message.error('上传头像图片只能是 JPG,PNG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 20MB!')
      }
      return isImg && isLt2M
    },
    wDoAvatarRemove() {
      this.wAddMember.form.imgHP = ''
    },

    /**
     * wDoAddMember
     * 添加会员
     */
    wDoAddMember() {
      // 请求参数
      let wData = {}

      // 获取值
      let {
        form: {
          phone: wA_phone, // 手机号码
          name: wA_name, // 昵称
          referrerId: wA_referrerId, // 推荐人id
          referrerNickName: wA_referrerNickName, // 推荐人昵称
          imgHP: wA_imgHP, // 头像
        },
      } = this.wAddMember

      if (!regexp.phone(wA_phone)) {
        this.$message.error('手机号码格式不正确!')
        return
      } else {
        wData.phone = wA_phone
      }
      if (wA_name) {
        wData.nickName = wA_name
      }
      if (wA_referrerId) {
        wData.superiorId = wA_referrerId
      }
      if (wA_imgHP) {
        wData.avatar = wA_imgHP
      }

      this.$confirm('此操作将添加新会员, 是否继续?', '添加会员', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 请求函数
          member_addMember(wData).then((res) => {
            let { value: wTabsType } = this.wTabs
            // 列表数据
            let { wArrIndex } = this.wTabs.options.find((value, index) => {
              value['wArrIndex'] = index
              return value.name == wTabsType
            })
            // 更改当前页码数据
            this.wTabs.options[wArrIndex].page = 1
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')

            // 弹框提示
            this.$message.success('会员添加成功!')
            // 关闭弹框
            this.wAddMember.isShow = false
          })
        })
        .catch(() => {})
    },

    /**
     * wDoOpenAddMemberFrame
     * 打开添加会员弹框
     */
    wDoOpenAddMemberFrame() {
      // 打开弹框
      this.wAddMember.isShow = true
      // 清楚默认数据
      this.wAddMember.form.phone = ''
      this.wAddMember.form.phone = '' // 手机号码
      this.wAddMember.form.name = '' // 昵称
      this.wAddMember.form.referrerId = '' // 推荐人id
      this.wAddMember.form.referrerNickName = '' // 推荐人昵称
      this.wAddMember.form.imgHP = '' // 头像
    },

    /**
     * wDoChoiceReferrer
     * 打开选择推荐人列表
     */
    wDoChoiceReferrer() {
      // 缓存用户id
      this.wEditTJR.ctype = 'add'
      // 获取会员列表，并且更改推荐人
      this.wGetMemberBySearch()
    },

    /**
     * wDic_getLevel
     * 认缴等级列表
     */
    wDic_getLevel(wP_userid) {
      return new Promise((resolve, reject) => {
        // 请求参数
        member_getPartner({
          userId: wP_userid,
        })
          .then((res) => {
            // 返回值
            let resData = res

            // 数据处理
            let newArr = []
            resData.forEach((item) => {
              let obj = {}
              obj.id = item.identity
              obj.label = item.name
              obj.val = item.share
              obj.money = item.value

              // 添加数据
              newArr.push(obj)
            })

            // 更新数据
            this.wPayment.level.options = newArr

            resolve('success')
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * wDic_getPartner
     * 合伙人配置列表
     */
    wDic_getPartner() {
      return new Promise((resolve, reject) => {
        // 请求参数
        member_getPartner()
          .then((res) => {
            // 返回值
            let resData = res

            // 数据处理
            let newArr = []
            resData.forEach((item) => {
              let obj = {}
              obj.id = item.id
              obj.label = item.name
              obj.val = item.share

              // 添加数据
              newArr.push(obj)
            })

            // 更新数据
            this.wPayment_gxhhr.level.options = newArr

            resolve('success')
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * wDoEnter_keywords
     * 关键字搜索回车事件
     */
    wDoEnter_keywords() {
      let { value: wTabsType } = this.wTabs
      // 列表数据
      let { wArrIndex } = this.wTabs.options.find((value, index) => {
        value['wArrIndex'] = index
        return value.name == wTabsType
      })
      // 更改当前页码数据
      this.wTabs.options[wArrIndex].page = 1
      // 获取合伙人列表数据
      this.wGetMemberList('HHRLB')
    },

    /**
     * wchange_select
     * 合伙人等级切换
     */
    wchange_select() {
      let { value: wTabsType } = this.wTabs
      // 列表数据
      let { wArrIndex } = this.wTabs.options.find((value, index) => {
        value['wArrIndex'] = index
        return value.name == wTabsType
      })
      // 更改当前页码数据
      this.wTabs.options[wArrIndex].page = 1
      // 获取合伙人列表数据
      this.wGetMemberList('HHRLB')
    },

    /**
     * wFun_doChoose_wDQType
     * 方法-配额设置弹框-列表选择
     * @param {Blooean} wP_type 配额设置的类型
     */
    wFun_doChoose_wDQType(wP_type) {
      this.wDQuota.wType = wP_type
      this.$nextTick(() => {
        this.$refs['wDQForm-assess'].resetFields()
        this.$refs['wDQForm-balance'].resetFields()
        this.$refs['wDQForm-integral'].resetFields()
        this.$refs['wDQForm-shares'].resetFields()
        this.$refs['wDQForm-achievements'].resetFields()
        this.$refs['wDQForm-coupon'].resetFields()
      })
    },

    /**
     * wFun_wDQuota_submit
     * 方法-配额设置弹框-提交数据
     */
    wFun_wDQuota_submit() {
      // 请求参数
      let [
        wData,
        {
          wDQuota: {
            wUserId,
            wType,
            wAssess,
            wBalance,
            wIntegral,
            wShares,
            wAchievements,
            wCoupon,
          },
        },
      ] = [{}, this]

      // 用户id
      if (!wUserId) {
        this.$message.error('请选择用户再操作!')
        return
      } else {
        wData.userId = wUserId
      }

      // 测评数据
      if (wType == 0) {
        let [
          {
            wType: wType_assess,
            wNumber: wNumber_assess,
            wTime: wTime_assess,
            wTextarea: wTextarea_assess,
          },
        ] = [wAssess]

        // 修改类型 0.减少 1.增加 ——积分测评类型，addition-增加积分，subtraction-减少积分
        if (wType_assess == 'addition') {
          wData.exchangeType = 1
        } else if (wType_assess == 'subtraction') {
          wData.exchangeType = 0
        } else {
          this.$message.warning('请选择积分变化类型!')
          return
        }

        // 变化数量
        if (parseFloat(wNumber_assess) > 0) {
          wData.score = parseFloat(wNumber_assess)
        } else {
          this.$message.warning('请输入正确的积分变化数量!')
          return
        }

        // 变化时间
        if (wTime_assess) {
          wData.exchangeTime = wTime_assess
        } else {
          this.$message.warning('请选择积分变化时间!')
          return
        }

        // 变化原因
        if (wTextarea_assess.trim()) {
          wData.remark = wTextarea_assess.trim()
        } else {
          this.$message.warning('请输入积分变化的原因!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 请求函数
        wApi_member_updatePartnerAssessScore({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
      // 余额
      if (wType == 1) {
        let [
          {
            wType: wType_balance,
            wNumber: wNumber_balance,
            wTextarea: wTextarea_balance,
          },
        ] = [wBalance]

        // 修改类型 0.减少 1.增加 ——余额类型，addition-增加余额，subtraction-减少余额
        if (wType_balance == 'addition') {
          wData.exchangeType = 1
        } else if (wType_balance == 'subtraction') {
          wData.exchangeType = 0
        } else {
          this.$message.warning('请选择余额变化类型!')
          return
        }

        // 变化数量
        if (parseFloat(wNumber_balance) > 0) {
          wData.money = parseFloat(wNumber_balance)
        } else {
          this.$message.warning('请输入正确的余额变化数量!')
          return
        }

        // 变化原因
        if (wTextarea_balance.trim()) {
          wData.remark = wTextarea_balance.trim()
        } else {
          this.$message.warning('请输入余额变化的原因!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 请求函数
        wApi_member_updatePartnerBalance({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
      // 积分
      if (wType == 2) {
        let [
          {
            wValue: wValue_integral,
            wType: wType_integral,
            wNumber: wNumber_integral,
            wTextarea: wTextarea_integral,
          },
        ] = [wIntegral]

        // 积分类型 1-vip积分
        if (wValue_integral == null) {
          this.$message.warning('请选择积分类型!')
          return
        } else {
          wData.integralId = wValue_integral
        }

        // 修改类型 0.减少 1.增加 ——积分类型，addition-增加积分，subtraction-减少积分
        if (wType_integral == 'addition') {
          wData.exchangeType = 1
        } else if (wType_integral == 'subtraction') {
          wData.exchangeType = 0
        } else {
          this.$message.warning('请选择积分变化类型!')
          return
        }

        // 变化数量
        if (parseFloat(wNumber_integral) > 0) {
          wData.integral = parseFloat(wNumber_integral)
        } else {
          this.$message.warning('请输入正确的积分变化数量!')
          return
        }

        // 变化原因
        if (wTextarea_integral.trim()) {
          wData.remark = wTextarea_integral.trim()
        } else {
          this.$message.warning('请输入积分变化的原因!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 请求函数
        wApi_member_updatePartnerIntegration({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
      // 股份
      if (wType == 3) {
        let [
          {
            wType: wType_shares,
            wNumber: wNumber_shares,
            wTextarea: wTextarea_shares,
          },
        ] = [wShares]

        // 修改类型 0.减少 1.增加 ——股份，addition-增加股份，subtraction-减少股份
        if (wType_shares == 'addition') {
          wData.exchangeType = 1
        } else if (wType_shares == 'subtraction') {
          wData.exchangeType = 0
        } else {
          this.$message.warning('请选择股份变化类型!')
          return
        }

        // 变化数量
        if (parseFloat(wNumber_shares) > 0) {
          wData.share = parseFloat(wNumber_shares)
        } else {
          this.$message.warning('请输入正确的股份变化数量!')
          return
        }

        // 变化原因
        if (wTextarea_shares.trim()) {
          wData.remark = wTextarea_shares.trim()
        } else {
          this.$message.warning('请输入股份变化的原因!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 请求函数
        wApi_member_updatePartnerShare({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
      // 业绩
      if (wType == 4) {
        let [
          {
            wValue: wValue_achievements,
            wType: wType_achievements,
            wNumber: wNumber_achievements,
            wTextarea: wTextarea_achievements,
          },
        ] = [wAchievements]

        // 业绩类型 1-vip业绩
        if (wValue_achievements == null) {
          this.$message.warning('请选择业绩类型!')
          return
        } else {
          wData.type = wValue_achievements
        }

        // 修改类型 0.减少 1.增加 ——业绩类型，addition-增加业绩，subtraction-减少业绩
        if (wType_achievements == 'addition') {
          wData.exchangeType = 1
        } else if (wType_achievements == 'subtraction') {
          wData.exchangeType = 0
        } else {
          this.$message.warning('请选择业绩变化类型!')
          return
        }

        // 变化数量
        if (parseFloat(wNumber_achievements) > 0) {
          wData.performance = parseFloat(wNumber_achievements)
        } else {
          this.$message.warning('请输入正确的业绩变化数量!')
          return
        }

        // 变化原因
        if (wTextarea_achievements.trim()) {
          wData.remark = wTextarea_achievements.trim()
        } else {
          this.$message.warning('请输入业绩变化的原因!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })
        // 请求函数
        wApi_member_updatePartnerPerformance({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
      // 优惠券
      if (wType == 5) {
        let [
          {
            wValue: wValue_coupon,
            wCost: wCost_coupon,
            wNumber: wNumber_coupon,
            wTime: wTime_coupon,
            wTextarea: wTextarea_coupon,
          },
        ] = [wCoupon]

        // 优惠券类型
        if (wValue_coupon == null) {
          this.$message.warning('请选择优惠券类型!')
          return
        } else {
          wData.verificationConfigId = wValue_coupon
        }

        // 优惠券价值
        if (wCost_coupon.trim() != '') {
          wData.money = wCost_coupon.trim()
        }

        // 变化数量
        if (parseFloat(wNumber_coupon) > 0) {
          wData.num = parseFloat(wNumber_coupon)
        } else {
          this.$message.warning('请输入正确的优惠券数量!')
          return
        }

        // 失效时间
        if (wTime_coupon) {
          wData.validDate = wUtil_date.parseTime(wTime_coupon)
        } else {
          this.$message.warning('请选择优惠券失效时间!')
          return
        }

        // 备注
        if (wTextarea_coupon.trim()) {
          wData.remark = wTextarea_coupon.trim()
        } else {
          this.$message.warning('请输入备注!')
          return
        }

        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        })

        // 请求函数
        wApi_member_addCouponByUserId({
          data: JSON.stringify(wData),
        })
          .then((res) => {
            loading.close()
            this.$message.success('操作成功!')
            this.wDQuota.wIsShow = false
            // 获取合伙人列表数据
            this.wGetMemberList('HHRLB')
          })
          .catch((err) => {
            loading.close()
          })
      }
    },

    /**
     * wFun_queryIntegralType
     * 方法-查询积分类型
     * @param {Number} wP_userId 用户id
     */
    wFun_queryIntegralType(wP_userId) {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_queryIntegralType({
          data: {
            userId: wP_userId,
          },
        })
          .then((res) => {
            let [{ data: resData }] = [res]
            let wOptions = []
            for (let i = 0; i < resData.length; i++) {
              let wObj = {}
              wObj.wLabel = resData[i].integralName
              wObj.wValue = resData[i].integralId
              wObj.wOther = resData[i]
              wOptions.push(wObj)
            }
            this.wDQuota.wIntegral.wOptions = wOptions
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * wFun_queryAchievementsType
     * 方法-查询业绩类型
     * @param {Number} wP_userId 用户id
     */
    wFun_queryAchievementsType(wP_userId) {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_queryAchievementsType({
          data: {
            userId: wP_userId,
          },
        })
          .then((res) => {
            let [{ data: resData }] = [res]
            let wOptions = []
            for (let i = 0; i < resData.length; i++) {
              let wObj = {}
              wObj.wLabel = resData[i].typeName
              wObj.wValue = resData[i].type
              wObj.wOther = resData[i]
              wOptions.push(wObj)
            }
            this.wDQuota.wAchievements.wOptions = wOptions
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    // 方法-查询优惠券类型
    wFun_queryCouponType() {
      return new Promise((resolve, reject) => {
        // 请求函数
        wApi_member_couponTypeList()
          .then((res) => {
            let [{ data: resData }] = [res]
            let wOptions = []
            for (let i = 0; i < resData.length; i++) {
              let wObj = {}
              wObj.wLabel = resData[i].name
              wObj.wValue = resData[i].id
              wObj.wOther = resData[i]
              wOptions.push(wObj)
            }
            this.wDQuota.wCoupon.wOptions = wOptions
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /**
     * wFun_saveInfo
     * 方法-保存数据
     * @param {String} wP_type 类型
     */
    wFun_saveInfo(wP_type) {
      let [wData, { wUserId, wInfo }] = [{}, this.wIEdit]

      // 用户id
      if (!wUserId) {
        this.$message.error('请选择操作用户！')
        return
      } else {
        wData.id = wUserId
      }
      // 根据不同的类型添加不同的参数
      switch (wP_type) {
        case 'wRealName':
          // 真实姓名
          {
            if (wInfo.wRealName.trim() == '') {
              this.$message.error('请输入用户真实姓名！')
              return
            } else {
              wData.realName = wInfo.wRealName.trim()
            }
          }
          break

        default:
          break
      }

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      // 请求函数
      wApi_member_updateUserInfo({
        data: JSON.stringify(wData),
      })
        .then((res) => {
          loading.close()
          this.$message.success('操作成功!')
          this.wIEdit.wIsShow = false
          // 获取合伙人列表数据
          this.wGetMemberList('HHRLB')
        })
        .catch((err) => {
          loading.close()
        })
    },

    /**
     * wFun_doPPopup
     * 方法-操作测评积分弹框
     * @param {Boolean} wP_truthy 真值，true-打开弹框，false-关闭弹框
     */
    wFun_doPPopup(wP_truthy) {
      this.wPPopup.wIsShow = wP_truthy ? true : false
      this.wPPopup.wPage = 1
      this.wPPopup.wList = []

      this.$nextTick(() => {
        // 方法-查询测评积分记录
        this.wFun_queryPointsRecords()
      })
    },

    /**
     * wFun_queryPointsRecords
     * 方法-查询测评积分记录
     * @param {Boolean} wP_truthy 真值，true-查询第一页数据，false,查询下一页数据
     */
    wFun_queryPointsRecords(wP_truthy) {
      let [
        wData,
        {
          wDQuota: { wUserId },
          wPPopup: { wPage, wLimit, wTotal, wList },
        },
      ] = [{}, this]

      if (wUserId) {
        wData.userId = wUserId
      }

      // 添加分页参数
      wData.pageModel = {}
      wData.pageModel.pageNo = wP_truthy ? 1 : parseInt(wPage)
      wData.pageModel.pageSize = wLimit
      wData.pageModel.sortField = 'exchangeTime,createTime'
      wData.pageModel.sortWay = 'desc,desc'

      wApi_member_queryPointsRecords({
        data: JSON.stringify(wData),
      })
        .then((res) => {
          let [{ total: wResTotal, records: wResRecords }] = [res.data]
          // 更新数据
          this.wPPopup.wPage = wP_truthy ? 1 : parseInt(wPage)
          this.wPPopup.wTotal = wResTotal
          this.wPPopup.wList = wResRecords
        })
        .catch((err) => {})
    },

    /**
     * wELPageCurrentChange_pointsRecords
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange_pointsRecords(wP_cPage) {
      this.wPPopup.wPage = wP_cPage
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryPointsRecords()
      })
    },
    /**
     * wELPageSizeChange_pointsRecords
     * 修改分页条数
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange_pointsRecords(wP_cPageSize) {
      this.wPPopup.wLimit = wP_cPageSize
      this.$nextTick(() => {
        // 方法-查询表格数据
        this.wFun_queryPointsRecords(true)
      })
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/css/publicStyle.less';

// 筛选查询
.wML-screen {
  display: block;
  width: 100%;
  user-select: none;
  margin-bottom: 20px;

  // 筛选列表
  .wMLS-list {
    display: block;
    width: 100%;

    // 业绩金额
    .wMLS-moneyGroup {
      display: flex;
    }
  }
}

// 会员数据列表
.wML-member {
  display: block;
  width: 100%;
  position: relative;

  // 添加会员按钮
  .wML-addBtn {
    position: absolute;
    top: 4px;
    right: 10px;
  }

  .wMLM-block {
    display: block;
    width: 100%;
    box-sizing: border-box;

    // table表
    .wMLM-table {
      display: block;
      width: 100%;
    }

    // 分页
    .wMLM-page {
      display: block;
      width: 100%;
      margin-top: 20px;
      text-align: center;
    }
  }
}

.wETJRFTable-user {
  display: flex;
  align-items: center;

  .wETJRFTable-userName {
    margin-left: 10px;
  }
}

// 头像上传
.wAvatar-imgBox {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.wAvatar-imgBox:hover {
  // border-color: #409eff;
  border-color: @wColor_theme;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.wAvatar-imgBox {
  position: relative;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.wAvatar-imgDel {
  display: none;
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  top: 0;
  right: 0;
  z-index: 10;
  color: #000000;
}
.wAvatar-imgBox:hover .wAvatar-imgDel {
  display: block;
  background-color: rgba(255, 255, 255, 0.3);
  color: @wColor_theme;
}

.wAddMemberFrame-btnGroup {
  display: block;
  text-align: center;
}

// 配额设置弹框
.wDialog-quota {
  display: block;
  width: 100%;
  border-top: 2px solid #f3f3f3;
  padding-top: 10px;
  box-sizing: border-box;
  margin-top: -30px;

  .wDQuota-type {
    display: flex;
    width: 100%;
    align-items: center;

    .wDQType-list {
      flex: none;
      display: flex;
      align-items: center;

      .wDQType-item {
        flex: none;
        width: 80px;
        height: 40px;
        background-color: #eeeeee;
        border: 1px solid #dddddd;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        color: #666666;
        line-height: 22px;

        &.wActive,
        &:hover {
          background-color: #ff6321;
          border: 1px solid #ff6321;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }

  .wDQuota-body {
    display: block;
    width: 100%;
    padding-top: 30px;
    box-sizing: border-box;
  }
}

.wDQuota-footer {
  display: flex;
  justify-content: center;
  align-items: center;

  .wDQFooter-btn {
    flex: none;
    width: 151px;
    height: 40px;
    background: #ff6321;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    line-height: 22px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;

    &:active {
      opacity: 0.8;
      -webkit-transform: translate(2px, 2px);
      -moz-transform: translate(2px, 2px);
      -ms-transform: translate(2px, 2px);
      -o-transform: translate(2px, 2px);
      transform: translate(2px, 2px);
    }
  }
}

// 信息修改弹框
.wMember-infoEdit {
  display: block;
  width: 100%;

  .wMIEForm-wRealName {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
