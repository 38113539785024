<template>
  <div class="container">
    <div class="box">
      <div class="header">
        <el-button
          type="primary"
          @click="addBtn"
        >添加</el-button>
      </div>
      <div class="list">
        <el-table
          :data="dataList"
          border
          style="width: 100%"
        >
          <el-table-column
            label="分佣名称"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="直推比例"
            prop="direct"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.direct + '%' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="间推比例"
            prop="indirect"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.indirect + '%' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="管理奖比例"
            prop="interior"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.interior + '%' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="tableBtn(scope.row, 'edit')"
              ><span>修改</span>
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="tableBtn(scope.row, 'delete')"
              ><span>删除</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      title="分佣管理"
      :visible.sync="addEditShow"
      width="500px"
      append-to-body
      @close="dialogClose()"
    >
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item
              label="分佣名称:"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                placeholder="分佣名称"
                style="width: 100%; max-width: 200px;"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="直推比例:"
              prop="direct"
            >
              <el-input-number
                v-model="form.direct"
                :min="0"
                :max="30"
                :precision="2"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="间推比例:"
              prop="indirect"
            >
              <el-input-number
                v-model="form.indirect"
                :min="0"
                :max="30"
                :precision="2"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="管理奖比例:"
              prop="interior"
            >
              <el-input-number
                v-model="form.interior"
                :min="0"
                :max="30"
                :precision="2"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col
            :span="24"
            style="text-align: center"
          >
            <el-button
              type="primary"
              @click="confirmBtn()"
            >保存</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getAchievementListApi,
  addAchievementApi,
  deleteAchievementApi,
  editAchievementApi
} from "@/network/api.js";
export default {
  name: "ConfigAchievement",
  data() {
    return {
      dataList: [],

      addEditShow: false,
      form: {
        id: undefined,
        name: "",
        direct: 0,
        indirect: 0,
        interior: 0
      },
      rules: {
        name: [
          { required: true, message: "请输入分佣名称", trigger: "change" }
        ],
        direct: [
          { required: true, message: "请输入直推比例", trigger: "change" }
        ],
        indirect: [
          { required: true, message: "请输入间推比例", trigger: "change" }
        ],
        interior: [
          { required: true, message: "请输入管理奖比例", trigger: "change" }
        ]
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    tableBtn(data, type) {
      switch (type) {
        case "edit":
          {
            this.form.id = data.id;
            this.form.name = data.name;
            this.form.direct = data.direct;
            this.form.indirect = data.indirect;
            this.form.interior = data.interior;
            this.addEditShow = true;
          }
          break;
        case "delete":
          {
            console.log(data.id);
            deleteAchievementApi([data.id]).then(res => {
              this.$message.success("删除成功!");
              this.getList();
            });
          }
          break;
      }
    },
    getList() {
      getAchievementListApi().then(res => {
        this.dataList = res;
      });
    },
    confirmBtn() {
      this.$refs["formRef"].validate(valid => {
        if (valid) {
          if (this.form.id) {
            editAchievementApi(this.form).then(res => {
              this.$message.success("修改成功!");
              this.dialogClose();
              this.getList();
            });
          } else {
            addAchievementApi(this.form).then(res => {
              this.$message.success("新增成功!");
              this.dialogClose();
              this.getList();
            });
          }
        } else {
          return false;
        }
      });
    },
    addBtn() {
      this.form.id = undefined;
      this.form.name = "";
      this.form.direct = "";
      this.form.indirect = "";
      this.form.interior = "";
      this.addEditShow = true;
    },
    dialogClose() {
      this.form.id = undefined;
      this.$refs["formRef"].resetFields();
      this.addEditShow = false;
    }
  }
};
</script>

<style scoped lang="less">
@import "@/assets/css/publicStyle.less";

.container {
  .box {
    display: block;
    width: 100%;
    padding: 20px 16px;
    box-sizing: border-box;
    background-color: #ffffff;

    .header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 20px;
    }

    .list {
      display: block;
      width: 100%;
    }
  }
}
</style>