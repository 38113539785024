import axios from './axios'

// baseURL:process.env.VUE_APP_URE,
// url: "",
// method: "post",
// responseType: "json",
// headers: {},
// params: {},
// data: {},
// timeout: 5000,
// isLoading: false,
// statusCode: () => { },
// complete: () => { },
// success: () => { },
// fail: () => { },

// 登录
const login = {
  // 登录-接口
  login_login: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/manager/account/login'; // 存放接口
    axios(wP_obj);
  },
}


// 会员-模块
const member = {
  // 会员列表查询-接口
  member_list: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/queryPage'; // 存放接口
    axios(wP_obj);
  },
  // 查用户等级-接口
  member_level: wP_obj => {
    // wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/queryUserIdentity'; // 存放接口
    wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/listPartnerIdentityConfig'; // 存放接口
    axios(wP_obj);
  },
  // 修改用户是否锁定-接口
  member_editLocked: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/userAndPartner/modifyUserLocked'; // 存放接口
    axios(wP_obj);
  },
}

// 门店-模块
const store = {
  // 门店列表分页查询-接口
  store_list: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/store/managerList'; // 存放接口
    axios(wP_obj);
  },
  // 修改门店是否开业-接口
  store_operateBusiness: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/store/openOrClose'; // 存放接口
    axios(wP_obj);
  },
  // 门店批量删除-接口
  store_delete: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/store/delete'; // 存放接口
    axios(wP_obj);
  },
}

// 商品-模块
const goods = {
  // 赶场商品-分页查询管理平台商品列表-接口
  goods_GCList: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/market/queryNoodlesList'; // 存放接口
    // wP_obj.url = wP_obj.url ? wP_obj.url : '/market/queryMgtGoodsList'; // 存放接口
    axios(wP_obj);
  },
  // 赶场商品-查询商品分类列表-接口
  goods_GCGetCategory: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/market/queryGoodsCategoryList'; // 存放接口
    axios(wP_obj);
  },
  // 赶场商品-商品上下架-接口
  goods_GCUpdateState: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/market/changeGoodsState'; // 存放接口
    axios(wP_obj);
  },
  // 拼团商品-分页查询拼团商城列表-接口
  goods_PTList: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/legUpGroup/queryLUGPage'; // 存放接口
    axios(wP_obj);
  },
  // 超值套餐-查询套餐列表-接口
  goods_CZList: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/foods/list'; // 存放接口
    axios(wP_obj);
  },
  // 超值套餐-修改套餐上下架状态-接口
  goods_CZUpdateState: wP_obj => {
    wP_obj.url = wP_obj.url ? wP_obj.url : '/foods/updateState'; // 存放接口
    axios(wP_obj);
  },
}

export default {
  ...login,
  ...member,
  ...store,
  ...goods,
}