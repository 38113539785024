<template>
  <!-- 佣金概括 -->
  <div>
    <!-- 筛选查询 -->
    <div class="wScreenArea">
      <el-card class="box-card">
        <div slot="header" class="wScreenCardHeader">
          <span>筛选查询</span>
          <el-button type="primary" size="mini" @click="wDoExportData">
            导出数据
          </el-button>
        </div>
        <!-- 筛选列表 -->
        <el-form
          ref="wScreenForm"
          label-position="left"
          label-width="90px"
          :model="wScreen"
          @submit.native.prevent
        >
          <el-row :gutter="20">
            <!-- 佣金分类 -->
            <el-col :span="6">
              <el-form-item label="佣金分类" prop="wCType">
                <el-select
                  v-model="wScreen.wCType"
                  placeholder="全部分类"
                  @change="wchange_wCType"
                >
                  <el-option
                    v-for="item in wC_wCType"
                    :key="item.wValue"
                    :label="item.wLabel"
                    :value="item.wValue"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 查询时间 -->
            <el-col :span="6">
              <el-form-item label="查询时间" prop="wTimeSlot">
                <el-date-picker
                  style="width: 100%"
                  v-model="wScreen.wTimeSlot"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="wchange_wTimeSlot"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <!-- 操作按钮 -->
            <el-col :span="24" v-if="false">
              <el-button size="medium" @click="wFormScreenReset">
                重置
              </el-button>
              <el-button size="medium" @click="wFormScreenSubmit">
                查询
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>

    <!-- 表格数据 -->
    <div class="wTableArea">
      <!-- 表格 -->
      <el-table border :data="wTable.wList">
        <el-table-column label="用户" align="center" width="250">
          <template slot-scope="scope">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <el-avatar
                :src="scope.row.avatar"
                style="margin-right: 10px"
              ></el-avatar>
              <div>{{ scope.row.nickname }}</div>
            </div>
            <div>手机号:{{ scope.row.phone }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="info" label="内容" align="center">
        </el-table-column>
        <el-table-column prop="incomeType" label="佣金分类" align="center">
        </el-table-column>
        <el-table-column
          prop="proportion"
          label="分佣比例/提现税点(%)"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="createTime" label="时间" align="center">
        </el-table-column>
        <el-table-column prop="commissionMoney" label="金额(元)" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="wPagination">
        <el-pagination
          background
          :layout="wC_layout"
          :current-page="wTable.wPage"
          :page-size="wTable.wLimit"
          :total="wTable.wTotal"
          :page-sizes="wC_pageSizes"
          :hide-on-single-page="false"
          @current-change="wELPageCurrentChange"
          @size-change="wELPageSizeChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  statistics_queryCommissionType,
  statistics_queryCommissionRecord,
} from "@/network/wApi.js";
export default {
  data() {
    return {
      // 字典数据-佣金分类
      wDic_CType: [],
      // 筛选查询
      wScreen: {
        wCType: "0", // 佣金分类, 默认0-全部分类
        wTimeSlot: "", // 查询时间段
      },
      // 表格数据
      wTable: {
        wPage: 1,
        wLimit: 10,
        wTotal: 0,
        wList: [],
      },
    };
  },
  computed: {
    // 获取分页配置数据
    wC_layout() {
      return this.$store.state.wPage.wLayout;
    },
    wC_pageSizes() {
      return this.$store.state.wPage.wPageSizes;
    },
    // 获取筛选条件的佣金分类数据
    wC_wCType() {
      let wCTypeData = [
        {
          wValue: "0",
          wLabel: "全部分类",
        },
      ];
      this.wDic_CType.forEach((item, index) => {
        wCTypeData.push({
          wValue: item.id.toString(),
          wLabel: item.name,
        });
      });
      return wCTypeData;
    },
  },
  mounted() {
    // 获取参数
    if (this.$route.query.type) {
      this.wScreen.wCType = this.$route.query.type.toString();
    }
    if (this.$route.query.time && this.$route.query.time.length == 12) {
      let wTime_begin = new Date(
        this.$route.query.time.substring(0, 4) +
          "-" +
          this.$route.query.time.substring(4, 6) +
          "-" +
          "01 00:00:00"
      );
      let wTime_end_date = new Date(
        this.$route.query.time.substring(6, 10),
        this.$route.query.time.substring(10, 12),
        "00"
      ).getDate();
      let wTime_end = new Date(
        this.$route.query.time.substring(6, 10) +
          "-" +
          this.$route.query.time.substring(10, 12) +
          "-" +
          wTime_end_date +
          " 23:59:59"
      );
      // 更新数据
      this.wScreen.wTimeSlot = [wTime_begin, wTime_end];
    }
    this.$nextTick(() => {
      // Promise.allSettled()方法，用来确定一组异步操作是否都结束了（不管成功或失败）
      Promise.allSettled([this.wQueryCommissionTypeData()]).then((results) => {
        console.log(results);
        this.wTable.wPage = 1;
        this.$nextTick(() => {
          // 查询表格数据
          this.wQueryTableData();
        });
      });
    });
  },
  methods: {
    /**
     * wFormScreenReset
     * 重置表单数据
     */
    wFormScreenReset() {
      this.$refs["wScreenForm"].resetFields();
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wFormScreenSubmit
     * 提交表单数据
     */
    wFormScreenSubmit() {
      // 查询表格数据
      this.wQueryTableData();
    },
    /**
     * wELPageCurrentChange
     * 分页 currentPage 改变时会触发
     */
    wELPageCurrentChange(wP_cPage) {
      this.wTable.wPage = wP_cPage;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wELPageSizeChange
     * 修改推荐人分页
     * 分页 pageSize 改变时会触发 - 每页条数
     */
    wELPageSizeChange(wP_cPageSize) {
      // 更改当前页码数据
      this.wTable.wPage = 1;
      this.wTable.wLimit = wP_cPageSize;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wDoExportData
     * 导出数据
     */
    wDoExportData() {
      this.$message("功能待开发");
    },
    /**
     * wchange_wCType
     * 佣金分类切换选择
     * @param {String} wP_value 目前的选中值
     */
    wchange_wCType(wP_value) {
      this.wTable.wPage = 1;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wchange_wTimeSlot
     * 佣金分类切换选择
     * @param {Array} wP_value 组件绑定值。格式与绑定值一致，可受 value-format 控制
     */
    wchange_wTimeSlot(wP_value) {
      this.wTable.wPage = 1;
      this.$nextTick(() => {
        // 查询表格数据
        this.wQueryTableData();
      });
    },
    /**
     * wQueryCommissionTypeData
     * 查询佣金分类数据
     */
    wQueryCommissionTypeData() {
      return new Promise((resolve, reject) => {
        // 请求函数
        statistics_queryCommissionType()
          .then((res) => {
            // 更新数据
            this.wDic_CType = res;
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    /**
     * wQueryTableData
     * 查询表格数据
     */
    wQueryTableData() {
      let [
        wData,
        {
          wScreen: { wCType, wTimeSlot },
          wTable: { wPage, wLimit },
        },
      ] = [{}, this];

      // 佣金分类
      if (wCType != "0") {
        wData.incomeType = [wCType];
      }
      // 查询时间
      if (wTimeSlot && wTimeSlot.length == 2) {
        wData.startDate = `${wTimeSlot[0].getFullYear()}-${
          wTimeSlot[0].getMonth() + 1 < 10
            ? "0" + (wTimeSlot[0].getMonth() + 1)
            : wTimeSlot[0].getMonth() + 1
        }-${
          wTimeSlot[0].getDate() < 10
            ? "0" + wTimeSlot[0].getDate()
            : wTimeSlot[0].getDate()
        }`;
        wData.endDate = `${wTimeSlot[1].getFullYear()}-${
          wTimeSlot[1].getMonth() + 1 < 10
            ? "0" + (wTimeSlot[1].getMonth() + 1)
            : wTimeSlot[1].getMonth() + 1
        }-${
          wTimeSlot[1].getDate() < 10
            ? "0" + wTimeSlot[1].getDate()
            : wTimeSlot[1].getDate()
        }`;
      }
      // 类别,0-无需分佣 1-待到账 2-已到账 3-已退款
      wData.state = 2;
      // 分页
      wData.page = {};
      wData.page.pageNo = wPage;
      wData.page.pageSize = wLimit;

      // 请求参数
      statistics_queryCommissionRecord(JSON.stringify(wData))
        .then((res) => {
          let [{ total: wTotal, records: wList }] = [res];

          // 更新数据
          this.wTable.wTotal = wTotal;
          this.wTable.wList = wList;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";
.ss {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>